<template>
  <div class="container font-weight-light mt-3">
    <div class="text-center mb-4">
      <h2 class="fw-bold mb-2 display-8">
        {{ coverLetterExample?.Role }}<span class="text-primary"> Cover Letter Example</span>
      </h2>
      <h5 class="text-muted">A <i>Genuine</i> Cover Letter Example That Got A Candidate Hired</h5>
    </div>
    <div class="callout bg-primary-subtle d-flex align-items-center m-1 text-black-30">
      <i class="bi bi-info-square-fill fs-3 me-3"></i>
      <!-- Bootstrap 5 classes for size and margin -->
      <p class="fw-light">
        This cover letter example helped a real JobBuddy user secure their
        {{ coverLetterExample?.Role.toLowerCase() }} position. Shared with permission to assist you
        in your job search, names and places have been changed to protect their privacy.
      </p>
    </div>

    <div class="p-body row w-100">
      <div class="col-md-6 col-12">
        <img
          :src="getImageUrl(coverLetterExample?.ExampleImgSrc)"
          :alt="`Cover letter example for ${coverLetterExample?.Role}`"
          class="img-fluid d-block my-3 w-100 shadow"
          loading="lazy"
        />
      </div>

      <div class="col-md-6 col-12 m-auto border-start">
        <div class="pt-3">
          <h3 class="h4 mb-2">
            <b><i>Why This Cover Letter Works</i></b>
          </h3>
          <p>{{ coverLetterExample?.WhyItWorked }}.</p>
        </div>
        <div class="mb-4">
          <h3 class="h4 mb-2">
            <b
              ><i>Essential Guidelines for a <u>Compelling</u> Cover Letter</i></b
            >
          </h3>

          <h3 class="h5 mb-2 ppt-2">💪 Begin with a Strong Introduction</h3>
          <p>
            Begin your cover letter with a specific reference to the job title or company. Hiring
            managers are looking for genuine interest in the role and by demonstrating that you've
            tailored your application, you'll stand out from applicants using generic templates.
          </p>
        </div>

        <div class="mb-4">
          <h3 class="h5 mb-2">✅ Highlight Key Skills and Accomplishments</h3>
          <p>
            Mention key skills and notable accomplishments that align with the job requirements,
            such as streamlining operations or leading a successful project. Detailing these
            specifics demonstrates your qualifications and the tangible value you bring.
          </p>
        </div>

        <div class="mb-4">
          <h3 class="h5 mb-2">🤵 Demonstrate Alignment with Company Values</h3>
          <p>
            Illustrate your alignment with the company's values by mentioning your admiration for
            their work or ethos, like their dedication to sustainability or innovation. This shows
            your genuine interest and cultural fit.
          </p>
        </div>

        <div class="mb-4">
          <h3 class="h5 mb-2">🔎 Utilize Keywords from the Job Posting</h3>
          <p>
            Incorporate keywords from the job description into your cover letter to tailor it to the
            specific role and demonstrate your attention to detail. This technique ensures your
            application resonates with hiring managers and aligns with the job requirements.
          </p>
        </div>

        <div class="mb-4">
          <h3 class="h5 mb-2">📢 End with a Persuasive Call to Action</h3>
          <p>
            Conclude your cover letter with a strong call to action, urging the hiring manager to
            invite you for an interview. Express your enthusiasm about the possibility of
            contributing to the company, which can prompt them to move your application forward.
          </p>
        </div>

        <div class="mb-4">
          <h3 class="h5 mb-2">💼 Employ a Professional Format</h3>
          <p>
            Use a clean, professional template for your cover letter to create a positive visual
            impression. Ensure it is neatly organized and easy to read, which reflects your
            professionalism.
          </p>
        </div>
      </div>
    </div>
    <div class="m-1 rounded bg-body-tertiary">
      <div class="m-3 text-body p-3">
        <h3 class="h5 mb-2 text-body">
          <b
            >Create Your Cover Letter in <u><i>Seconds</i></u> and <u><i>for Free</i></u> with
            Job<span class="text-primary">Buddy</span></b
          >
          💡
        </h3>
        <p>
          Each of these cover letters was carefully researched, written and formatted using the most
          advanced AI cover letter generator in the world. Sign up for free to create your own cover
          letters. It's easy, fast and free.
        </p>

        <div class="row mt-5 w-100 m-1">
          <router-link :to="{ name: 'Dashboard' }">
            <button class="btn btn-primary btn-lg w-100">Create Cover Letter</button>
          </router-link>
        </div>
      </div>
    </div>

    <div v-if="getImageUrlForResume(coverLetterExample?.ExampleImgSrc)">
      <div class="row m-1 text-body p-3 bg-body-tertiary rounded">
        <!-- Text column -->
        <div class="col-12 col-sm-6 order-1 order-sm-1">
          <h3 class="h5 mb-2 mt-5 text-body">
            <b>{{ coverLetterExample?.Role }} Resume That Got A Candidate Hired</b>
          </h3>
          <span>
            Explore a <i>real-world</i> {{ coverLetterExample?.Role.toLocaleLowerCase() }} resume
            that successfully secured a job offer.
          </span>
          <br />
          <br />

          <i>Optimized for Applicant Tracking Systems</i>
          <br />

          <i>Professionally Crafted Design</i>
          <br />

          <i>Demonstrated Effectiveness</i>
        </div>

        <!-- Image column -->
        <div
          class="col-12 col-sm-6 order-2 order-sm-2 d-flex justify-content-center align-items-center"
        >
          <img
            class="m-auto"
            style="max-height: 350px; max-width: 100%"
            :src="getImageUrlForResume(coverLetterExample.ExampleImgSrc)"
            loading="lazy"
          />
        </div>

        <!-- Button column -->
        <div
          class="col-12 col-sm-6 order-3 order-sm-3 d-flex justify-content-center align-items-center"
        >
          <router-link :to="{ name: 'ResumeDetail', params: route.params.role }">
            <button class="btn btn-outline-primary btn-lg w-100">View This Resume</button>
          </router-link>
        </div>
      </div>
    </div>
    <div>
      <h3 class="h5 mb-2 mt-5"><b>More Cover Letter Examples</b></h3>
      <p>
        Browse more cover letter examples for different roles and industries. Each cover letter
        example is based on a real cover letter that got a candidate hired.
      </p>
      <div class="row">
        <div class="d-flex">
          <div class="overflow-x-auto w-100 d-flex scroll-container">
            <router-link
              v-for="example in coverLetterExamples"
              :key="example.Role"
              :to="{
                name: 'CoverLetterDetail',
                params: { role: example.Role.toLowerCase().replace(/ /g, '-') },
              }"
              style="text-decoration: none"
              class="col"
            >
              <div class="card border-0 m-2" style="width: 18rem">
                <img
                  :src="getImageUrl(example.ExampleImgSrc)"
                  :alt="`Cover letter example for ${example.Role}`"
                  class="shadow m-1 example-card"
                  loading="lazy"
                />
                <div class="card-body">
                  <h5 class="card-title text-secondary">{{ example.Role }}</h5>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <div>
        <h3 class="h5 mb-2 mt-5"><b>See All Cover Letter Examples</b></h3>
        <p>
          Browse our full cover letter example collection, with a variety of cover letters for
          different roles and industries.
        </p>
        <div>
          <router-link :to="{ name: 'CoverLetterExamples' }">
            <button class="btn btn-outline-primary btn-lg w-100">View All Cover Letters</button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useHead } from "@unhead/vue";
import { onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { CoverLetterExample, coverLetterExamples } from "./blogs/CoverLetterExamples";

const route = useRoute();
const coverLetterExample = ref<CoverLetterExample | undefined>();
// Fetch initial cover letter example based on the initial route.
onMounted(() => {
  loadCoverLetterExample();
});

// Watch for route changes to update the cover letter example.
watch(
  () => route.params.role,
  (newRole) => {
    loadCoverLetterExample();
  }
);

watch(
  () => coverLetterExample.value,
  (newVal) => {
    if (newVal) {
      useHead({
        title: `Cover Letter for a ${newVal.Role} Position`,
        meta: [
          {
            name: "description",
            content: `Discover how this standout cover letter for a ${newVal.Role.toLowerCase()} position helped a candidate secure a job. Learn key tips and strategies to enhance your own cover letter.`,
          },
          {
            name: "keywords",
            content: `${
              newVal.Role
            } cover letter, successful ${newVal.Role.toLowerCase()} cover letter example, JobBuddy cover letter tips, cover letter strategies, professional cover letter templates`,
          },
        ],
      });
    }
  },
  { immediate: true }
);

function loadCoverLetterExample() {
  const role = route.params.role?.toLowerCase();
  coverLetterExample.value = coverLetterExamples.find(
    (example) => example.key.toLowerCase() === role
  );
}

function getImageUrl(path: any): string {
  if (!path) {
    console.error("Invalid path provided");
    return ""; // Return a default image path or an error image
  }
  return require(`../../public/media/coverLetterExamples/${path}`);
}

function getImageUrlForResume(path: any): string {
  if (!path) {
    console.error("Invalid path provided");
    return ""; // Return a default image path or an error image
  }
  return require(`../../public/media/resumeExamples/${path}`);
}
</script>

<style scoped>
.container {
  padding: 1em;
  font-family: Arial, sans-serif;
}

.text-center {
  text-align: center;
  margin-bottom: 2em;
}

.fw-bold {
  font-weight: bold;
}

.mb-2,
.mb-3,
.mb-4 {
  margin-bottom: 0.5rem;
}

.my-3,
.my-5 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.p-body {
  text-align: left;
  margin-top: 15px;
}

.h4 {
  font-size: 1.25rem;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
  color: white;
}

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.callout {
  padding: 20px;
  margin: 20px 0;
  border: 1px solid #007bff;
  border-left-width: 5px;
  border-radius: 3px;
}
.overflow-x-auto.position-relative {
  position: relative;
}

.scroll-container {
  overflow-x: scroll;
}

.example-card:hover {
  transform: scale(1.05);
  transition: transform 0.3s;
  border: 3px solid rgba(0, 0, 255, 0.304); /* Change 'blue' to any desired highlight color */
  border-radius: 3px;
  box-shadow: 0 0 15px rgba(0, 0, 255, 0.5); /* Additional shadow for selected image */
}
</style>

function getImageUrl(path: any) { throw new Error("Function not implemented."); }
