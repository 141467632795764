import AIProfile from "@models/AIProfile";
import { AxiosResponse } from "axios";
import { instance } from "./apiProvider/ApiProvider";

export class AIProfileApiService {
  public static async createOrEditAIProfile(
    aiProfile: AIProfile
  ): Promise<AxiosResponse<AIProfile>> {
    const axios = await instance();
    const response = await axios.post("/createOrEditAIProfile", aiProfile);
    if (response.status == 200) {
      return response;
    } else {
      console.error(response);
      return response;
    }
  }

  public static async getAiProfilesForLoggedInUser(): Promise<
    AxiosResponse<{ aiProfiles: AIProfile[] }>
  > {
    const axios = await instance();
    const response = await axios.get("/getAiProfilesForLoggedInUser");
    if (response.status == 200) {
      return response;
    }
    console.error(response);
    return response;
  }

  public static async deleteAIProfile(aiProfileId: string): Promise<AxiosResponse> {
    const axios = await instance();
    const response = await axios.delete("/deleteAIProfile", {
      params: {
        id: aiProfileId,
      },
    });
    if (response.status == 200) {
      return response;
    } else {
      console.error(response);
      return response;
    }
  }
}
