<template>
  <div>
    <Modal title="There's been an error!" ref="errorModal">
      <template #body>There was an error processing this request. Please try again later!</template>
    </Modal>
    <Modal title="Subscription Quota Reached" hide-close-button ref="quotaExceededModal">
      <template #body
        >Oops! It looks like you've reached the limit for creating new applications on your current
        subscription plan. To continue enjoying our services without interruption, please consider
        upgrading.</template
      >
      <template #footer>
        <button
          @click="upgradeSubscriptionAction"
          class="w-100 btn btn-warning btn-lg mt-auto rounded-pill"
        >
          Upgrade Subscription
        </button>
      </template>
    </Modal>
    <div class="container w-80 mb-3">
      <h1 class="mt-5">
        <span class="fw-bold">Job<span class="text-primary">Buddy</span> Application Wizard</span>
      </h1>
      <form class="justify-content-center container mt-5">
        <!-- LOADING BAR -->
        <div class="position-relative d-flex align-items-center mb-4">
          <div class="progress w-100">
            <div
              class="progress-bar progress-bar-striped progress-bar-animated"
              role="progressbar"
              :style="`width: ${getProgress()}%`"
            />
          </div>
        </div>
        <div />
        <!-- APPLICATION CARD 1 -->
        <div v-if="applicationPhase == 1">
          <div v-if="!showDetails">
            <h5 id="headingOne" class="accordion-header mb-3">Paste job listing URL here</h5>
            <div class="mb-3 input-group">
              <input
                v-model="jobListingURL"
                placeholder="www.example.com/joblisting"
                class="form-control"
              />
              <button @click="loadDetailsFromURL" type="button" class="btn btn-primary">
                <span v-show="!loading">Load Details</span>
                <div v-show="loading" class="spinner-border spinner-border-sm" role="status" />
              </button>
            </div>
            <div v-if="showUrlLoadError" class="text-danger m-3">
              We can't automatically load the details from this URL right now
            </div>
          </div>
          <div v-if="showDetails">
            <h5 id="headingOne" class="accordion-header mb-3">Company Name</h5>
            <div class="mb-3">
              <input
                v-model="application.companyName"
                placeholder="Company Name"
                class="form-control"
              />
            </div>
            <h5 id="headingOne" class="accordion-header mb-3">Role Title</h5>
            <div class="mb-3">
              <input v-model="application.roleTitle" placeholder="Role" class="form-control" />
            </div>
            <div class="mt-3">
              <div class="rounded mb-3">
                <h5 id="headingOne" class="accordion-header mb-3">Job Description</h5>
                <div class="" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <textarea
                      v-model="application.jobDescription"
                      class="form-control p-5"
                      rows="10"
                      placeholder="Paste the personal specification, job description etc here"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <a href="#" @click="showDetails = !showDetails">
            <b>{{ showDetails ? "Load details from URL" : "Enter job details manually" }}</b>
          </a>
        </div>
        <!-- APPLICATION CARD 2 -->
        <div v-if="applicationPhase == 2">
          <div class="mt-3">
            <div class="rounded mb-3">
              <h5 id="headingOne" class="accordion-header mb-3">Describe the organization</h5>
              <div class="" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <textarea
                    v-model="application.companyDescription"
                    class="form-control p-5"
                    rows="10"
                    placeholder="Paste (or write) a little about the company you're applying for. This is used to personalize the response"
                    required
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- APPLICATION CARD 3 -->
        <div v-if="applicationPhase > 2">
          <div class="mt-3">
            <div class="rounded mb-3">
              <h5 id="headingOne" class="accordion-header mb-3">Any comments?</h5>
              <div class="" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <textarea
                    v-model="application.roleComments"
                    class="form-control p-5"
                    rows="10"
                    placeholder="(Optional) Is there anything that particuarly interested you in this role? Write it here"
                    required
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-grid gap-2 align-bottom">
          <div class="btn-group">
            <button
              class="btn btn-primary mt-5"
              type="button"
              :disabled="loading"
              @click="buttonClicked"
            >
              <span v-show="!loading">{{ buttonText }}</span>
              <div v-show="loading" class="spinner-border spinner-border-sm" role="status" />
            </button>
          </div>
          <button v-if="generatedContent" class="btn btn-danger" type="button">Regenerate</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup lang="ts">
import CreateOrUpdateApplication from "@models/dtos/CreateOrUpdateApplication";
import { ref, onMounted } from "vue";
import router from "../router";
import { ApplicationApiService } from "@/services/ApplicationApiService";
import Modal from "@/components/Modal.vue";
import { toast } from "vue3-toastify";
import { ApplicationStatus } from "@models/SystemEnums";
import { WebscrapeApiService } from "@/services/WebscrapeApiService";

const loading = ref(false);
const applicationPhase = ref(1);
const buttonText = ref("Next");
const app = new CreateOrUpdateApplication(
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  ApplicationStatus.TO_APPLY,
  0
);
const application = ref(app);
const jobListingURL = ref("");
const errorModal = ref(null);
const quotaExceededModal = ref(null);
const showDetails = ref(false);
const showUrlLoadError = ref(false);
function getProgress() {
  return 30 * applicationPhase.value;
}

async function buttonClicked() {
  applicationPhase.value++;

  if (applicationPhase.value == 3) {
    buttonText.value = "Create Application";
  }
  if (applicationPhase.value > 3) {
    await createApplication();
  }
}

async function loadDetailsFromURL() {
  try {
    loading.value = true;
    const result = await WebscrapeApiService.getApplicationDetailsFromUrl(jobListingURL.value);
    if (result.status == 200) {
      application.value.companyName = result?.data.companyName;
      application.value.companyDescription = result?.data.companyDescription;
      application.value.jobDescription = result?.data.jobDescription;
      application.value.roleTitle = result?.data.roleTitle;
      showDetails.value = true;
    }
  } catch (err: any) {
    showUrlLoadError.value = true;
    if (err.response.data.error == "Quota exceeded") {
      toast.error("Quota exceeded, please upgrade your account");
      loading.value = false;
      quotaExceededModal.value.show();
    }
  } finally {
    loading.value = false;
  }
}

async function createApplication() {
  try {
    loading.value = true;
    const result = await ApplicationApiService.createOrUpdateApplication(app);
    router.push("/myapplication/" + result.data?.id);
  } catch (err: any) {
    await handleApplicationCreationError(err);
  } finally {
    loading.value = false;
  }
}

function handleApplicationCreationError(err: any) {
  if (err.status != 200) {
    if (err.response.data.error == "Quota exceeded") {
      toast.error("Quota exceeded, please upgrade your account");
      loading.value = false;
      quotaExceededModal.value.show();
    } else {
      toast.error("There has been a problem creating this application");
      loading.value = false;
      errorModal.value.show();
    }
  }
}

function upgradeSubscriptionAction() {
  (quotaExceededModal.value as any).hide();
  router.push({ path: "/subscribe", replace: true });
}
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #0275d8;
}
</style>
