<template>
  <div class="dropzone" style="height: 200px">
    <div class="d-flex justify-content-between"></div>
    <div v-if="records?.length > 0" class="overflow-auto h-100">
      <span class="position-absolute bottom-0 pb-4 mb-5 start-0">
        <i
          v-tooltip="'Add Resume'"
          v-if="records.length > 0"
          v-bind="getRootProps()"
          class="bi bi-file-earmark-plus-fill mh-100 text-primary position-absolute"
          style="font-size: 50px; width: 100px; cursor: pointer"
        >
          <input v-bind="getInputProps()" @click="toggleUploadArea" />
        </i>
      </span>
      <table class="table table table-hover">
        <!-- add padding and margin utilities -->
        <tbody>
          <tr v-for="(record, index) in records" :key="index">
            <td class="align-middle">{{ record.documentName }}</td>
            <td class="">
              <i
                v-tooltip="'Delete File'"
                role="button"
                class="bi bi-trash text-danger danger m-1"
                @click="handleClickDeleteFile(record.id)"
              >
              </i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else class="text-center h-100" v-bind="getRootProps()">
      <div
        class="dropzone-area h-100"
        :class="{
          isDragActive,
        }"
        style="display: flex; justify-content: center; align-items: center; height: 100%"
      >
        <input v-bind="getInputProps()" />
        <p v-if="isDragActive">Drop the files here ...</p>
        <span v-else class="text-center"> Drag and drop your files here, or click to select </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, watch, ref } from "vue";
import { useDropzone } from "vue3-dropzone";

const { getRootProps, getInputProps, isDragActive, ...rest } = useDropzone({
  onDrop,
  accept: [".docx", ".pdf"],
  maxFiles: 1,
  maxSize: 3000000,
});

const isUploadAreaToggled = ref(false);
defineProps({
  records: {
    type: [],
  },
});

function toggleUploadArea() {
  isUploadAreaToggled.value = isUploadAreaToggled.value ? false : true;
}

const emit = defineEmits(["fileUploaded", "fileDeleted"]);

watch(isDragActive, () => {
  console.log("isDragActive", isDragActive.value, rest);
});

function onDrop(acceptedFiles, rejectedFiles) {
  acceptedFiles.forEach((file) => {
    emit("fileUploaded", file);
  });
  toggleUploadArea();
}

function handleClickDeleteFile(id) {
  emit("fileDeleted", id);
}
</script>
<style>
.dropzone-area {
  min-height: 100px;
}
</style>
