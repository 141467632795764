<template>
  <div class="w-80 pt-2 container">
    <h1>Privacy Policy</h1>
    <h1
      style="
        margin-top: 24pt;
        margin-right: 0cm;
        margin-bottom: 24pt;
        margin-left: 0cm;
        font-size: 27px;
        font-family: 'Times New Roman', serif;
        margin: 0cm;
        text-align: center;
      "
    ></h1>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      &nbsp;
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    ></p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: decimal; margin-left: 7.850000000000001px">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <strong
            ><u><span style="font-family: 'Arial', sans-serif">Introduction</span></u></strong
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <strong
        ><u><span style="font-family: 'Arial', sans-serif">&nbsp;</span></u></strong
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">Welcome to <strong>JobBuddy</strong>.</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >JobBuddy&nbsp;(&ldquo;us&rdquo;, &ldquo;we&rdquo;, or &ldquo;our&rdquo;)
        operates&nbsp;www.jobbuddytech.com&nbsp;(hereinafter referred to as
        &ldquo;<strong>Service</strong>&rdquo;).</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >Our Privacy Policy governs your visit to www.jobbuddytech.com, and explains how we collect,
        safeguard and disclose information that results from your use of our Service.&nbsp;</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >We use your data to provide and improve Service. By using Service, you agree to the
        collection and use of information in accordance with this policy. Unless otherwise defined
        in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in
        our Terms and Conditions.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >Our Terms and Conditions (&ldquo;<strong>Terms</strong>&rdquo;) govern all use of our
        Service and together with the Privacy Policy constitutes your agreement with us
        (&ldquo;<strong>agreement</strong>&rdquo;).</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined; margin-left: 7.850000000000001px">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <strong
            ><u><span style="">Definitions</span></u></strong
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <strong
        ><u><span style="font-family: 'Arial', sans-serif">&nbsp;</span></u></strong
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <strong><span style="font-family: 'Arial', sans-serif">SERVICE&nbsp;</span></strong
      ><span style="font-family: 'Arial', sans-serif"
        >means the&nbsp;www.jobbuddytech.com website&nbsp;operated by&nbsp;JobBuddy.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <strong><span style="font-family: 'Arial', sans-serif">PERSONAL DATA</span></strong
      ><span style="font-family: 'Arial', sans-serif"
        >&nbsp;means data about a living individual who can be identified from those data (or from
        those and other information either in our possession or likely to come into our
        possession).</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <strong><span style="font-family: 'Arial', sans-serif">USAGE DATA</span></strong
      ><span style="font-family: 'Arial', sans-serif"
        >&nbsp;is data collected automatically either generated by the use of Service or from
        Service infrastructure itself (for example, the duration of a page visit).</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <strong><span style="font-family: 'Arial', sans-serif">COOKIES</span></strong
      ><span style="font-family: 'Arial', sans-serif"
        >&nbsp;are small files stored on your device (computer or mobile device).</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <strong><span style="font-family: 'Arial', sans-serif">DATA CONTROLLER</span></strong
      ><span style="font-family: 'Arial', sans-serif"
        >&nbsp;means a natural or legal person who (either alone or jointly or in common with other
        persons) determines the purposes for which and the manner in which any personal data are, or
        are to be, processed. For the purpose of this Privacy Policy, we are a Data Controller of
        your data.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <strong
        ><span style="font-family: 'Arial', sans-serif"
          >DATA PROCESSORS (OR SERVICE PROVIDERS)</span
        ></strong
      ><span style="font-family: 'Arial', sans-serif"
        >&nbsp;means any natural or legal person who processes the data on behalf of the Data
        Controller. We may use the services of various Service Providers in order to process your
        data more effectively.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <strong><span style="font-family: 'Arial', sans-serif">DATA SUBJECT&nbsp;</span></strong
      ><span style="font-family: 'Arial', sans-serif"
        >is any living individual who is the subject of Personal Data.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <strong><span style="font-family: 'Arial', sans-serif">THE USER&nbsp;</span></strong
      ><span style="font-family: 'Arial', sans-serif"
        >is the individual using our Service. The User corresponds to the Data Subject, who is the
        subject of Personal Data.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined; margin-left: 7.850000000000001px">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <strong
            ><u><span style="">Information Collection and Use</span></u></strong
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <strong
        ><u><span style="font-family: 'Arial', sans-serif">&nbsp;</span></u></strong
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >We collect several different types of information for various purposes to provide and
        improve our Service to you.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined; margin-left: 7.850000000000001px">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <strong
            ><u><span style="">Types of Data Collected</span></u></strong
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <strong
        ><u><span style="font-family: 'Arial', sans-serif">&nbsp;</span></u></strong
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <strong><span style="font-family: 'Arial', sans-serif">Personal Data</span></strong>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >While using our Service, we may ask you to provide us with certain personally identifiable
        information that can be used to contact or identify you (&ldquo;<strong
          >Personal Data</strong
        >&rdquo;). Personally identifiable information may include, but is not limited to:</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <span style="font-family: 'Arial', sans-serif">Email address</span>
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 18pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <span style="font-family: 'Arial', sans-serif">First name and last name</span>
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 18pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <span style="font-family: 'Arial', sans-serif">Phone number</span>
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 18pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <span style="font-family: 'Arial', sans-serif"
            >Address, State, Province, ZIP/Postal code, City</span
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 18pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <span style="font-family: 'Arial', sans-serif">Cookies and Usage Data</span>
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 18pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >We may use your Personal Data to contact you with newsletters, marketing or promotional
        materials and other information that may be of interest to you. You may opt out of receiving
        any, or all, of these communications from us&nbsp;by following the unsubscribe link.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 14.2pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <strong><span style="font-family: 'Arial', sans-serif">Usage Data</span></strong>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >We may also collect information that your browser sends whenever you visit our Service or
        when you access Service by or through a mobile device (&ldquo;<strong>Usage Data</strong
        >&rdquo;).</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >This Usage Data may include information such as your computer&apos;s Internet Protocol
        address (e.g. IP address), browser type, browser version, the pages of our Service that you
        visit, the time and date of your visit, the time spent on those pages, unique device
        identifiers and other diagnostic data.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >When you access Service with a mobile device, this Usage Data may include information such
        as the type of mobile device you use, your mobile device unique ID, the IP address of your
        mobile device, your mobile operating system, the type of mobile Internet browser you use,
        unique device identifiers and other diagnostic data.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 14.2pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <strong><span style="font-family: 'Arial', sans-serif">Tracking Cookies Data</span></strong>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >We use cookies and similar tracking technologies to track the activity on our Service and
        we hold certain information.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >Cookies are files with a small amount of data which may include an anonymous unique
        identifier. Cookies are sent to your browser from a website and stored on your device. Other
        tracking technologies are also used such as beacons, tags and scripts to collect and track
        information and to improve and analyze our Service.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >You can instruct your browser to refuse all cookies or to indicate when a cookie is being
        sent. However, if you do not accept cookies, you may not be able to use some portions of our
        Service.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">Examples of Cookies we use:</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <strong><span style="font-family: 'Arial', sans-serif">Session Cookies:</span></strong
          ><span style="font-family: 'Arial', sans-serif"
            >&nbsp;We use Session Cookies to operate our Service.</span
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <strong><span style="font-family: 'Arial', sans-serif">Preference Cookies:</span></strong
          ><span style="font-family: 'Arial', sans-serif"
            >&nbsp;We use Preference Cookies to remember your preferences and various
            settings.</span
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <strong><span style="font-family: 'Arial', sans-serif">Security Cookies:</span></strong
          ><span style="font-family: 'Arial', sans-serif"
            >&nbsp;We use Security Cookies for security purposes.</span
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <strong><span style="font-family: 'Arial', sans-serif">Advertising Cookies:</span></strong
          ><span style="font-family: 'Arial', sans-serif"
            >&nbsp;Advertising Cookies are used to serve you with advertisements that may be
            relevant to you and your interests.</span
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 18pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <strong><span style="font-family: 'Arial', sans-serif">Other Data</span></strong>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >While using our Service, we may also collect the following information: sex, age, date of
        birth, place of birth, passport details, citizenship, registration at place of residence and
        actual address, telephone number (work, mobile), details of documents on education,
        qualification, professional training, employment agreements, non-disclosure agreements,
        information on bonuses and compensation, information on marital status, family members,
        social security (or other taxpayer identification) number, office location and other
        data.&nbsp;</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined; margin-left: 7.850000000000001px">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <strong
            ><u><span style="font-family: 'Arial', sans-serif">Use of Data</span></u></strong
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <strong
        ><u><span style="font-family: 'Arial', sans-serif">&nbsp;</span></u></strong
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >JobBuddy&nbsp;uses the collected data for various purposes:</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <span style="font-family: 'Arial', sans-serif">to provide and maintain our Service;</span>
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 18pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <span style="font-family: 'Arial', sans-serif"
            >to notify you about changes to our Service;&nbsp;</span
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 18pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <span style="font-family: 'Arial', sans-serif"
            >to allow you to participate in interactive features of our Service when you choose to
            do so;&nbsp;</span
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 18pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <span style="font-family: 'Arial', sans-serif">to provide customer support;&nbsp;</span>
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 18pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <span style="font-family: 'Arial', sans-serif"
            >to gather analysis or valuable information so that we can improve our
            Service;&nbsp;</span
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 18pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <span style="font-family: 'Arial', sans-serif">to monitor the usage of our Service;</span>
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <span style="font-family: 'Arial', sans-serif"
            >to detect, prevent and address technical issues;</span
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 18pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <span style="font-family: 'Arial', sans-serif"
            >to fulfill any other purpose for which you provide it;</span
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <span style="font-family: 'Arial', sans-serif"
            >to carry out our obligations and enforce our rights arising from any contracts entered
            into between you and us, including for billing and collection;</span
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <span style="font-family: 'Arial', sans-serif"
            >to provide you with notices about your account and/or subscription, including
            expiration and renewal notices, email-instructions, etc.;</span
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <span style="font-family: 'Arial', sans-serif"
            >to provide you with news, special offers and general information about other goods,
            services and events which we offer that are similar to those that you have already
            purchased or enquired about unless you have opted not to receive such information;</span
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <span style="font-family: 'Arial', sans-serif"
            >in any other way we may describe when you provide the information;</span
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <span style="font-family: 'Arial', sans-serif"
            >for any other purpose with your consent.&nbsp;</span
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined; margin-left: 7.850000000000001px">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <strong
            ><u><span style="font-family: 'Arial', sans-serif">Retention of Data</span></u></strong
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <strong
        ><u><span style="font-family: 'Arial', sans-serif">&nbsp;</span></u></strong
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >We will retain your Personal Data only for as long as is necessary for the purposes set out
        in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to
        comply with our legal obligations (for example, if we are required to retain your data to
        comply with applicable laws), resolve disputes, and enforce our legal agreements and
        policies.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >We will also retain Usage Data for internal analysis purposes. Usage Data is generally
        retained for a shorter period, except when this data is used to strengthen the security or
        to improve the functionality of our Service, or we are legally obligated to retain this data
        for longer time periods.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined; margin-left: 7.850000000000001px">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <strong
            ><u><span style="">Transfer of Data</span></u></strong
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <strong
        ><u><span style="font-family: 'Arial', sans-serif">&nbsp;</span></u></strong
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >Your information, including Personal Data, may be transferred to &ndash; and maintained on
        &ndash; computers located outside of your state, province, country or other governmental
        jurisdiction where the data protection laws may differ from those of your
        jurisdiction.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >If you are located outside United States and choose to provide information to us, please
        note that we transfer the data, including Personal Data, to United States and process it
        there.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >Your consent to this Privacy Policy followed by your submission of such information
        represents your agreement to that transfer.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0.2pt;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >JobBuddy&nbsp;will take all the steps reasonably necessary to ensure that your data is
        treated securely and in accordance with this Privacy Policy and no transfer of your Personal
        Data will take place to an organisation or a country unless there are adequate controls in
        place including the security of your data and other personal information.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined; margin-left: 7.850000000000001px">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <strong
            ><u><span style="">Disclosure of Data</span></u></strong
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <strong
        ><u><span style="font-family: 'Arial', sans-serif">&nbsp;</span></u></strong
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >We may disclose personal information that we collect, or you provide:</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <strong
            ><span style="font-family: 'Arial', sans-serif"
              >Disclosure for Law Enforcement.</span
            ></strong
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 35.7pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >Under certain circumstances, we may be required to disclose your Personal Data if required
        to do so by law or in response to valid requests by public authorities.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 21.3pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <strong
            ><span style="font-family: 'Arial', sans-serif">Business Transaction.</span></strong
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 35.7pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >If we or our subsidiaries are involved in a merger, acquisition or asset sale, your
        Personal Data may be transferred.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 21.25pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <strong
            ><span style="font-family: 'Arial', sans-serif"
              >Other cases. We may disclose your information also:</span
            ></strong
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 35.45pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <strong><span style="font-family: 'Arial', sans-serif">&nbsp;</span></strong>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <span style="font-family: 'Arial', sans-serif"
            >to contractors, service providers, and other third parties we use to support our
            business;</span
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 54pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <span style="font-family: 'Arial', sans-serif"
            >to fulfill the purpose for which you provide it;</span
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 54pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <span style="font-family: 'Arial', sans-serif"
            >with your consent in any other cases;</span
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 54pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined; margin-left: 7.850000000000001px">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <strong
            ><u><span style="">Security of Data</span></u></strong
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <strong
        ><u><span style="font-family: 'Arial', sans-serif">&nbsp;</span></u></strong
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >The security of your data is important to us but remember that no method of transmission
        over the Internet or method of electronic storage is 100% secure. While we strive to use
        commercially acceptable means to protect your Personal Data, we cannot guarantee its
        absolute security.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined; margin-left: 7.850000000000001px">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <strong
            ><u
              ><span style=""
                >Your Data Protection Rights Under General Data Protection Regulation (GDPR)</span
              ></u
            ></strong
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <strong
        ><u><span style="font-family: 'Arial', sans-serif">&nbsp;</span></u></strong
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >If you are a resident of the European Union (EU) and European Economic Area (EEA), you have
        certain data protection rights, covered by GDPR. &ndash; See more at&nbsp;</span
      ><a href="https://eur-lex.europa.eu/eli/reg/2016/679/oj"
        ><span style="font-family: 'Arial', sans-serif"
          >https://eur-lex.europa.eu/eli/reg/2016/679/oj</span
        ></a
      ><span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >We aim to take reasonable steps to allow you to correct, amend, delete, or limit the use of
        your Personal Data.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >If you wish to be informed what Personal Data we hold about you and if you want it to be
        removed from our systems, please email us at hello@jobbuddytech.com.&nbsp;</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >In certain circumstances, you have the following data protection rights:</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <span style="font-family: 'Arial', sans-serif"
            >the right to access, update or to delete the information we have on you;</span
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 35.7pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <span style="font-family: 'Arial', sans-serif"
            >the right of rectification. You have the right to have your information rectified if
            that information is inaccurate or incomplete;</span
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <span style="font-family: 'Arial', sans-serif"
            >the right to object. You have the right to object to our processing of your Personal
            Data;</span
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 35.7pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <span style="font-family: 'Arial', sans-serif"
            >the right of restriction. You have the right to request that we restrict the processing
            of your personal information;</span
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 35.7pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <span style="font-family: 'Arial', sans-serif"
            >the right to data portability. You have the right to be provided with a copy of your
            Personal Data in a structured, machine-readable and commonly used format;</span
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <span style="font-family: 'Arial', sans-serif"
            >the right to withdraw consent.&nbsp;You also have the right to withdraw your consent at
            any time where we rely on your consent to process your personal information;</span
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >Please note that we may ask you to verify your identity before responding to such requests.
        Please note, we may not able to provide Service without some necessary data.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >You have the right to complain to a Data Protection Authority about our collection and use
        of your Personal Data. For more information, please contact your local data protection
        authority in the European Economic Area (EEA).</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined; margin-left: 7.850000000000001px">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <strong
            ><u
              ><span style=""
                >Your Data Protection Rights under the California Privacy Protection Act
                (CalOPPA)</span
              ></u
            ></strong
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <strong
        ><u><span style="font-family: 'Arial', sans-serif">&nbsp;</span></u></strong
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >CalOPPA is the first state law in the nation to require commercial websites and online
        services to post a privacy policy. The law&rsquo;s reach stretches well beyond California to
        require a person or company in the United States (and conceivable the world) that operates
        websites collecting personally identifiable information from California consumers to post a
        conspicuous privacy policy on its website stating exactly the information being collected
        and those individuals with whom it is being shared, and to comply with this policy. &ndash;
        See more at:&nbsp;</span
      ><a
        href="https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/"
        ><span style="font-family: 'Arial', sans-serif"
          >https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/</span
        ></a
      ><span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >According to CalOPPA we agree to the following:</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <span style="font-family: 'Arial', sans-serif"
            >users can visit our site anonymously;</span
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 35.7pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <span style="font-family: 'Arial', sans-serif"
            >our Privacy Policy link includes the word &ldquo;Privacy&rdquo;, and can easily be
            found on the page specified above on the home page of our website;</span
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <span style="font-family: 'Arial', sans-serif"
            >users will be notified of any privacy policy changes on our Privacy Policy Page;</span
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <span style="font-family: 'Arial', sans-serif"
            >users are able to change their personal information by emailing us at
            hello@jobbuddytech.com.&nbsp;</span
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 18pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >Our Policy on &ldquo;Do Not Track&rdquo; Signals:</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >We honor Do Not Track signals and do not track, plant cookies, or use advertising when a Do
        Not Track browser mechanism is in place. Do Not Track is a preference you can set in your
        web browser to inform websites that you do not want to be tracked.&nbsp;</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >You can enable or disable Do Not Track by visiting the Preferences or Settings page of your
        web browser.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined; margin-left: 7.850000000000001px">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <strong
            ><u
              ><span style=""
                >Your Data Protection Rights under the California Consumer Privacy Act (CCPA)</span
              ></u
            ></strong
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <strong
        ><u><span style="font-family: 'Arial', sans-serif">&nbsp;</span></u></strong
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >If you are a California resident, you are entitled to learn what data we collect about you,
        ask to delete your data and not to sell (share) it. To exercise your data protection rights,
        you can make certain requests and ask us:</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <strong
            ><span style="font-family: 'Arial', sans-serif"
              >What personal information we have about you</span
            ></strong
          ><span style="font-family: 'Arial', sans-serif"
            >. If you make this request, we will return to you:</span
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 35.7pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <span style="font-family: 'Arial', sans-serif"
            >The categories of personal information we have collected about you.</span
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 54pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <span style="font-family: 'Arial', sans-serif"
            >The categories of sources from which we collect your personal information.</span
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <span style="font-family: 'Arial', sans-serif"
            >The business or commercial purpose for collecting or selling your personal
            information.</span
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <span style="font-family: 'Arial', sans-serif"
            >The categories of third parties with whom we share personal information.</span
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <span style="font-family: 'Arial', sans-serif"
            >The specific pieces of personal information we have collected about you.</span
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <span style="font-family: 'Arial', sans-serif"
            >A list of categories of personal information that we have sold, along with the category
            of any other company we sold it to. If we have not sold your personal information, we
            will inform you of that fact.</span
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <span style="font-family: 'Arial', sans-serif"
            >A list of categories of personal information that we have disclosed for a business
            purpose, along with the category of any other company we shared it with.</span
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 35.45pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >Please note, you are entitled to ask us to provide you with this information up to two
        times in a rolling twelve-month period. When you make this request, the information provided
        may be limited to the personal information we collected about you in the previous
        12&nbsp;months.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <strong
            ><span style="font-family: 'Arial', sans-serif"
              >To delete your personal information</span
            ></strong
          ><span style=""
            >. If you make this request, we will delete the personal information we hold about you
            as of the date of your request from our records and direct any service providers to do
            the same. In some cases, deletion may be accomplished through de-identification of the
            information. If you choose to delete your personal information, you may not be able to
            use certain functions that require your personal information to operate.<strong
              >&nbsp;</strong
            ></span
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 35.7pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <strong><span style="font-family: 'Arial', sans-serif">&nbsp;</span></strong>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <strong
            ><span style="font-family: 'Arial', sans-serif"
              >To stop selling your personal information</span
            ></strong
          ><span style="font-family: 'Arial', sans-serif"
            >. We do not sell your personal information for monetary consideration. However, under
            some circumstances, a transfer of personal information to a third party, or within our
            family of companies, without monetary consideration may be considered a
            &ldquo;sale&rdquo; under California law.</span
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 35.45pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 35.45pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >If you submit a request to stop selling your personal information, we will stop making such
        transfers. If you are a California resident, to opt-out of the sale of your personal
        information, click &ldquo;Do Not Sell My Personal Information&rdquo; at the bottom of our
        home page to submit your request.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 35.45pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 35.45pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >Please note, if you ask us to delete or stop selling your data, it may impact your
        experience with us, and you may not be able to participate in certain programs or membership
        services which require the usage of your personal information to function. But in no
        circumstances, we will discriminate against you for exercising your rights.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 35.45pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 35.45pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >To exercise your California data protection rights described above, please send your
        request(s) by one of the following means:</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 35.45pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 35.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">By email: hello@jobbuddytech.com</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 35.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >Your data protection rights, described above, are covered by the CCPA, short for the
        California Consumer Privacy Act. To find out more, visit the official&nbsp;</span
      ><a href="http://leginfo.legislature.ca.gov/faces/billTextClient.xhtml?bill_id=201720180AB375"
        ><span style="font-family: 'Arial', sans-serif"
          >California Legislative Information website</span
        ></a
      ><span style="font-family: 'Arial', sans-serif"
        >. The CCPA took effect on 01/01/2020.&nbsp;</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined; margin-left: 7.850000000000001px">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <strong
            ><u><span style="">Service Providers</span></u></strong
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <strong
        ><u><span style="font-family: 'Arial', sans-serif">&nbsp;</span></u></strong
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >We may employ third party companies and individuals to facilitate our Service
        (&ldquo;<strong>Service Providers</strong>&rdquo;), provide Service on our behalf, perform
        Service-related services or assist us in analysing how our Service is used.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >These third parties have access to your Personal Data only to perform these tasks on our
        behalf and are obligated not to disclose or use it for any other purpose.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <strong
        ><span style="font-family: 'Arial', sans-serif; background: lime">&nbsp;</span></strong
      >
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined; margin-left: 7.850000000000001px">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <strong
            ><u><span style="">Payments</span></u></strong
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <strong
        ><u><span style="font-family: 'Arial', sans-serif">&nbsp;</span></u></strong
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >We may provide paid products and/or services within Service. In that case, we use
        third-party services for payment processing (e.g. payment processors).</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >We will not store or collect your payment card details. That information is provided
        directly to our third-party payment processors whose use of your personal information is
        governed by their Privacy Policy. These payment processors adhere to the standards set by
        PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands
        like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the
        secure handling of payment information.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">The payment processors we work with are:</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        font-weight: bold;
      "
    >
      <span style="font-family: 'Arial', sans-serif">PayPal or Braintree:</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >Their Privacy Policy can be viewed at&nbsp;</span
      ><a href="https://www.paypal.com/webapps/mpp/ua/privacy-full"
        ><span style="font-family: 'Arial', sans-serif"
          >https://www.paypal.com/webapps/mpp/ua/privacy-full</span
        ></a
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        font-weight: bold;
      "
    >
      <span style="font-family: 'Arial', sans-serif">Apple Store In-App Payments:</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >Their Privacy Policy can be viewed at:&nbsp;</span
      ><a href="https://www.apple.com/legal/privacy/en-ww/"
        ><span style="font-family: 'Arial', sans-serif"
          >https://www.apple.com/legal/privacy/en-ww/</span
        ></a
      ><span style="font-family: 'Arial', sans-serif">&nbsp;/&nbsp;</span
      ><a href="https://support.apple.com/en-us/HT203027"
        ><span style="font-family: 'Arial', sans-serif"
          >https://support.apple.com/en-us/HT203027</span
        ></a
      ><span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        font-weight: bold;
      "
    >
      <span style="font-family: 'Arial', sans-serif">Stripe:</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >Their Privacy Policy can be viewed at:&nbsp;</span
      ><a href="https://stripe.com/us/privacy"
        ><span style="font-family: 'Arial', sans-serif">https://stripe.com/us/privacy</span></a
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined; margin-left: 7.850000000000001px">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <strong
            ><u><span style="">Links to Other Sites</span></u></strong
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <strong
        ><u><span style="font-family: 'Arial', sans-serif">&nbsp;</span></u></strong
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >Our Service may contain links to other sites that are not operated by us. If you click a
        third party link, you will be directed to that third party&apos;s site. We strongly advise
        you to review the Privacy Policy of every site you visit.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <strong
        ><u><span style="font-family: 'Arial', sans-serif">&nbsp;</span></u></strong
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >We have no control over and assume no responsibility for the content, privacy policies or
        practices of any third party sites or services.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined; margin-left: 7.850000000000001px">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <strong
            ><u
              ><span style="font-family: 'Arial', sans-serif">Children&apos;s Privacy</span></u
            ></strong
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 18pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 18pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >Our Services are not intended for use by children under the age of 13
        (&ldquo;<strong>Children</strong>&rdquo;).&nbsp;</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 18pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 18pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >We do not knowingly collect personally identifiable information from Children under 13. If
        you become aware that a Child has provided us with Personal Data, please contact us. If we
        become aware that we have collected Personal Data from Children without verification of
        parental consent, we take steps to remove that information from our servers.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined; margin-left: 7.850000000000001px">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <strong
            ><u><span style="">Changes to This Privacy Policy</span></u></strong
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <strong
        ><u><span style="font-family: 'Arial', sans-serif">&nbsp;</span></u></strong
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >We may update our Privacy Policy from time to time. We will notify you of any changes by
        posting the new Privacy Policy on this page.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <strong
        ><u><span style="font-family: 'Arial', sans-serif">&nbsp;</span></u></strong
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >We will let you know via email and/or a prominent notice on our Service, prior to the
        change becoming effective and update &ldquo;effective date&rdquo; at the top of this Privacy
        Policy.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >You are advised to review this Privacy Policy periodically for any changes. Changes to this
        Privacy Policy are effective when they are posted on this page.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined; margin-left: 7.850000000000001px">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            font-size: 15px;
            font-family: 'Times New Roman', serif;
          "
        >
          <strong
            ><u><span style="">Contact Us</span></u></strong
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <strong
        ><u><span style="font-family: 'Arial', sans-serif">&nbsp;</span></u></strong
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >If you have any questions about this Privacy Policy, please contact us by email:
        hello@jobbuddytech.com.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        font-size: 15px;
        font-family: 'Times New Roman', serif;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
  </div>
</template>

<script setup></script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #0275d8;
}
</style>
