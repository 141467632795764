export type ResumeExample = {
  Role: string;
  ExampleImgSrc: string;
  key: string; // Added key property
  WhyItWorked: string;
  ATSKeywords: string[];
};

// Function to convert a string to snake case
function toSnakeCase(str: string): string {
  return str.toLowerCase().replace(/ /g, "-");
}

export const resumeExamples: ResumeExample[] = [
  {
    Role: "Administrative Assistant",
    ExampleImgSrc: "AdminAssistant.webp",
    key: toSnakeCase("Administrative Assistant"),
    WhyItWorked:
      "This resume excelled due to the candidate's clear demonstration of required skills, backed by quantitative data. The professional format and relevant keywords made the information easily accessible for both hiring managers and the Applicant Tracking System (ATS)",
    ATSKeywords: ["Microsoft Office", "Google Suite", "Data Entry", "Scheduling", "Receptionist"],
  },
  {
    Role: "Bartender",
    ExampleImgSrc: "Bartender.webp",
    key: toSnakeCase("Bartender"),
    WhyItWorked:
      "This resume excelled due to the candidate's clear demonstration of required skills, backed by quantitative data. The professional format and relevant keywords made the information easily accessible for both hiring managers and the Applicant Tracking System (ATS)",
    ATSKeywords: [
      "Mixology",
      "Customer Service",
      "Cash Handling",
      "POS Systems",
      "Inventory Management",
    ],
  },
  {
    Role: "Customer Service Representative",
    ExampleImgSrc: "CustomerServiceRepresentative.webp",
    key: toSnakeCase("Customer Service Representative"),
    WhyItWorked:
      "This resume excelled due to the candidate's clear demonstration of required skills, backed by quantitative data. The professional format and relevant keywords made the information easily accessible for both hiring managers and the Applicant Tracking System (ATS)",
    ATSKeywords: ["Customer Service", "Call Center", "Troubleshooting", "Sales", "CRM"],
  },
  {
    Role: "Data Analyst",
    ExampleImgSrc: "DataAnalyst.webp",
    key: toSnakeCase("Data Analyst"),
    WhyItWorked:
      "This resume excelled due to the candidate's clear demonstration of required skills, backed by quantitative data. The professional format and relevant keywords made the information easily accessible for both hiring managers and the Applicant Tracking System (ATS)",
    ATSKeywords: ["SQL", "Python", "Data Visualization", "Excel", "Data Cleaning"],
  },
  {
    Role: "Executive Assistant",
    ExampleImgSrc: "ExecutiveAssistant.webp",
    key: toSnakeCase("Executive Assistant"),
    WhyItWorked:
      "This resume excelled due to the candidate's clear demonstration of required skills, backed by quantitative data. The professional format and relevant keywords made the information easily accessible for both hiring managers and the Applicant Tracking System (ATS)",
    ATSKeywords: [
      "Calendar Management",
      "Travel Arrangements",
      "Expense Reports",
      "Meeting Coordination",
      "Project Management",
    ],
  },
  {
    Role: "HR",
    ExampleImgSrc: "HR.webp",
    key: toSnakeCase("HR"),
    WhyItWorked:
      "This resume excelled due to the candidate's clear demonstration of required skills, backed by quantitative data. The professional format and relevant keywords made the information easily accessible for both hiring managers and the Applicant Tracking System (ATS)",
    ATSKeywords: [
      "Recruiting",
      "Employee Relations",
      "Benefits Administration",
      "HRIS",
      "Compliance",
    ],
  },
  {
    Role: "High School Teacher",
    ExampleImgSrc: "HighSchoolTeacher.webp",
    key: toSnakeCase("High School Teacher"),
    WhyItWorked:
      "This resume excelled due to the candidate's clear demonstration of required skills, backed by quantitative data. The professional format and relevant keywords made the information easily accessible for both hiring managers and the Applicant Tracking System (ATS)",
    ATSKeywords: [
      "Lesson Planning",
      "Classroom Management",
      "Differentiated Instruction",
      "Curriculum Development",
      "Parent Communication",
    ],
  },
  {
    Role: "Lab Technician",
    ExampleImgSrc: "LabTechnician.webp",
    key: toSnakeCase("Lab Technician"),
    WhyItWorked:
      "This resume excelled due to the candidate's clear demonstration of required skills, backed by quantitative data. The professional format and relevant keywords made the information easily accessible for both hiring managers and the Applicant Tracking System (ATS)",
    ATSKeywords: [
      "Laboratory Techniques",
      "Data Analysis",
      "Quality Control",
      "Sample Collection",
      "Research",
    ],
  },
  {
    Role: "Marketing",
    ExampleImgSrc: "Marketing.webp",
    key: toSnakeCase("Marketing"),
    WhyItWorked:
      "This resume excelled due to the candidate's clear demonstration of required skills, backed by quantitative data. The professional format and relevant keywords made the information easily accessible for both hiring managers and the Applicant Tracking System (ATS)",
    ATSKeywords: ["Digital Marketing", "Social Media", "SEO", "Content Creation", "Analytics"],
  },
  {
    Role: "Nurse",
    ExampleImgSrc: "Nurse.webp",
    key: toSnakeCase("Nurse"),
    WhyItWorked:
      "This resume excelled due to the candidate's clear demonstration of required skills, backed by quantitative data. The professional format and relevant keywords made the information easily accessible for both hiring managers and the Applicant Tracking System (ATS)",
    ATSKeywords: [
      "Patient Care",
      "Medication Administration",
      "EMR",
      "Wound Care",
      "Critical Thinking",
    ],
  },
  {
    Role: "Police Officer",
    ExampleImgSrc: "PoliceOfficer.webp",
    key: toSnakeCase("Police Officer"),
    WhyItWorked:
      "This resume excelled due to the candidate's clear demonstration of required skills, backed by quantitative data. The professional format and relevant keywords made the information easily accessible for both hiring managers and the Applicant Tracking System (ATS)",
    ATSKeywords: [
      "Law Enforcement",
      "Criminal Justice",
      "Emergency Response",
      "Community Policing",
      "Report Writing",
    ],
  },
  {
    Role: "Project Manager",
    ExampleImgSrc: "ProjectManager.webp",
    key: toSnakeCase("Project Manager"),
    WhyItWorked:
      "This resume excelled due to the candidate's clear demonstration of required skills, backed by quantitative data. The professional format and relevant keywords made the information easily accessible for both hiring managers and the Applicant Tracking System (ATS)",
    ATSKeywords: ["Project Management", "Agile", "Scrum", "Budgeting", "Stakeholder Management"],
  },
  {
    Role: "Research Assistant",
    ExampleImgSrc: "ResearchAssistant.webp",
    key: toSnakeCase("Research Assistant"),
    WhyItWorked:
      "This resume excelled due to the candidate's clear demonstration of required skills, backed by quantitative data. The professional format and relevant keywords made the information easily accessible for both hiring managers and the Applicant Tracking System (ATS)",
    ATSKeywords: ["Research", "Data Collection", "Literature Review", "Lab Techniques", "Analysis"],
  },
  {
    Role: "Retail",
    ExampleImgSrc: "Retail.webp",
    key: toSnakeCase("Retail"),
    WhyItWorked:
      "This resume excelled due to the candidate's clear demonstration of required skills, backed by quantitative data. The professional format and relevant keywords made the information easily accessible for both hiring managers and the Applicant Tracking System (ATS)",
    ATSKeywords: [
      "Sales",
      "Customer Service",
      "Inventory Management",
      "Visual Merchandising",
      "POS Systems",
    ],
  },
  {
    Role: "Student",
    ExampleImgSrc: "Student.webp",
    key: toSnakeCase("Student"),
    WhyItWorked:
      "This resume excelled due to the candidate's clear demonstration of required skills, backed by quantitative data. The professional format and relevant keywords made the information easily accessible for both hiring managers and the Applicant Tracking System (ATS)",
    ATSKeywords: [
      "Education",
      "Internships",
      "Leadership",
      "Teamwork",
      "Communication",
      "Skills",
      "GPA",
    ],
  },
];

// You can now use these `key` properties for URL slugs or other identifiers.
