<template>
  <div class="w-80 container pt-2">
    <h1>Terms of Service</h1>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: center;
      "
    ></p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: center;
      "
    >
      <strong><span style="font-family: 'Arial', sans-serif">&nbsp;</span></strong>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    ></p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 15px;
        font-family: 'Cambria', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: decimal; margin-left: 7.850000000000001px">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            line-height: 115%;
            font-size: 15px;
            font-family: 'Cambria', serif;
          "
        >
          <strong
            ><u><span style="">Introduction</span></u></strong
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">Welcome to&nbsp;</span
      ><strong><span style="font-family: 'Arial', sans-serif">JobBuddy</span></strong
      ><span style="font-family: 'Arial', sans-serif"
        >&nbsp;(&ldquo;<strong>Company</strong>&rdquo;, &ldquo;<strong>we</strong>&rdquo;,
        &ldquo;<strong>our</strong>&rdquo;, &ldquo;<strong>us</strong>&rdquo;)<span style=""
          >! As you have just clicked our Terms of Service, please pause, grab a cup of coffee and
          carefully read the following pages. It will take you approximately 20&nbsp;minutes.</span
        ></span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >These Terms of Service (&ldquo;<strong>Terms</strong>&rdquo;, &ldquo;<strong
          >Terms of Service</strong
        >&rdquo;) govern your use of&nbsp;our web pages located at www.jobbuddytech.com</span
      ><span style="font-family: 'Arial', sans-serif">&nbsp;operated by JobBuddy</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >Our Privacy Policy also governs your use of our Service and explains how we collect,
        safeguard and disclose information that results from your use of our web pages. Please read
        it here&nbsp;</span
      ><span style="font-family: 'Arial', sans-serif"
        >www.jobbuddytech.com/privacypolicy<span style="">.</span></span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >Your agreement with us includes these Terms&nbsp;and our Privacy
        Policy&nbsp;(&ldquo;<strong>Agreements</strong>&rdquo;). You acknowledge that you have read
        and understood Agreements, and agree to be bound of them.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >If you do not agree with (or cannot comply with) Agreements, then you may not use the
        Service, but please let us know by emailing at&nbsp;</span
      ><span style="font-family: 'Arial', sans-serif"
        >hello@jobbuddytech.com<span style=""
          >&nbsp;so we can try to find a solution. These Terms apply to all visitors, users and
          others who wish to access or use Service.</span
        ></span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">Thank you for being responsible.</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 15px;
        font-family: 'Cambria', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined; margin-left: 7.850000000000001px">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            line-height: 115%;
            font-size: 15px;
            font-family: 'Cambria', serif;
          "
        >
          <strong
            ><u><span style="">Communications</span></u></strong
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >By creating an Account on our Service, you agree to subscribe to newsletters, marketing or
        promotional materials and other information we may send. However, you may opt out of
        receiving any, or all, of these communications from us by following the unsubscribe link or
        by emailing at.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 15px;
        font-family: 'Cambria', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined; margin-left: 7.850000000000001px">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            line-height: 115%;
            font-size: 15px;
            font-family: 'Cambria', serif;
          "
        >
          <strong
            ><u><span style="">Purchases</span></u></strong
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >If you wish to purchase any product or service made available through Service
        (&ldquo;<strong>Purchase</strong>&rdquo;), you may be asked to supply certain information
        relevant to your Purchase including, without limitation, your credit card number, the
        expiration date of your credit card, your billing address, and your shipping
        information.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >You represent and warrant that: (i) you have the legal right to use any credit card(s) or
        other payment method(s) in connection with any Purchase; and that (ii) the information you
        supply to us is true, correct and complete.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >We may employ the use of third party services for the purpose of facilitating payment and
        the completion of Purchases. By submitting your information, you grant us the right to
        provide the information to these third parties&nbsp;subject to our Privacy Policy.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >We reserve the right to refuse or cancel your order at any time for reasons including but
        not limited to: product or service availability, errors in the description or price of the
        product or service, error in your order or other reasons.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >We reserve the right to refuse or cancel your order if fraud or an unauthorized or illegal
        transaction is suspected.&nbsp;</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 15px;
        font-family: 'Cambria', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined; margin-left: 7.850000000000001px">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            line-height: 115%;
            font-size: 15px;
            font-family: 'Cambria', serif;
          "
        >
          <strong
            ><u><span style="">Subscriptions</span></u></strong
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >Some parts of Service are billed on a subscription basis
        (&ldquo;<strong>Subscription(s)</strong>&rdquo;). You will be billed in advance on a
        recurring and periodic basis (&ldquo;<strong>Billing Cycle</strong>&rdquo;). Billing cycles
        are set&nbsp;on a monthly basis.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >At the end of each Billing Cycle, your Subscription will automatically renew under the
        exact same conditions unless you cancel it or&nbsp;</span
      ><span style="font-family: 'Arial', sans-serif"
        >JobBuddy&nbsp;cancels it. You may cancel your Subscription renewal either through your
        online account management page or by contacting&nbsp;JobBuddy&nbsp;customer support
        team.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >A valid payment method, including&nbsp;credit card or PayPal, is required to process the
        payment for your subscription. You shall provide&nbsp;JobBuddy&nbsp;with accurate and
        complete billing information including full name, address, state, zip code, telephone
        number, and a valid payment method information. By submitting such payment information, you
        automatically authorize&nbsp;JobBuddy&nbsp;to charge all Subscription fees incurred through
        your account to any such payment instruments.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >Should automatic billing fail to occur for any reason,&nbsp;</span
      ><span style="font-family: 'Arial', sans-serif"
        >JobBuddy&nbsp;will issue an electronic invoice indicating that you must proceed manually,
        within a certain deadline date, with the full payment corresponding to the billing period as
        indicated on the invoice.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 15px;
        font-family: 'Cambria', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined; margin-left: 7.850000000000001px">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            line-height: 115%;
            font-size: 15px;
            font-family: 'Cambria', serif;
          "
        >
          <strong
            ><u><span style="">Free Trial</span></u></strong
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >JobBuddy&nbsp;may, at its sole discretion, offer a Subscription with a free trial for a
        limited period of time (&ldquo;<strong><span style="">Free Trial</span></strong
        ><span style="">&rdquo;).</span></span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >You may be required to enter your billing information in order to sign up for Free
        Trial.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >If you do enter your billing information when signing up for Free Trial, you will not be
        charged by&nbsp;</span
      ><span style="font-family: 'Arial', sans-serif"
        >JobBuddy&nbsp;until Free Trial has expired. On the last day of Free Trial period, unless
        you cancelled your Subscription, you will be automatically charged the applicable
        Subscription fees for the type of Subscription you have selected.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">At any time and without notice,&nbsp;</span
      ><span style="font-family: 'Arial', sans-serif"
        >JobBuddy&nbsp;reserves the right to (i) modify Terms of Service of Free Trial offer, or
        (ii) cancel such Free Trial offer.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 15px;
        font-family: 'Cambria', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined; margin-left: 7.850000000000001px">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            line-height: 115%;
            font-size: 15px;
            font-family: 'Cambria', serif;
          "
        >
          <strong
            ><u><span style="">Fee Changes</span></u></strong
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >JobBuddy, in its sole discretion and at any time, may modify Subscription fees for the
        Subscriptions. Any Subscription fee change will become effective at the end of the
        then-current Billing Cycle.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >JobBuddy&nbsp;will provide you with a reasonable prior notice of any change in Subscription
        fees to give you an opportunity to terminate your Subscription before such change becomes
        effective.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >Your continued use of Service after Subscription fee change comes into effect constitutes
        your agreement to pay the modified Subscription fee amount.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 15px;
        font-family: 'Cambria', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined; margin-left: 7.850000000000001px">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            line-height: 115%;
            font-size: 15px;
            font-family: 'Cambria', serif;
          "
        >
          <strong
            ><u><span style="">Refunds</span></u></strong
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >We issue refunds for Contracts within twenty-eight (28) days of the original purchase of
        the Contract.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 15px;
        font-family: 'Cambria', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined; margin-left: 7.850000000000001px">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            line-height: 115%;
            font-size: 15px;
            font-family: 'Cambria', serif;
          "
        >
          <strong
            ><u><span style="">Content</span></u></strong
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >Our Service allows you to post, link, store, share and otherwise make available certain
        information, text, graphics, videos, or other material
        (&ldquo;<strong>Content</strong>&rdquo;). You are responsible for Content that you post on
        or through Service, including its legality, reliability, and appropriateness.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >By posting Content on or through Service, You represent and warrant that: (i) Content is
        yours (you own it) and/or you have the right to use it and the right to grant us the rights
        and license as provided in these Terms, and (ii) that the posting of your Content on or
        through Service does not violate the privacy rights, publicity rights, copyrights, contract
        rights or any other rights of any person or entity. We reserve the right to terminate the
        account of anyone found to be infringing on a copyright.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >You retain any and all of your rights to any Content you submit, post or display on or
        through Service and you are responsible for protecting those rights. We take no
        responsibility and assume no liability for Content you or any third party posts on or
        through Service. However, by posting Content using Service you grant us the right and
        license to use, modify, publicly perform, publicly display, reproduce, and distribute such
        Content on and through Service. You agree that this license includes the right for us to
        make your Content available to other users of Service, who may also use your Content subject
        to these Terms.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >JobBuddy&nbsp;has the right but not the obligation to monitor and edit all Content provided
        by users.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >In addition, Content found on or through this Service are the property of&nbsp;</span
      ><span style="font-family: 'Arial', sans-serif"
        >JobBuddy&nbsp;or used with permission. You may not distribute, modify, transmit, reuse,
        download, repost, copy, or use said Content, whether in whole or in part, for commercial
        purposes or for personal gain, without express advance written permission from us.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 15px;
        font-family: 'Cambria', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined; margin-left: 7.850000000000001px">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            line-height: 115%;
            font-size: 15px;
            font-family: 'Cambria', serif;
          "
        >
          <strong
            ><u><span style="">Prohibited Uses</span></u></strong
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >You may use Service only for lawful purposes and in accordance with Terms. You agree not to
        use Service:</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 15px;
        font-family: 'Cambria', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            line-height: 115%;
            font-size: 15px;
            font-family: 'Cambria', serif;
          "
        >
          <span style="font-family: 'Arial', sans-serif"
            >In any way that violates any applicable national or international law or
            regulation.</span
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 35.7pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 15px;
        font-family: 'Cambria', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            line-height: 115%;
            font-size: 15px;
            font-family: 'Cambria', serif;
          "
        >
          <span style="font-family: 'Arial', sans-serif"
            >For the purpose of exploiting, harming, or attempting to exploit or harm minors in any
            way by exposing them to inappropriate content or otherwise.</span
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 35.7pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 15px;
        font-family: 'Cambria', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            line-height: 115%;
            font-size: 15px;
            font-family: 'Cambria', serif;
          "
        >
          <span style="font-family: 'Arial', sans-serif"
            >To transmit, or procure the sending of, any advertising or promotional material,
            including any &ldquo;junk mail&rdquo;, &ldquo;chain letter,&rdquo; &ldquo;spam,&rdquo;
            or any other similar solicitation.</span
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 35.7pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 15px;
        font-family: 'Cambria', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            line-height: 115%;
            font-size: 15px;
            font-family: 'Cambria', serif;
          "
        >
          <span style="font-family: 'Arial', sans-serif"
            >To impersonate or attempt to impersonate Company, a Company employee, another user, or
            any other person or entity.</span
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 35.7pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 15px;
        font-family: 'Cambria', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            line-height: 115%;
            font-size: 15px;
            font-family: 'Cambria', serif;
          "
        >
          <span style="font-family: 'Arial', sans-serif"
            >In any way that infringes upon the rights of others, or in any way is illegal,
            threatening, fraudulent, or harmful, or in connection with any unlawful, illegal,
            fraudulent, or harmful purpose or activity.</span
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 35.7pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 15px;
        font-family: 'Cambria', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            line-height: 115%;
            font-size: 15px;
            font-family: 'Cambria', serif;
          "
        >
          <span style="font-family: 'Arial', sans-serif"
            >To engage in any other conduct that restricts or inhibits anyone&rsquo;s use or
            enjoyment of Service, or which, as determined by us, may harm or offend Company or users
            of Service or expose them to liability.</span
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">Additionally, you agree not to:</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 15px;
        font-family: 'Cambria', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            line-height: 115%;
            font-size: 15px;
            font-family: 'Cambria', serif;
          "
        >
          <span style="font-family: 'Arial', sans-serif"
            >Use Service in any manner that could disable, overburden, damage, or impair Service or
            interfere with any other party&rsquo;s use of Service, including their ability to engage
            in real time activities through Service.</span
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 35.7pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 15px;
        font-family: 'Cambria', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            line-height: 115%;
            font-size: 15px;
            font-family: 'Cambria', serif;
          "
        >
          <span style="font-family: 'Arial', sans-serif"
            >Use any robot, spider, or other automatic device, process, or means to access Service
            for any purpose, including monitoring or copying any of the material on Service.</span
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 35.7pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 15px;
        font-family: 'Cambria', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            line-height: 115%;
            font-size: 15px;
            font-family: 'Cambria', serif;
          "
        >
          <span style="font-family: 'Arial', sans-serif"
            >Use any manual process to monitor or copy any of the material on Service or for any
            other unauthorized purpose without our prior written consent.</span
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 35.7pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 15px;
        font-family: 'Cambria', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            line-height: 115%;
            font-size: 15px;
            font-family: 'Cambria', serif;
          "
        >
          <span style="font-family: 'Arial', sans-serif"
            >Use any device, software, or routine that interferes with the proper working of
            Service.</span
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 35.7pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 15px;
        font-family: 'Cambria', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            line-height: 115%;
            font-size: 15px;
            font-family: 'Cambria', serif;
          "
        >
          <span style="font-family: 'Arial', sans-serif"
            >Introduce any viruses, trojan horses, worms, logic bombs, or other material which is
            malicious or technologically harmful.</span
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 35.7pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 15px;
        font-family: 'Cambria', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            line-height: 115%;
            font-size: 15px;
            font-family: 'Cambria', serif;
          "
        >
          <span style="font-family: 'Arial', sans-serif"
            >Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of
            Service, the server on which Service is stored, or any server, computer, or database
            connected to Service.</span
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 35.7pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 15px;
        font-family: 'Cambria', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            line-height: 115%;
            font-size: 15px;
            font-family: 'Cambria', serif;
          "
        >
          <span style="font-family: 'Arial', sans-serif"
            >Attack Service via a denial-of-service attack or a distributed denial-of-service
            attack.</span
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 35.7pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 15px;
        font-family: 'Cambria', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            line-height: 115%;
            font-size: 15px;
            font-family: 'Cambria', serif;
          "
        >
          <span style="font-family: 'Arial', sans-serif"
            >Take any action that may damage or falsify Company rating.</span
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 35.7pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 15px;
        font-family: 'Cambria', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            line-height: 115%;
            font-size: 15px;
            font-family: 'Cambria', serif;
          "
        >
          <span style="font-family: 'Arial', sans-serif"
            >Otherwise attempt to interfere with the proper working of Service.</span
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 15px;
        font-family: 'Cambria', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined; margin-left: 7.850000000000001px">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            line-height: 115%;
            font-size: 15px;
            font-family: 'Cambria', serif;
          "
        >
          <strong
            ><u><span style="">Analytics</span></u></strong
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <strong
        ><u><span style="font-family: 'Arial', sans-serif">&nbsp;</span></u></strong
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >We may use third-party Service Providers to monitor and analyze the use of our
        Service.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <strong><span style="font-family: 'Arial', sans-serif">Google Analytics</span></strong>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >Google Analytics is a web analytics service offered by Google that tracks and reports
        website traffic. Google uses the data collected to track and monitor the use of our Service.
        This data is shared with other Google services. Google may use the collected data to
        contextualise and personalise the ads of its own advertising network.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >For more information on the privacy practices of Google, please visit the Google Privacy
        Terms web page:&nbsp;</span
      ><a href="https://policies.google.com/privacy?hl=en"
        ><span style="font-family: 'Arial', sans-serif"
          >https://policies.google.com/privacy?hl=en</span
        ></a
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <u><span style="font-family: 'Arial', sans-serif; color: blue">&nbsp;</span></u>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >We also encourage you to review the Google&apos;s policy for safeguarding your
        data:&nbsp;</span
      ><a href="https://support.google.com/analytics/answer/6004245"
        ><span style="font-family: 'Arial', sans-serif"
          >https://support.google.com/analytics/answer/6004245</span
        ></a
      ><span style="font-family: 'Arial', sans-serif">.</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 15px;
        font-family: 'Cambria', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined; margin-left: 7.850000000000001px">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            line-height: 115%;
            font-size: 15px;
            font-family: 'Cambria', serif;
          "
        >
          <strong
            ><u><span style="">No Use By Minors</span></u></strong
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >Service is intended only for access and use by individuals at least eighteen (18) years
        old. By accessing or using any of Company, you warrant and represent that you are at least
        eighteen (18) years of age and with the full authority, right, and capacity to enter into
        this agreement and abide by all of the terms and conditions of Terms. If you are not at
        least eighteen (18) years old, you are prohibited from both the access and usage of
        Service.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 15px;
        font-family: 'Cambria', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined; margin-left: 7.850000000000001px">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            line-height: 115%;
            font-size: 15px;
            font-family: 'Cambria', serif;
          "
        >
          <strong
            ><u><span style="">Accounts</span></u></strong
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >When you create an account with us, you guarantee that you are above the age of 18, and
        that the information you provide us is accurate, complete, and current at all times.
        Inaccurate, incomplete, or obsolete information may result in the immediate termination of
        your account on Service.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >You are responsible for maintaining the confidentiality of your account and password,
        including but not limited to the restriction of access to your computer and/or account. You
        agree to accept responsibility for any and all activities or actions that occur under your
        account and/or password, whether your password is with our Service or a third-party service.
        You must notify us immediately upon becoming aware of any breach of security or unauthorized
        use of your account.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >You may not use as a username the name of another person or entity or that is not lawfully
        available for use, a name or trademark that is subject to any rights of another person or
        entity other than you, without appropriate authorization. You may not use as a username any
        name that is offensive, vulgar or obscene.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >We reserve the right to refuse service, terminate accounts, remove or edit content, or
        cancel orders in our sole discretion.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 15px;
        font-family: 'Cambria', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined; margin-left: 7.850000000000001px">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            line-height: 115%;
            font-size: 15px;
            font-family: 'Cambria', serif;
          "
        >
          <strong
            ><u><span style="">Intellectual Property</span></u></strong
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >Service and its original content (excluding Content provided by users), features and
        functionality are and will remain the exclusive property of&nbsp;</span
      ><span style="font-family: 'Arial', sans-serif"
        >JobBuddy&nbsp;and its licensors. Service is protected by copyright, trademark, and other
        laws of&nbsp;the United States and foreign countries. Our trademarks and trade dress may not
        be used in connection with any product or service without the prior written consent
        of&nbsp;JobBuddy.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 15px;
        font-family: 'Cambria', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined; margin-left: 7.850000000000001px">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            line-height: 115%;
            font-size: 15px;
            font-family: 'Cambria', serif;
          "
        >
          <strong
            ><u><span style="">Error Reporting and Feedback</span></u></strong
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >You may provide us&nbsp;directly at hello@jobbuddytech.com&nbsp;with information and
        feedback concerning errors, suggestions for improvements, ideas, problems, complaints, and
        other matters related to our Service (&ldquo;<strong>Feedback</strong>&rdquo;). You
        acknowledge and agree that: (i) you shall not retain, acquire or assert any intellectual
        property right or other right, title or interest in or to the Feedback; (ii) Company may
        have development ideas similar to the Feedback; (iii) Feedback does not contain confidential
        information or proprietary information from you or any third party; and (iv) Company is not
        under any obligation of confidentiality with respect to the Feedback. In the event the
        transfer of the ownership to the Feedback is not possible due to applicable mandatory laws,
        you grant Company and its affiliates an exclusive, transferable, irrevocable,
        free-of-charge, sub-licensable, unlimited and perpetual right to use (including copy,
        modify, create derivative works, publish, distribute and commercialize) Feedback in any
        manner and for any purpose.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 15px;
        font-family: 'Cambria', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined; margin-left: 7.850000000000001px">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            line-height: 115%;
            font-size: 15px;
            font-family: 'Cambria', serif;
          "
        >
          <strong
            ><u><span style="">Links To Other Web Sites</span></u></strong
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >Our Service may contain links to third party web sites or services that are not owned or
        controlled by&nbsp;</span
      ><span style="font-family: 'Arial', sans-serif">JobBuddy</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >JobBuddy&nbsp;has no control over, and assumes no responsibility for the content, privacy
        policies, or practices of any third party web sites or services. We do not warrant the
        offerings of any of these entities/individuals or their websites.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">YOU ACKNOWLEDGE AND AGREE THAT&nbsp;</span
      ><span style="font-family: 'Arial', sans-serif">JobBuddy</span
      ><span style="font-family: 'Arial', sans-serif"
        >&nbsp;<span style=""
          >SHALL NOT BE RESPONSIBLE OR LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY DAMAGE OR LOSS CAUSED
          OR ALLEGED TO BE CAUSED BY OR IN CONNECTION WITH USE OF OR RELIANCE ON ANY SUCH CONTENT,
          GOODS OR SERVICES AVAILABLE ON OR THROUGH ANY SUCH THIRD PARTY WEB SITES OR
          SERVICES.</span
        ></span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND PRIVACY POLICIES OF ANY THIRD PARTY
        WEB SITES OR SERVICES THAT YOU VISIT.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 15px;
        font-family: 'Cambria', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined; margin-left: 7.850000000000001px">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            line-height: 115%;
            font-size: 15px;
            font-family: 'Cambria', serif;
          "
        >
          <strong
            ><u><span style="">Disclaimer Of Warranty&nbsp;</span></u></strong
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >THESE SERVICES ARE PROVIDED BY COMPANY ON AN &ldquo;AS IS&rdquo; AND &ldquo;AS
        AVAILABLE&rdquo; BASIS. COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS
        OR IMPLIED, AS TO THE OPERATION OF THEIR SERVICES, OR THE INFORMATION, CONTENT OR MATERIALS
        INCLUDED THEREIN. YOU EXPRESSLY AGREE THAT YOUR USE OF THESE SERVICES, THEIR CONTENT, AND
        ANY SERVICES OR ITEMS OBTAINED FROM US IS AT YOUR SOLE RISK.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >NEITHER COMPANY NOR ANY PERSON ASSOCIATED WITH COMPANY MAKES ANY WARRANTY OR REPRESENTATION
        WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY
        OF THE SERVICES. WITHOUT LIMITING THE FOREGOING, NEITHER COMPANY NOR ANYONE ASSOCIATED WITH
        COMPANY REPRESENTS OR WARRANTS THAT THE SERVICES, THEIR CONTENT, OR ANY SERVICES OR ITEMS
        OBTAINED THROUGH THE SERVICES WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT
        DEFECTS WILL BE CORRECTED, THAT THE SERVICES OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE
        OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SERVICES OR ANY SERVICES OR ITEMS
        OBTAINED THROUGH THE SERVICES WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY,
        OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY,
        NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER
        APPLICABLE LAW.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 15px;
        font-family: 'Cambria', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined; margin-left: 7.850000000000001px">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            line-height: 115%;
            font-size: 15px;
            font-family: 'Cambria', serif;
          "
        >
          <strong
            ><u><span style="">Limitation Of Liability</span></u></strong
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS, DIRECTORS, EMPLOYEES, AND
        AGENTS HARMLESS FOR ANY INDIRECT, PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGE,
        HOWEVER IT ARISES (INCLUDING ATTORNEYS&apos; FEES AND ALL RELATED COSTS AND EXPENSES OF
        LITIGATION AND ARBITRATION, OR AT TRIAL OR ON APPEAL, IF ANY, WHETHER OR NOT LITIGATION OR
        ARBITRATION IS INSTITUTED), WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS
        ACTION, OR ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, INCLUDING WITHOUT LIMITATION
        ANY CLAIM FOR PERSONAL INJURY OR PROPERTY DAMAGE, ARISING FROM THIS AGREEMENT AND ANY
        VIOLATION BY YOU OF ANY FEDERAL, STATE, OR LOCAL LAWS, STATUTES, RULES, OR REGULATIONS, EVEN
        IF COMPANY HAS BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. EXCEPT AS
        PROHIBITED BY LAW, IF THERE IS LIABILITY FOUND ON THE PART OF COMPANY, IT WILL BE LIMITED TO
        THE AMOUNT PAID FOR THE PRODUCTS AND/OR SERVICES, AND UNDER NO CIRCUMSTANCES WILL THERE BE
        CONSEQUENTIAL OR PUNITIVE DAMAGES. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
        PUNITIVE, INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE PRIOR LIMITATION OR EXCLUSION MAY NOT
        APPLY TO YOU.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 15px;
        font-family: 'Cambria', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined; margin-left: 7.850000000000001px">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            line-height: 115%;
            font-size: 15px;
            font-family: 'Cambria', serif;
          "
        >
          <strong
            ><u><span style="">Termination</span></u></strong
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >We may terminate or suspend your account and bar access to Service immediately, without
        prior notice or liability, under our sole discretion, for any reason whatsoever and without
        limitation, including but not limited to a breach of Terms.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >If you wish to terminate your account, you may simply discontinue using Service.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >All provisions of Terms which by their nature should survive termination shall survive
        termination, including, without limitation, ownership provisions, warranty disclaimers,
        indemnity and limitations of liability.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 15px;
        font-family: 'Cambria', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined; margin-left: 7.850000000000001px">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            line-height: 115%;
            font-size: 15px;
            font-family: 'Cambria', serif;
          "
        >
          <strong
            ><u><span style="">Governing Law</span></u></strong
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >These Terms shall be governed and construed in accordance with the laws of&nbsp;the United
        Kingdom&nbsp;without regard to its conflict of law provisions.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >Our failure to enforce any right or provision of these Terms will not be considered a
        waiver of those rights. If any provision of these Terms is held to be invalid or
        unenforceable by a court, the remaining provisions of these Terms will remain in effect.
        These Terms constitute the entire agreement between us regarding our Service and supersede
        and replace any prior agreements we might have had between us regarding Service.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 15px;
        font-family: 'Cambria', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined; margin-left: 7.850000000000001px">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            line-height: 115%;
            font-size: 15px;
            font-family: 'Cambria', serif;
          "
        >
          <strong
            ><u><span style="">Changes To Service</span></u></strong
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >We reserve the right to withdraw or amend our Service, and any service or material we
        provide via Service, in our sole discretion without notice. We will not be liable if for any
        reason all or any part of Service is unavailable at any time or for any period. From time to
        time, we may restrict access to some parts of Service, or the entire Service, to users,
        including registered users.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 15px;
        font-family: 'Cambria', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined; margin-left: 7.850000000000001px">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            line-height: 115%;
            font-size: 15px;
            font-family: 'Cambria', serif;
          "
        >
          <strong
            ><u><span style="">Amendments To Terms</span></u></strong
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >We may amend Terms at any time by posting the amended terms on this site. It is your
        responsibility to review these Terms periodically.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >Your continued use of the Platform following the posting of revised Terms means that you
        accept and agree to the changes. You are expected to check this page frequently so you are
        aware of any changes, as they are binding on you.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >By continuing to access or use our Service after any revisions become effective, you agree
        to be bound by the revised terms. If you do not agree to the new terms, you are no longer
        authorized to use Service.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 15px;
        font-family: 'Cambria', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined; margin-left: 7.850000000000001px">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            line-height: 115%;
            font-size: 15px;
            font-family: 'Cambria', serif;
          "
        >
          <strong
            ><u><span style="">Waiver And Severability</span></u></strong
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >No waiver by Company of any term or condition set forth in Terms shall be deemed a further
        or continuing waiver of such term or condition or a waiver of any other term or condition,
        and any failure of Company to assert a right or provision under Terms shall not constitute a
        waiver of such right or provision.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >If any provision of Terms is held by a court or other tribunal of competent jurisdiction to
        be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or
        limited to the minimum extent such that the remaining provisions of Terms will continue in
        full force and effect.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 15px;
        font-family: 'Cambria', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined; margin-left: 7.850000000000001px">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            line-height: 115%;
            font-size: 15px;
            font-family: 'Cambria', serif;
          "
        >
          <strong
            ><u><span style="">Acknowledgement</span></u></strong
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE THAT YOU HAVE READ THESE
        TERMS OF SERVICE AND AGREE TO BE BOUND BY THEM.</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <div
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 15px;
        font-family: 'Cambria', serif;
      "
    >
      <ol style="margin-bottom: 0cm; list-style-type: undefined; margin-left: 7.850000000000001px">
        <li
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10pt;
            margin-left: 0cm;
            line-height: 115%;
            font-size: 15px;
            font-family: 'Cambria', serif;
          "
        >
          <strong
            ><u><span style="">Contact Us</span></u></strong
          >
        </li>
      </ol>
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif"
        >Please send your feedback, comments, requests for technical support:</span
      >
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0cm;
        margin-left: 17.85pt;
        line-height: normal;
        font-size: 15px;
        font-family: 'Cambria', serif;
        text-align: justify;
      "
    >
      <span style="font-family: 'Arial', sans-serif">By email: hello@jobbuddytech.com.</span>
    </p>
  </div>
</template>

<script setup></script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #0275d8;
}
</style>
