import { type ApplicationStatus } from '../SystemEnums'

export default class CreateOrUpdateApplication {
  id: string | null
  userId: string
  companyName: string
  roleTitle: string
  jobDescription: string
  companyDescription: string
  roleComments: string
  applicationStatus: ApplicationStatus
  index: number

  constructor (id: string | null, userId: string, companyName: string, roleTitle: string, jobDescription: string, companyDescription: string, roleComments: string, applicationStatus: ApplicationStatus, index: number) {
    this.id = id
    this.userId = userId
    this.companyName = companyName
    this.roleTitle = roleTitle
    this.jobDescription = jobDescription
    this.companyDescription = companyDescription
    this.roleComments = roleComments
    this.applicationStatus = applicationStatus
    this.index = index
  }
}
