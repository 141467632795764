import { type ResponseDto } from './dtos/ResponseDtos'

export class ResponseFeedback {
  // Keep a copy of the response, not just an ID because users will probably delete their bad responses
  positiveFeedback: boolean
  response: ResponseDto
  accuracy: number
  formatting: number
  communication: number
  overallRating: number
  comments: string

  constructor (positiveFeedback: boolean, response: ResponseDto, accuracy: number, formatting: number, communication: number, overallRating: number, comments: string) {
    this.positiveFeedback = positiveFeedback
    this.response = response
    this.accuracy = accuracy
    this.formatting = formatting
    this.communication = communication
    this.overallRating = overallRating
    this.comments = comments
  }
}
