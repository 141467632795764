<template>
  <div class="container font-weight-light mt-3">
    <div class="text-center mb-4">
      <h2 class="fw-bold mb-2 display-8">
        {{ resumeExample?.Role }}<span class="text-primary"> Resume Example</span>
      </h2>
      <h5 class="text-muted">
        A <i>Genuine</i> {{ resumeExample?.Role }} Resume Example That Got A Candidate Hired
      </h5>
    </div>
    <div class="callout bg-primary-subtle d-flex align-items-center m-1 text-black-30">
      <i class="bi bi-info-square-fill fs-3 me-3"></i>
      <!-- Bootstrap 5 classes for size and margin -->
      <p class="fw-light">
        This resume helped a real JobBuddy user secure their
        {{ resumeExample?.Role.toLowerCase() }} position. Shared with permission to assist you in
        your job search, names, dates and places have been changed to protect their privacy.
      </p>
    </div>

    <div class="p-body row w-100">
      <div class="col-md-6 col-12">
        <img
          :src="getImageUrl(resumeExample?.ExampleImgSrc)"
          :alt="`Cover letter example for ${resumeExample?.Role}`"
          class="img-fluid d-block my-3 w-100 shadow"
          loading="lazy"
        />
      </div>

      <div class="col-md-6 col-12 m-auto border-start">
        <div class="">
          <h3 class="h4 mb-2">
            <b><i>Why This Resume Works</i></b>
          </h3>
          <p>{{ resumeExample?.WhyItWorked }}.</p>
        </div>
        <div class="mb-4">
          <h3 class="h4 mb-2">
            <b
              ><i>Our Top Tips For A <u>High-Impact</u> Resume</i></b
            >
          </h3>

          <h3 class="h5 mb-2 ppt-2">🎯 Tailor Your Resume to the Job</h3>
          <p>
            Customize your resume for each application. Highlight experiences and skills that align
            closely with the job description.
          </p>

          <h3 class="h5 mb-2">👁️ Use a Clear and Professional Format</h3>
          <p>
            Keep your resume clean and easy to read. Use a professional font, consistent formatting,
            and clear section headings.
          </p>

          <h3 class="h5 mb-2">📊 Quantify Achievements</h3>
          <p>
            Where possible, add numbers to your achievements to show impact, such as "increased
            sales by 20%" or "reduced processing time by 30%".
          </p>

          <h3 class="h5 mb-2">🔑 Include Keywords From the Job Description</h3>
          <p>
            Integrate keywords from the job description into your resume. This helps pass applicant
            tracking systems (ATS) and shows you're a fit.
          </p>
          <div>
            <p>
              <i>
                Check out our list of the best ATS keywords for a
                {{ resumeExample?.Role.toLocaleLowerCase() }} resume below.
              </i>
            </p>
          </div>
          <h3 class="h5 mb-2">🛠 Highlight Relevant Skills</h3>
          <p>
            Focus on the skills most relevant to the job. Include both hard skills (like software
            proficiency) and soft skills (like communication).
          </p>

          <h3 class="h5 mb-2">📝 Keep It Concise</h3>
          <p>
            Limit your resume to 1-2 pages. Be concise and only include information that adds value
            to your application.
          </p>
        </div>
      </div>
    </div>
    <div class="p-3 rounded bg-success-subtle align-items-center m-1 text-black-30">
      <div class="d-flex align-items-center justify-content-center">
        <h5>
          Top ATS Keywords for a {{ resumeExample?.Role }} Resume<i
            class="bi bi-search fs-3 mx-3"
          ></i>
        </h5>
      </div>
      <p class="fw-light p-3">
        Here's some of the keywords that recruiters and hiring managers look for when hiring for a
        {{ resumeExample?.Role.toLocaleLowerCase() }} role. Including these keywords in your resume
        can help you pass applicant tracking systems (ATS) and show you're a good fit for the role.
      </p>
      <div class="d-flex justify-content-center row">
        <div
          v-for="keyword in resumeExample?.ATSKeywords"
          :key="keyword"
          class="border border-success rounded m-2 col-5 col-sm-2"
        >
          <span class="text-success-emphasis">{{ keyword }}</span>
        </div>
      </div>
    </div>
    <div class="m-1 rounded bg-body-tertiary">
      <div class="m-3 text-body p-3">
        <h3 class="h5 mb-2 text-body">
          <b
            >Create Your Resume in <u><i>Seconds</i></u> and <u><i>for Free</i></u> with Job<span
              class="text-primary"
              >Buddy</span
            ></b
          >
          💡
        </h3>
        <p>
          This resume was built with JobBuddy's AI resume generator. Our resume AI will analyze your
          target job for keywords, identify relevant experience from your existing resume and
          reformat it all for you into a beautiful new, tailored resume which you can download as
          Word or PDF. Join thousands of happy users and create your resume for free in seconds.
        </p>

        <div class="row mt-5 w-100">
          <router-link :to="{ name: 'Dashboard' }">
            <button class="btn btn-primary btn-lg w-100">Create Resume</button>
          </router-link>
        </div>
      </div>
    </div>

    <div v-if="getImageUrlForCoverLetter(resumeExample?.ExampleImgSrc)">
      <div class="row m-1 text-body p-3 bg-body-tertiary rounded">
        <!-- Text column -->
        <div class="col-12 col-sm-6 order-1 order-sm-1">
          <h3 class="h5 mb-2 mt-5 text-body">
            <b>Discover the {{ resumeExample?.Role }} Cover Letter That Got Someone Hired</b>
          </h3>
          <span>
            Check out a <i>genuine</i> {{ resumeExample?.Role.toLocaleLowerCase() }} cover letter
            that successfully secured a job offer.
          </span>
          <br />
          <br />

          <i>Optimized for Applicant Tracking Systems</i>
          <br />

          <i>Professionally Crafted Design</i>
          <br />

          <i>Demonstrated Effectiveness</i>
        </div>

        <!-- Image column -->
        <div
          class="col-12 col-sm-6 order-2 order-sm-2 d-flex justify-content-center align-items-center p-2"
        >
          <img
            loading="lazy"
            class="m-auto"
            style="max-height: 350px; max-width: 100%"
            :src="getImageUrlForCoverLetter(resumeExample.ExampleImgSrc)"
          />
        </div>

        <!-- Button column -->
        <div
          class="col-12 col-sm-6 order-3 order-sm-3 d-flex justify-content-center align-items-center"
        >
          <router-link :to="{ name: 'CoverLetterDetail', params: route.params.role }">
            <button class="btn btn-outline-primary btn-lg w-100">View This Cover Letter</button>
          </router-link>
        </div>
      </div>
    </div>
    <div>
      <h3 class="h5 mb-2 mt-5">More Resume Examples</h3>
      <p>
        Browse more cover letter examples for different roles and industries. Each cover letter
        example is based on a real cover letter that got a candidate hired.
      </p>
      <div class="row">
        <div class="d-flex">
          <div class="overflow-x-auto w-100 d-flex scroll-container">
            <router-link
              v-for="example in resumeExamples"
              :key="example.Role"
              :to="{
                name: 'ResumeDetail',
                params: { role: example.Role.toLowerCase().replace(/ /g, '-') },
              }"
              style="text-decoration: none"
              class="col"
            >
              <div class="card border-0 m-2" style="width: 18rem">
                <img
                  loading="lazy"
                  :src="getImageUrl(example.ExampleImgSrc)"
                  :alt="`Cover letter example
                for ${example.Role}`"
                  class="shadow m-1 example-card"
                />
                <div class="card-body">
                  <h5 class="card-title text-secondary">{{ example.Role }}</h5>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <div>
        <h3 class="h5 mb-2 mt-5"><b>See All Resumes</b></h3>
        <p>
          Browse our full resume example collection, with a variety of resumes for different roles
          and industries.
        </p>
        <div>
          <router-link :to="{ name: 'ResumeExamples' }">
            <button class="btn btn-outline-primary btn-lg w-100">View All Resumes</button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { getCurrentInstance, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { ResumeExample, resumeExamples } from "./blogs/ResumeExamples";
import { useHead } from "@unhead/vue";

const route = useRoute();
const resumeExample = ref<ResumeExample | undefined>();
// Fetch initial cover letter example based on the initial route.
onMounted(() => {
  loadresumeExample();
});

// Watch for route changes to update the cover letter example.
watch(
  () => route.params.role,
  (newRole) => {
    loadresumeExample();
  }
);

watch(
  () => resumeExample.value, // watching the resumeExample object for changes
  (newVal, oldVal) => {
    if (newVal) {
      // Ensuring that newVal is defined before updating head
      useHead({
        title: `Example of a Successful ${newVal.Role} Resume`, // Dynamic title based on the role
        meta: [
          {
            name: "description",
            content: `See the example ${newVal.Role.toLowerCase()} resume that got somebody hired in their ${newVal.Role.toLowerCase()} role. Explore our collection of successful resumes made with JobBuddy.`, // Dynamic description based on the role
          },
          {
            name: "keywords",
            content: `${
              newVal.Role
            } resume, successful ${newVal.Role.toLowerCase()} resume, JobBuddy resume builder, resume templates, industry-specific resumes, AI resume optimization`, // Dynamic keywords based on the role
          },
        ],
      });
    }
  },
  { immediate: true } // Ensures this watch effect runs immediately on component mount
);

function getImageUrlForCoverLetter(path: any): string {
  if (!path) {
    console.error("Invalid path provided");
    return ""; // Return a default image path or an error image
  }
  return require(`../../public/media/coverLetterExamples/${path}`);
}

function loadresumeExample() {
  const role = route.params.role?.toLowerCase();
  resumeExample.value = resumeExamples.find((example) => example.key.toLowerCase() === role);
}

function getImageUrl(path: any): string {
  if (!path) {
    console.error("Invalid path provided");
    return ""; // Return a default image path or an error image
  }
  return require(`../../public/media/resumeExamples/${path}`);
}
</script>

<style scoped>
.container {
  padding: 1em;
  font-family: Arial, sans-serif;
}

.text-center {
  text-align: center;
  margin-bottom: 2em;
}

.fw-bold {
  font-weight: bold;
}

.mb-2,
.mb-3,
.mb-4 {
  margin-bottom: 0.5rem;
}

.my-3,
.my-5 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.p-body {
  text-align: left;
  margin-top: 15px;
}

.h4 {
  font-size: 1.25rem;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
  color: white;
}

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.callout {
  padding: 20px;
  margin: 20px 0;
  border: 1px solid #007bff;
  border-left-width: 5px;
  border-radius: 3px;
}
.overflow-x-auto.position-relative {
  position: relative;
}

.scroll-container {
  overflow-x: scroll;
}

.example-card:hover {
  transform: scale(1.05);
  transition: transform 0.3s;
  border: 3px solid rgba(0, 0, 255, 0.304); /* Change 'blue' to any desired highlight color */
  border-radius: 3px;
  box-shadow: 0 0 15px rgba(0, 0, 255, 0.5); /* Additional shadow for selected image */
}
</style>

function getImageUrl(path: any) { throw new Error("Function not implemented."); }
