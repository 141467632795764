import { createApp } from "vue";
import { auth0 } from "./auth/auth0";
import App from "./App.vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import router from "./router/index";
import store from "./store";
import Particles from "particles.vue3";
import Vue3Toastify, { ToastContainerOptions } from "vue3-toastify";
import { vTooltip } from "floating-vue";
import "floating-vue/dist/style.css";
import { VueHeadMixin, createHead } from "@unhead/vue";

const app = createApp(App);
app.use(Vue3Toastify, {
  autoClose: 1500,
} as ToastContainerOptions);
app.use(store);
app.directive("tooltip", vTooltip);
app.use(router);
app.use(Particles);
app.use(auth0);
const head = createHead();
app.use(head);
app.mixin(VueHeadMixin);
app.mount("#app");
