import type AIProfile from '../AIProfile'
import type CoverLetterSettings from '../CoverLetterSettings'
import { type ResponseType } from '../Response'
export class ResponseDto {
  id: string | null
  content: string
  applicationId: string
  userId: string
  isFavourite: boolean
  type: ResponseType
  documentId: string | null
  temperature: number
  frequencyPenalty: number
  template: TemplateOptions | null
  fileId: string | null
  schema: Record<string, unknown> | null

  constructor (id: string | null, content: string, applicationId: string, userId: string, isFavourite: boolean, type: ResponseType, documentId: string | null, temperature: number, frequencyPenalty: number, template: TemplateOptions | null, fileId: string, schema: Record<string, unknown> | null) {
    this.id = id
    this.content = content
    this.applicationId = applicationId
    this.userId = userId
    this.isFavourite = isFavourite
    this.type = type
    this.documentId = documentId
    this.temperature = temperature
    this.frequencyPenalty = frequencyPenalty
    this.template = template
    this.fileId = fileId
    this.schema = schema
  }
}

export enum TemplateOptions {
  RESUME_CLASSIC,
  RESUME_GRADUATE,
  RESUME_MODERN,
  COVER_LETTER_CLASSIC,
  COVER_LETTER_MODERN,
  COVER_LETTER_GRADUATE,
  INTERVIEW_PREP,
  RESUME_FEEDBACK
}

export class GenerateNewResponse {
  applicationId: string
  documentId: string
  type: ResponseType
  temperature: number
  frequencyPenalty: number
  template: TemplateOptions | null
  coverLetterSettings?: CoverLetterSettings
  aiProfile?: AIProfile

  constructor (applicationId: string, documentId: string, type: ResponseType, temperature: number, frequencyPenalty: number, template: TemplateOptions | null, coverLetterSettings?: CoverLetterSettings, aiProfile?: AIProfile) {
    this.applicationId = applicationId
    this.documentId = documentId
    this.type = type
    this.temperature = temperature
    this.frequencyPenalty = frequencyPenalty
    this.template = template
    this.coverLetterSettings = coverLetterSettings
    this.aiProfile = aiProfile
  }
}
