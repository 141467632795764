export type CoverLetterExample = {
  Role: string;
  ExampleImgSrc: string;
  key: string; // Added key property
  WhyItWorked: string;
};

// Function to convert a string to snake case
function toSnakeCase(str: string): string {
  return str.toLowerCase().replace(/ /g, "-");
}

// Array of cover letter examples with added `key` property
export const coverLetterExamples: CoverLetterExample[] = [
  {
    Role: "Administrative Assistant",
    ExampleImgSrc: "AdminAssistant.webp",
    key: toSnakeCase("Administrative Assistant"),
    WhyItWorked:
      "This cover letter draws heavily from the details in the job description, which makes it clear that the candidate understands the role and the company’s needs. The use of stats and numbers help prove the candidates comptency and the cover letter is well-organized and easy to read",
  },
  {
    Role: "Bartender",
    ExampleImgSrc: "Bartender.webp",
    key: toSnakeCase("Bartender"),
    WhyItWorked:
      "This cover letter example worked because it relates the candidate's experience to the job requirements and the candidates personality to the comany's mission",
  },
  {
    Role: "Customer Service Representative",
    ExampleImgSrc: "CustomerServiceRepresentative.webp",
    key: toSnakeCase("Customer Service Representative"),
    WhyItWorked:
      "This cover letter example is well structured and addresses the job requirements and the company's mission clearly, backing up the candidate's claims with examples",
  },
  {
    Role: "Data Analyst",
    ExampleImgSrc: "DataAnalyst.webp",
    key: toSnakeCase("Data Analyst"),
    WhyItWorked:
      "In this cover letter, the candidate uses data to back up their claims, and relates their experience to the job requirements",
  },
  {
    Role: "Executive Assistant",
    ExampleImgSrc: "ExecutiveAssistant.webp",
    key: toSnakeCase("Executive Assistant"),
    WhyItWorked:
      "This cover letter example is well structured and addresses the job requirements and the company's mission clearly, backing up the candidate's claims with examples",
  },
  {
    Role: "HR",
    ExampleImgSrc: "HR.webp",
    key: toSnakeCase("HR"),
    WhyItWorked:
      "This cover letter example is well structured and addresses the job requirements and the company's mission clearly, backing up the candidate's claims with examples",
  },
  {
    Role: "High School Teacher",
    ExampleImgSrc: "HighSchoolTeacher.webp",
    key: toSnakeCase("High School Teacher"),
    WhyItWorked:
      "This cover letter example is well structured and addresses the job requirements and the company's mission clearly, backing up the candidate's claims with examples",
  },
  {
    Role: "Lab Technician",
    ExampleImgSrc: "LabTechnician.webp",
    key: toSnakeCase("Lab Technician"),
    WhyItWorked:
      "This cover letter example is well structured and addresses the job requirements and the company's mission clearly, backing up the candidate's claims with examples",
  },
  {
    Role: "Marketing",
    ExampleImgSrc: "Marketing.webp",
    key: toSnakeCase("Marketing"),
    WhyItWorked:
      "This cover letter example is well structured and addresses the job requirements and the company's mission clearly, backing up the candidate's claims with examples",
  },
  {
    Role: "Nurse",
    ExampleImgSrc: "Nurse.webp",
    key: toSnakeCase("Nurse"),
    WhyItWorked:
      "This cover letter example is well structured and addresses the job requirements and the company's mission clearly, backing up the candidate's claims with examples",
  },
  {
    Role: "Police Officer",
    ExampleImgSrc: "PoliceOfficer.webp",
    key: toSnakeCase("Police Officer"),
    WhyItWorked:
      "This cover letter example is well structured and addresses the job requirements and the company's mission clearly, backing up the candidate's claims with examples",
  },
  {
    Role: "Project Manager",
    ExampleImgSrc: "ProjectManager.webp",
    key: toSnakeCase("Project Manager"),
    WhyItWorked:
      "This cover letter example is well structured and addresses the job requirements and the company's mission clearly, backing up the candidate's claims with examples",
  },
  {
    Role: "Research Assistant",
    ExampleImgSrc: "ResearchAssistant.webp",
    key: toSnakeCase("Research Assistant"),
    WhyItWorked:
      "This cover letter example is well structured and addresses the job requirements and the company's mission clearly, backing up the candidate's claims with examples",
  },
  {
    Role: "Retail",
    ExampleImgSrc: "Retail.webp",
    key: toSnakeCase("Retail"),
    WhyItWorked:
      "This cover letter example is well structured and addresses the job requirements and the company's mission clearly, backing up the candidate's claims with examples",
  },
  {
    Role: "Student",
    ExampleImgSrc: "Student.webp",
    key: toSnakeCase("Student"),
    WhyItWorked:
      "This cover letter example is well structured and addresses the job requirements and the company's mission clearly, backing up the candidate's claims with examples",
  },
];

// You can now use these `key` properties for URL slugs or other identifiers.
