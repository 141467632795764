<template>
  <div>
    <Modal title="There's been an error!" ref="errorModal">
      <template #body>There was an error processing this request. Please try again later!</template>
    </Modal>
    <Modal title="This license appears to be incorrect" ref="licenseErrorModal">
      <template #body
        >Check your license is typed in exactly as you received it in your invite email. It should
        have the exact format <b>XXXX-XXXX-XXXX-XXXX</b> with no trailing spaces, additional
        characters etc. This license is case sensitive. It can also only be used once. If you're
        still having problems, please email us at hello@jobbuddytech.com</template
      >
    </Modal>
    <h1 class="mt-5">Just a few more details...</h1>
    <div class="container">
      <div class="row m-3">
        <label class="form-label">Full Name</label>
        <input v-model="createUser.name" type="text" class="form-control" placeholder="John Doe" />
      </div>
      <div class="row m-3">
        <label class="form-label">Email</label>
        <input
          v-model="createUser.email"
          type="email"
          class="form-control"
          placeholder="name@example.com"
        />
      </div>
      <div class="row m-3">
        <label class="form-label">Current Occupation</label>
        <input v-model="createUser.occupation" type="text" class="form-control" />
      </div>
      <div class="row m-3">
        <label class="form-label">Country</label>
        <select
          v-model="createUser.country"
          class="form-control select-dropdown"
          style="max-height: 100px"
          size="1"
        >
          <option value="" disabled>Select your country</option>
          <option v-for="country in countries" :key="country">{{ country }}</option>
        </select>
      </div>
      <div class="row m-3">
        <label class="form-label">Gender</label>
        <select v-model="createUser.gender" class="form-control">
          <option>Male</option>
          <option>Female</option>
          <option>Other</option>
        </select>
      </div>
      <div class="row m-3">
        <label class="form-label">Employment Status</label>
        <select v-model="createUser.employmentStatus" class="form-control">
          <option>Unemployed - Casually browsing</option>
          <option>Unemployed - Long term unemployed</option>

          <option>Employed - Looking at new companies</option>
          <option>Employed - Looking to change career/role</option>
          <option>Graduate</option>
        </select>
      </div>
      <div class="row m-3">
        <label class="form-label">Where Did You Hear About JobBuddy?</label>
        <select v-model="createUser.whereDidYouHearAboutUs" class="form-control">
          <option>Google</option>
          <option>Reddit</option>
          <option>TikTok</option>
          <option>Twitter</option>
          <option>A Friend</option>
          <option>Other</option>
        </select>
      </div>
      <!-- <div class="row m-3">
      <label class="form-label">License Key</label>
      <input v-model="license" type="text" class="form-control" placeholder="XXXX-XXXX-XXXX-XXXX" />
    </div> -->
      <div style="height: 250px" class="card border border-primary rounded m-3 mt-5">
        <div class="m-3 overflow-auto text-center">
          <h2>Terms and Conditions</h2>

          <h3>About your data...</h3>

          <p>
            JobBuddy may store some of your data in order to improve your experience using the
            service such as saving your data for use between sessions or in order to provide a
            better service. We won't share your data with anybody, except where you have consented
            for us to do so, or doing so is required in order to provide our services. By creating
            an account with JobBuddy, you agree that your data will be shared with OpenAI, the
            proprietors of the machine learning models JobBuddy uses in order to provide its
            services. You are also therefore agreeing to abide by the OpenAI terms, which can be
            found here: https://openai.com/terms. You may be provided the option to provide
            additional explicit consent to allow us to process your data to improve JobBuddy's own
            machine learning capabilities by allowing your data to be used in training a model. This
            is optional and not required in order to use the service.
          </p>

          <h3>1. Terms</h3>

          <p>
            By accessing this web site, you are agreeing to be bound by these web site Terms and
            Conditions of Use, all applicable laws and regulations, and agree that you are
            responsible for compliance with any applicable local laws. If you do not agree with any
            of these terms, you are prohibited from using or accessing this site. The materials
            contained in this web site are protected by applicable copyright and trade mark law.
          </p>

          <h3>2. Use License</h3>

          <ol type="a">
            <li>
              Permission is granted to temporarily download one copy of the materials (information
              or software) on JobBuddy's web site for personal, non-commercial transitory viewing
              only. This is the grant of a license, not a transfer of title, and under this license
              you may not:

              <ol type="i">
                <li>modify or copy the materials;</li>
                <li>
                  use the materials for any commercial purpose, or for any public display
                  (commercial or non-commercial);
                </li>
                <li>
                  attempt to decompile or reverse engineer any software contained on JobBuddy's web
                  site;
                </li>
                <li>remove any copyright or other proprietary notations from the materials; or</li>
                <li>
                  transfer the materials to another person or "mirror" the materials on any other
                  server.
                </li>
              </ol>
            </li>
            <li>
              This license shall automatically terminate if you violate any of these restrictions
              and may be terminated by JobBuddy at any time. Upon terminating your viewing of these
              materials or upon the termination of this license, you must destroy any downloaded
              materials in your possession whether in electronic or printed format.
            </li>
          </ol>

          <h3>3. Disclaimer</h3>

          <ol type="a">
            <li>
              The materials on JobBuddy's web site are provided "as is". JobBuddy makes no
              warranties, expressed or implied, and hereby disclaims and negates all other
              warranties, including without limitation, implied warranties or conditions of
              merchantability, fitness for a particular purpose, or non-infringement of intellectual
              property or other violation of rights. Further, JobBuddy does not warrant or make any
              representations concerning the accuracy, likely results, or reliability of the use of
              the materials on its Internet web site or otherwise relating to such materials or on
              any sites linked to this site.
            </li>
          </ol>

          <h3>4. Limitations</h3>

          <p>
            In no event shall JobBuddy or its suppliers be liable for any damages (including,
            without limitation, damages for loss of data or profit, or due to business
            interruption,) arising out of the use or inability to use the materials on JobBuddy's
            Internet site, even if JobBuddy or a JobBuddy authorized representative has been
            notified orally or in writing of the possibility of such damage. Because some
            jurisdictions do not allow limitations on implied warranties, or limitations of
            liability for consequential or incidental damages, these limitations may not apply to
            you.
          </p>

          <h3>5. Revisions and Errata</h3>

          <p>
            The materials appearing on JobBuddy's web site could include technical, typographical,
            or photographic errors. JobBuddy does not warrant that any of the materials on its web
            site are accurate, complete, or current. JobBuddy may make changes to the materials
            contained on its web site at any time without notice. JobBuddy does not, however, make
            any commitment to update the materials.
          </p>

          <h3>6. Links</h3>

          <p>
            JobBuddy has not reviewed all of the sites linked to its Internet web site and is not
            responsible for the contents of any such linked site. The inclusion of any link does not
            imply endorsement by JobBuddy of the site. Use of any such linked web site is at the
            user's own risk.
          </p>

          <h3>7. Site Terms of Use Modifications</h3>

          <p>
            JobBuddy may revise these terms of use for its web site at any time without notice. By
            using this web site you are agreeing to be bound by the then current version of these
            Terms and Conditions of Use.
          </p>

          <h3>8. Governing Law</h3>

          <p>
            Any claim relating to JobBuddy's web site shall be governed by the laws of the the
            United Kingdom without regard to its conflict of law provisions.
          </p>

          <p>General Terms and Conditions applicable to Use of a Web Site.</p>

          <h2>Privacy Policy</h2>

          <p>
            Your privacy is very important to us. Accordingly, we have developed this Policy in
            order for you to understand how we collect, use, communicate and disclose and make use
            of personal information. The following outlines our privacy policy.
          </p>

          <ul>
            <li>
              Before or at the time of collecting personal information, we will identify the
              purposes for which information is being collected.
            </li>
            <li>
              We will collect and use of personal information solely with the objective of
              fulfilling those purposes specified by us and for other compatible purposes, unless we
              obtain the consent of the individual concerned or as required by law.
            </li>
            <li>
              We will only retain personal information as long as necessary for the fulfillment of
              those purposes.
            </li>
            <li>
              We will collect personal information by lawful and fair means and, where appropriate,
              with the knowledge or consent of the individual concerned.
            </li>
            <li>
              Personal data should be relevant to the purposes for which it is to be used, and, to
              the extent necessary for those purposes, should be accurate, complete, and up-to-date.
            </li>
            <li>
              We will protect personal information by reasonable security safeguards against loss or
              theft, as well as unauthorized access, disclosure, copying, use or modification.
            </li>
            <li>
              We will make readily available to customers information about our policies and
              practices relating to the management of personal information.
            </li>
          </ul>

          <p>
            We are committed to conducting our business in accordance with these principles in order
            to ensure that the confidentiality of personal information is protected and maintained.
          </p>
        </div>
        <div class="d-flex card-footer text-muted">
          <div class="form-check">
            <label class="form-check-label"> I agree to the terms and conditions </label>
            <input v-model="createUser.hasAgreedToTerms" class="form-check-input" type="checkbox" />
          </div>
        </div>
      </div>
      <div class="d-grid">
        <button
          :disabled="!canCompleteSignup()"
          class="m-3 mb-5 btn btn-primary btn-lg"
          @click="createNewUser"
        >
          <span v-show="!loading">Complete Signup</span>
          <div v-show="loading" class="spinner-border spinner-border-sm" role="status" />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Modal from "@/components/Modal.vue";
import { onMounted, ref } from "vue";
import router from "@/router";
import store from "@/store";
import { useAuth0 } from "@auth0/auth0-vue";
import { countries } from "../components/CountriesList";
const { user } = useAuth0();

const errorModal = ref(null);
const licenseErrorModal = ref(null);

const loading = ref(false);
const license = ref("");
const createUser = ref({
  name: null,
  email: null,
  occupation: null,
  gender: null,
  employmentStatus: null,
  hasAgreedToTerms: false,
  country: null,
  whereDidYouHearAboutUs: null,
});

onMounted(() => {
  createUser.value.email = user.value?.email?.length ? user.value.email : null;
  createUser.value.gender = user.value?.gender ? user.value.gender : null;
  createUser.value.name = user.value?.name ? user.value.name : null;
});

async function createNewUser() {
  await store.dispatch("user/createUser", createUser.value);
  router.push("/subscribe");
}

function canCompleteSignup() {
  return (
    createUser.value.hasAgreedToTerms &&
    createUser.value.email != null &&
    createUser.value.gender != null &&
    createUser.value.employmentStatus != null &&
    createUser.value.name != null &&
    createUser.value.occupation != null &&
    createUser.value.country != null &&
    createUser.value.whereDidYouHearAboutUs
  );
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #0275d8;
}
.select-dropdown {
  max-height: 200px;
  overflow-y: auto;
}
</style>
