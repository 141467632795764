import { GenerateNewResponse, ResponseDto } from "@models/dtos/ResponseDtos";
import { ResponseFeedback } from "@models/ResponseFeedback";
import { AxiosResponse } from "axios";
import { instance } from "./apiProvider/ApiProvider";

export class ResponseApiService {
  public static async getApplicationResponses(
    applicationId: string
  ): Promise<AxiosResponse<ResponseDto[]>> {
    const axios = await instance();
    const response = await axios.get("/getApplicationResponses", {
      params: {
        id: applicationId,
      },
    });
    if (response.status == 200) {
      return response;
    } else {
      console.error(response);
      return response;
    }
  }
  public static async getResponseById(responseId: string): Promise<AxiosResponse<ResponseDto>> {
    const axios = await instance();
    const response = await axios.get("/getResponseById", {
      params: {
        id: responseId,
      },
    });
    if (response.status == 200) {
      return response;
    } else {
      console.error(response);
      return response;
    }
  }

  public static async generateNewResponse(
    responseOptions: GenerateNewResponse
  ): Promise<AxiosResponse> {
    const axios = await instance();
    const response = await axios.post("/generateNewResponse", responseOptions);
    if (response.status == 200) {
      return response;
    } else {
      console.error(response);
      return response;
    }
  }

  public static async editResponseContent(
    response: ResponseDto
  ): Promise<AxiosResponse<ResponseDto>> {
    const axios = await instance();
    const res = await axios.post("/editResponseContent", response);
    if (res.status == 200) {
      return res;
    } else {
      console.error(res);
      return res;
    }
  }

  public static async generateNewResume(
    responseOptions: GenerateNewResponse
  ): Promise<AxiosResponse> {
    const axios = await instance();
    const response = await axios.post("/generateNewResume", responseOptions);
    if (response.status == 200) {
      return response;
    } else {
      console.error(response);
      return response;
    }
  }

  public static async toggleFavoriteResponse(responseId: string): Promise<AxiosResponse> {
    const axios = await instance();
    const response = await axios.put("/toggleFavoriteResponse", null, {
      params: {
        id: responseId,
      },
    });
    if (response.status == 200) {
      return response;
    } else {
      console.error(response);
      return response;
    }
  }
  public static async delete(responseId: string): Promise<AxiosResponse> {
    const axios = await instance();
    const response = await axios.delete("/deleteResponse", {
      params: {
        id: responseId,
      },
    });
    if (response.status == 200) {
      return response;
    } else {
      console.error(response);
      return response;
    }
  }

  public static async postResponseFeedback(
    responseFeedback: ResponseFeedback
  ): Promise<AxiosResponse> {
    const axios = await instance();
    const response = await axios.post("/postResponseFeedback", responseFeedback);
    if (response.status == 200) {
      return response;
    } else {
      console.error(response);
      return response;
    }
  }
}
