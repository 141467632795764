import CreateOrUpdateApplication from "@models/dtos/CreateOrUpdateApplication";
import { AxiosResponse } from "axios";
import { instance } from "./apiProvider/ApiProvider";

export class WebscrapeApiService {
  public static async getApplicationDetailsFromUrl(url: string): Promise<
    AxiosResponse<
      | {
          roleTitle: string;
          companyName: string;
          jobDescription: string;
          companyDescription: string;
        }
      | undefined
    >
  > {
    const axios = await instance();
    const response = await axios.get("/getApplicationDetailsFromUrl", {
      params: {
        url: url,
      },
    });
    if (response.status == 200) {
      return response;
    } else {
      console.error(response);
      return response;
    }
  }
}
