import { AxiosResponse } from "axios";
import { instance } from "./apiProvider/ApiProvider";
import { ResponseDto } from "@models/dtos/ResponseDtos";

export class FileApiService {
  public static async getFileById(id: string, pdf: boolean): Promise<AxiosResponse<any>> {
    const axios = await instance();
    const response = await axios.get<any>("/getFileById", {
      params: { id: id, pdf: pdf },
      responseType: "blob",
    }); // use params to pass id

    if (response.status === 200) {
      return response;
    } else {
      console.error(response);
      throw new Error(`Request failed with status ${response.status}`);
    }
  }

  public static async getPDFPreview(res: ResponseDto): Promise<AxiosResponse<any>> {
    const axios = await instance();
    const response = await axios.post<any>("/pdfPreview", res, {
      responseType: "blob",
    });

    if (response.status === 200) {
      return response;
    } else {
      console.error(response);
      throw new Error(`Request failed with status ${response.status}`);
    }
  }
}
