import { RedirectLoginOptions } from "@auth0/auth0-spa-js";
import { AppState, createAuth0 } from "@auth0/auth0-vue";

export const auth0 = createAuth0({
  domain: "jobbuddy.uk.auth0.com",
  client_id: process.env.VUE_APP_AUTH_0_CLIENT_ID!,
  redirect_uri: window.location.origin,
  audience: "https://jobbuddy.onrender.com/api",
});
export async function getAccessTokenSilently() {
  return auth0.getAccessTokenSilently();
}

export function isAuthenticated() {
  return auth0.isAuthenticated;
}

export async function loginWithRedirect(options?: RedirectLoginOptions<AppState> | undefined) {
  await auth0.loginWithRedirect(options);
}
