import { getAccessTokenSilently } from "@/auth/auth0";
import axios, { AxiosInstance } from "axios";
export const instance = async (): Promise<AxiosInstance> => {
  const token = await getAccessTokenSilently();
  return axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    headers: {
      Authorization: `Bearer ${token ?? {}}`,
    },
  });
};
