<template>
  <div class="container">
    <div class="header">
      <h2 class="about-title">
        Unlock Your Career Potential with
        <span>Job<span class="text-primary">Buddy</span></span>
      </h2>
      <img class="mascot" src="../../public/media/jobbuddymascot.webp" alt="JobBuddy Mascot" />
    </div>
    <div class="info-section">
      <h3>Your Personalized Career Coach</h3>
      <p>
        With generic cover letter templates and resume builders, you're missing out on opportunities
        to stand out. That’s where <span class="highlight">JobBuddy</span> comes in. We offer you a
        comprehensive, AI-driven career assistant. Leveraging cutting-edge technologies like ChatGPT
        4.0, 3.5, and custom Davinci models, we bring you top-of-the-line resume and cover letter
        examples.
      </p>
      <br />
      <div class="features">
        <div class="feature">
          <i class="bi bi-pencil-square feature-icon"></i>
          <h4>Custom Cover Letters</h4>
          <p>
            Select the cover letter format that best fits you. Whether you're looking for a short
            cover letter sample or a detailed cover letter template, we have you covered.
          </p>
        </div>
        <div class="feature">
          <i class="bi bi-question-circle feature-icon"></i>
          <h4>Interview Prep</h4>
          <p>
            With JobBuddy’s bespoke interview question sets, you get tailored guidance on how to
            answer questions effectively, all built from your resume and the job description.
          </p>
        </div>
        <div class="feature">
          <i class="bi bi-search feature-icon"></i>
          <h4>Grammar Perfection</h4>
          <p>
            Say goodbye to grammatical mistakes in your covering letter for resume and other
            documents.
          </p>
        </div>
        <div class="feature">
          <i class="bi bi-arrow-clockwise feature-icon"></i>
          <h4>ATS-Optimized Resumes</h4>
          <p>
            Not just visually appealing, but your resume is also optimized for Applicant Tracking
            Systems (ATS). We ensure your resume cover letter examples pass through any system
            seamlessly.
          </p>
        </div>
      </div>
      <br />
      <br />
      <h3>What Sets JobBuddy Apart?</h3>
      <p>
        Unlike standard ChatGPT and other platforms, <span class="highlight">JobBuddy</span> has a
        specialized focus on helping you land your dream job. Our approach is data-driven and
        precise, featuring research-backed cover letter templates, resume AI, and job-specific
        customization. Each covering letter for job application and resume isn't merely text—it's a
        meticulously crafted document designed to resonate with your future employer.
      </p>
    </div>
    <br />
    <div class="cta-section">
      <h3>Start Your Job Hunt Right</h3>
      <p>
        Elevate your job search with <span class="highlight">JobBuddy</span>. With various tiers
        from free to professional, we offer solutions for every career stage. Our goal is to make
        the job application process less tedious, leaving you empowered and focused.
      </p>
      <button @click="login()" class="btn btn-primary btn-lg me-3">Try for Free!</button>
    </div>
  </div>
</template>

<script>
import { useAuth0 } from "@auth0/auth0-vue";
import { useHead } from "@unhead/vue";

export default {
  name: "AboutJb",
  setup() {
    const { loginWithRedirect } = useAuth0();

    const login = async () => {
      await loginWithRedirect({
        appState: { target: "/dashboard" },
      });
    };

    useHead({
      title: "About JobBuddy - Revolutionizing Job Applications with AI",
      meta: [
        {
          name: "description",
          content:
            "Discover JobBuddy, your ultimate AI career assistant that empowers job seekers with advanced tools for a seamless job application process. Learn about our vision, the technology behind our platform, and how we're helping thousands of users secure their dream jobs with smart, data-driven application tools.",
        },
        {
          name: "keywords",
          content:
            "About JobBuddy, AI-powered career tools, JobBuddy mission, advanced job application technology, JobBuddy AI",
        },
      ],
    });
    return {
      login,
    };
  },
};
</script>

<style scoped>
.container {
  padding: 3em;
  font-family: "Roboto", sans-serif;
}

.header {
  text-align: center;
  margin-bottom: 2em;
}

.about-title {
  font-size: 3em;
  font-weight: bold;
  margin-bottom: 1em;
}

.highlight {
  color: #8f8ff4;
}

.mascot {
  width: 120px;
  filter: drop-shadow(0 0 0.75rem #9c8ff4);
}

.quote-section {
  text-align: center;
  font-style: italic;
  font-size: 1.3em;
  margin: 3em 0;
  opacity: 0.9;
}

.quote {
  quotes: "“" "”";
  padding: 0 1em;
}

.quote::before {
  content: open-quote;
}

.quote::after {
  content: close-quote;
}

.info-section h3 {
  font-size: 2em;
  border-bottom: 2px solid #928ff4;
}

.features {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.feature {
  flex: 1;
  text-align: center;
  margin: 1em;
  padding: 1em;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  background-color: rgba(141, 141, 141, 0.154);
}

.feature-icon {
  font-size: 2em;
  color: #978ff4;
}

.feature h4 {
  margin-top: 0.5em;
  font-size: 1.5em;
}
</style>
