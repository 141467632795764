import { AxiosResponse } from "axios";
import { instance } from "./apiProvider/ApiProvider";
import Document from "@models/Document";
import DocumentDto from "@models/dtos/DocumentDto";
export class DocumentApiService {
  public static async getUserDocumentsList(): Promise<AxiosResponse<DocumentDto[]>> {
    const axios = await instance();
    const response = await axios.get("/getUserDocumentsList");
    if (response.status == 200) {
      return response;
    } else {
      console.error(response);
      return response;
    }
  }
  public static async uploadDocument(file: FormData): Promise<AxiosResponse<Document>> {
    const axios = await instance();
    const response = await axios.post("/uploadDocumentText", file);
    if (response.status == 200) {
      return response;
    } else {
      console.error(response);
      return response;
    }
  }
  public static async delete(documentId: string): Promise<AxiosResponse> {
    const axios = await instance();
    const response = await axios.delete("/deleteDocument", { data: { id: documentId } });
    if (response.status == 200) {
      return response;
    } else {
      console.error(response);
      return response;
    }
  }
}
