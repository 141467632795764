<template>
  <div class="container">
    <div class="text-center pt-5">
      <h1 class="display-3 fw-bold">Job<span class="text-primary">Buddy</span></h1>
      <p class="display-4">
        AI-Powered
        <span class="text-primary"><span class="lightning">⚡️</span> Job Applications</span>
      </p>
      <p class="lead">Supercharge Your Job Search with AI</p>
    </div>

    <div class="row text-center">
      <div class="col-md-4 mb-4">
        <div class="card border-0">
          <div class="p-3" style="height: 313px">
            <div class="loading-placeholder w-100 h-100 mx-auto d-block">
              <!-- Placeholder content to maintain the structure -->
              <img
                loading="lazy"
                class="w-100 mx-auto d-block header-image"
                src="../../public/media/EditCv.webp"
                alt="Edit CV"
              />
            </div>
          </div>
          <div class="card-body">
            <h4 class="mt-2">Tailored Cover Letters</h4>
            Crafting job-specific cover letters can be time-consuming. With JobBuddy's AI
            algorithms, generate high-impact cover letters that align your experience with the job
            description in seconds.
          </div>
        </div>
      </div>

      <div class="col-md-4 mb-4">
        <div class="card border-0">
          <div class="p-3" style="height: 313px">
            <img
              loading="lazy"
              class="w-100 mx-auto d-block header-image"
              src="../../public/media/QuestionCv.webp"
              alt="Enhanced Resume"
            />
          </div>
          <div class="card-body">
            <h4 class="mt-2">Optimized Resumes</h4>
            Struggling to optimize your resume? JobBuddy's AI instantly creates targeted resumes by
            smartly matching your skills with job requirements, using relevant keywords to enhance
            visibility.
          </div>
        </div>
      </div>

      <div class="col-md-4 mb-4">
        <div class="card border-0">
          <div class="p-3" style="height: 313px">
            <img
              loading="lazy"
              class="w-100 mx-auto d-block header-image"
              src="../../public/media/WriteCoverLetter.webp"
              alt="Interview Prep"
            />
          </div>
          <div class="card-body">
            <h4 class="mt-2">Ace The Interview</h4>
            Preparing for interviews just got easier with JobBuddy. Access personalized practice
            questions and answers tailored to your experience and the role you're applying for.
          </div>
        </div>
      </div>
    </div>

    <div class="mt-4">
      <button @click="login()" class="btn btn-primary btn-lg me-3">Try for Free!</button>
    </div>
    <div class="pt-5">
      <div
        v-for="checkmark in checkmarks"
        :key="checkmark.text"
        class="d-inline-flex gap-1 lc-block m-3"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="2em"
          height="2em"
          fill="currentColor"
          class="text-primary my-auto flex-shrink-0"
          viewBox="0 0 16 16"
          lc-helper="svg-icon"
        >
          <path
            d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"
          ></path>
          <path
            d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"
          ></path>
        </svg>
        <p editable="inline" class="lead my-auto">{{ checkmark.text }}</p>
      </div>
    </div>
    <div class="container w-100 pt-5">
      <p class="lead py-3"><i>We've helped users secure jobs companies like</i></p>
      <div>
        <!-- Adjusted the row-cols-* class for small screens -->
        <div class="d-flex w-100">
          <div v-for="brand in brands" :key="brand.name" class="w-100">
            <img
              class="img-fluid"
              :src="`/media/${brand.filename}`"
              alt="Brand Logo"
              loading="lazy"
              style="max-width: 45px"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="py-5">
      <p class="display-4"><i>Quality</i> Tools for Job Applicants</p>

      <div class="jobbuddy-example-section text-center my-5 bg-body-tertiary">
        <div class="row align-items-center flex-md-row-reverse">
          <!-- Example Cover Letter Image -->

          <!-- Explanation Text -->
          <div class="col-md-6 explainer-card-text-right">
            <h3 class="">Personalized cover letters</h3>
            <p class="lead mt-5">
              Craft persuasive and tailored cover letters. Stand out from the crowd and showcase
              your qualifications effectively to potential employers.
            </p>

            <!-- Call-to-Action Button -->

            <div class="my-5">
              <div
                v-for="checkmark in coverLetterCheckmarks"
                :key="checkmark.text"
                class="col-md-6 d-inline-flex gap-1 lc-block w-100"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2em"
                  height="2em"
                  fill="currentColor"
                  class="text-primary my-auto flex-shrink-0"
                  viewBox="0 0 16 16"
                  lc-helper="svg-icon"
                >
                  <path
                    d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"
                  ></path>
                  <path
                    d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"
                  ></path>
                </svg>
                <p editable="inline" class="lead my-auto">{{ checkmark.text }}</p>
              </div>
            </div>
            <button @click="login()" class="btn btn-primary btn-lg mt-3 align-bottom">
              Create Your Cover Letter
            </button>
          </div>
          <div class="col-md-6">
            <img
              loading="lazy"
              class="cover-letter-example-img"
              src="../../public/media/CoverLetterExample.webp"
              alt="Cover Letter Example"
            />
          </div>
        </div>
      </div>
      <div class="jobbuddy-example-section text-center my-5 bg-body-tertiary">
        <div class="row align-items-center">
          <!-- Example Cover Letter Image -->

          <!-- Explanation Text -->
          <div class="col-md-6 explainer-card-text-left">
            <h3 class="">Tailored Resumes</h3>
            <p class="lead mt-5">
              Instantly tailor your resume. Extracting skills from job descriptions, predicting
              keywords, and creating a polished, professional resume using proven templates.
            </p>
            <div class="my-5">
              <div
                v-for="checkmark in resumeCheckmarks"
                :key="checkmark.text"
                class="col-md-6 d-inline-flex gap-1 lc-block w-100"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2em"
                  height="2em"
                  fill="currentColor"
                  class="text-primary my-auto flex-shrink-0"
                  viewBox="0 0 16 16"
                  lc-helper="svg-icon"
                >
                  <path
                    d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"
                  ></path>
                  <path
                    d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"
                  ></path>
                </svg>
                <p editable="inline" class="lead my-auto">{{ checkmark.text }}</p>
              </div>
            </div>
            <!-- Call-to-Action Button -->
            <button @click="login()" class="btn btn-primary btn-lg mt-3">Create Your Resume</button>
          </div>
          <div class="col-md-6">
            <img
              loading="lazy"
              class="cover-letter-example-img"
              src="../../public/media/ResumeExample.webp"
              alt="Cover Letter Example"
            />
          </div>
        </div>
      </div>
      <div class="jobbuddy-example-section text-center my-5 bg-body-tertiary">
        <div class="row align-items-center flex-md-row-reverse">
          <!-- Example Cover Letter Image -->

          <!-- Explanation Text -->
          <div class="col-md-6 explainer-card-text-right">
            <h3 class="">Interview Help</h3>
            <p class="lead mt-5">
              Gain a competitive edge with AI-powered interview preparation. Analyse a job
              description to extract key information you can use to prepare.
            </p>
            <div class="my-5">
              <div
                v-for="checkmark in interviewPrepCheckmarks"
                :key="checkmark.text"
                class="col-md-6 d-inline-flex gap-1 lc-block w-100"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2em"
                  height="2em"
                  fill="currentColor"
                  class="text-primary my-auto flex-shrink-0"
                  viewBox="0 0 16 16"
                  lc-helper="svg-icon"
                >
                  <path
                    d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"
                  ></path>
                  <path
                    d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"
                  ></path>
                </svg>
                <p editable="inline" class="lead my-auto">{{ checkmark.text }}</p>
              </div>
            </div>
            <!-- Call-to-Action Button -->
            <button @click="login()" class="btn btn-primary btn-lg mt-3">Get Interview Help</button>
          </div>
          <div class="col-md-6">
            <img
              loading="lazy"
              class="cover-letter-example-img"
              src="../../public/media/InterviewPrepExample.webp"
              alt="Cover Letter Example"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="w-100 d-flex justify-content-center" ref="counterContainer">
      <div class="w-100 text-center">
        <div class="display-4">
          <span>We've saved users </span>
          <span
            class="text-primary"
            style="width: 150px; display: inline-block; text-align: center"
            >{{ hoursSaved }}</span
          >
          <span> hours </span>
          <span>🎉</span>
        </div>
      </div>
    </div>
    <TestamonialsVue :testamonials="testimonials"></TestamonialsVue>

    <div class="container py-5">
      <div class="row mb-3">
        <div class="col-lg-8 mx-auto text-center">
          <div class="lc-block mb-4">
            <div editable="rich">
              <h1>How does it work?</h1>
            </div>
          </div>
          <!-- /lc-block -->
          <!-- /lc-block -->

          <div class="lc-block">
            <div editable="rich">
              <p class="lead">How to write an AI generated cover letter in less than 1 minute</p>
            </div>
          </div>
          <!-- /lc-block -->
        </div>
        <!-- /col -->
      </div>

      <div class="row mb-4">
        <div style="padding: 55.64% 0 0 0; position: relative">
          <iframe
            src="https://player.vimeo.com/video/910248763?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
            title="JobBuddy Demo"
          ></iframe>
        </div>
      </div>
      <div class="row mb-4">
        <div class="mt-4">
          <button @click="login()" class="btn btn-primary btn-lg me-3 w-50">Get Started</button>
        </div>
      </div>
    </div>
    <div class="container py-5">
      <div class="row mb-5">
        <div class="col-md-12">
          <div class="lc-block text-center">
            <div editable="rich">
              <h2 class="fw-bold">FAQ</h2>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="lc-block mb-5" v-for="(faq, index) in faqs1" :key="index">
            <div editable="rich">
              <p class="h4">{{ faq.question }}</p>
              <p>{{ faq.answer }}</p>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="lc-block mb-5" v-for="(faq, index) in faqs2" :key="index">
            <div editable="rich">
              <p class="h4">{{ faq.question }}</p>
              <p>{{ faq.answer }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4">
      <button @click="login()" class="btn btn-primary btn-lg me-3">Try for Free!</button>
      <router-link to="About" class="btn btn-outline-primary btn-lg">Learn More</router-link>
    </div>
  </div>
</template>

<script>
import { useAuth0 } from "@auth0/auth0-vue";
import TestamonialsVue from "../components/Testamonials.vue";
import { useHead } from "@unhead/vue";
import { ref } from "vue";
export default {
  name: "Homepage",
  components: { TestamonialsVue },

  mounted() {
    // Load Vimeo player script when the component is mounted
    const script = document.createElement("script");
    script.src = "https://player.vimeo.com/api/player.js";
    script.async = true;
    document.head.appendChild(script);

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.beginCounterHoursSaved();
        observer.disconnect();
      }
    });
    observer.observe(this.$refs["counterContainer"]);

    useHead({
      title: "JobBuddy - Supercharge Your Job Search with AI",
      meta: [
        {
          name: "description",
          content:
            "Transform your job search with JobBuddy, the AI-powered career assistant. From crafting tailored cover letters and resumes to acing interviews, JobBuddy optimizes every step of your application process. Boost your visibility and chances with ATS-optimized documents and personalized interview prep.",
        },
        {
          name: "keywords",
          content:
            "JobBuddy, AI job application assistant, AI cover letter generator, ATS-optimized resume, AI interview preparation, career coaching",
        },
      ],
    });
  },

  setup() {
    const { loginWithRedirect } = useAuth0();

    const login = async () => {
      await loginWithRedirect({
        appState: { target: "/dashboard" },
      });
    };

    const hoursSaved = ref(0);

    const beginCounterHoursSaved = () => {
      const intervalId = setInterval(() => {
        if (hoursSaved.value >= 10249) {
          clearInterval(intervalId);
        } else {
          hoursSaved.value += 13;
        }
      }, 0.1);
    };

    const brands = [
      { name: "Samsung", filename: "X.webp" },
      { name: "Mailchimp", filename: "Tesla.webp" },
      { name: "Google", filename: "TikTok.webp" },
      { name: "Netflix", filename: "Unilever.webp" },
      { name: "Coca Cola", filename: "Spotify.webp" },
      { name: "Adobe", filename: "Meta.webp" },
    ];

    const checkmarks = [
      { text: "No credit card required" },
      { text: "Data privacy committment" },
      { text: "Undetectable by AI checkers" },
    ];

    const coverLetterCheckmarks = [
      { text: "Write cover letters in seconds" },
      { text: "Tailored to your experience" },
      { text: "Professional templates" },
      { text: "Keyword/ATS optimized" },
    ];

    const resumeCheckmarks = [
      { text: "Tailor your resume instantly" },
      { text: "Keyword/ATS optimization" },

      { text: "Professional templates" },
      { text: "Powered by real data" },
    ];

    const interviewPrepCheckmarks = [
      { text: "Discover role keywords" },
      { text: "Highlight responsibilities" },
      { text: "Predict interview questions" },
      { text: "Generate 'STAR' answers" },
    ];

    const testimonials = [
      {
        name: "Sophie",
        jobTitle: "Data Scientist",
        description:
          "As a busy student, JobBuddy was a lifesaver, making it way easier to juggle internship applications and exams. It's been a game-changer.",
        stars: 5,
        imageSrc: "Testamonial_Sophie.webp",
      },
      {
        name: "Abe",
        jobTitle: "UX Designer",
        description:
          "I can't stand writing cover letters, but JobBuddy made life so much easier for me. I feel confident when sending cover letters with JobBuddy.",
        stars: 5,
        imageSrc: "Testamonial_Abe.webp",
      },
      {
        name: "Alice",
        jobTitle: "Product Manager",
        description:
          "This is my secret weapon. These tools were instrumental in landing me my first six-figure role. Thanks so much!",
        stars: 5,
        imageSrc: "Testamonial_Alice.webp",
      },
      {
        name: "Robert",
        jobTitle: "Change Manager",
        description:
          "Tailoring my resume for every job application was so much more manageable with JobBuddy. Highly recommended.",
        stars: 5,
        imageSrc: "Testamonial_Robert.webp",
      },
      {
        name: "Ava",
        jobTitle: "Software Engineer",
        description:
          "A friend told me about JobBuddy. I was a bit skeptical at first, but it has consistently exceeded my expectations. ",
        stars: 5,
        imageSrc: "Testamonial_Ava.webp",
      },
    ];

    const faqs1 = [
      {
        question: "How is JobBuddy different from competitors?",
        answer:
          "JobBuddy stands out by avoiding gimmicks and focusing on crafting genuine, tailored job application documents. Unlike generic platforms, we leverage data-backed AI models and a variety of templates to help you create personalized and effective cover letters and resumes.",
      },
      {
        question: "How is it better than ChatGPT?",
        answer:
          "JobBuddy outperforms ChatGPT in crafting cover letters, leveraging a multi-model AI trained on successful applications. It optimizes for Applicant Tracking Systems, provides effective templates, and organizes career documents efficiently. Choose JobBuddy for a data-backed, streamlined job application process.",
      },
      {
        question: "Is JobBuddy secure?",
        answer:
          "Yes, JobBuddy prioritizes data security. We are committed to ensuring the privacy and protection of your personal information. Our platform adheres to stringent security measures to safeguard your data. We will never share data with 3rd parties without your consent.",
      },
    ];

    const faqs2 = [
      {
        question: "Can employers tell I've used JobBuddy/AI?",
        answer:
          "No, employers cannot detect if you've used JobBuddy or any AI tool. The documents generated are designed to look natural and professional, presenting your skills and experiences seamlessly. JobBuddy operates discreetly to enhance your job application process.",
      },
      {
        question: "How much does JobBuddy cost?",
        answer:
          "JobBuddy offers a range of plans to suit your needs. Our basic tier is entirely free, and for more advanced features, our JobSeeker tier is priced at just $5 per month. This makes JobBuddy a cost-effective solution compared to using high-end models like ChatGPT directly, which can be significantly more expensive.",
      },
      {
        question: "What can JobBuddy help me to do?",
        answer:
          "JobBuddy is your comprehensive career assistant. It can help you craft personalized and compelling cover letters, optimize your resume for applicant tracking systems, provide interview preparation with tailored questions and answers, and organize all your career documents efficiently. JobBuddy's goal is to make the job application process easier and more successful for you.",
      },
    ];

    return {
      login,
      brands,
      testimonials,
      checkmarks,
      coverLetterCheckmarks,
      resumeCheckmarks,
      interviewPrepCheckmarks,
      faqs1,
      faqs2,
      hoursSaved,
      beginCounterHoursSaved,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@keyframes wiggle-glow {
  0% {
    transform: rotate(0deg) scale(1);
    filter: drop-shadow(0 0 0 rgba(255, 255, 255, 0.5));
  }
  50% {
    transform: rotate(-10deg) scale(1.2);
    filter: drop-shadow(0 0 10px #0275d8);
  }
  100% {
    transform: rotate(0deg) scale(1);
    filter: drop-shadow(0 0 0 rgba(255, 255, 255, 0.5));
  }
}

.lightning {
  animation: wiggle-glow 1s ease forwards;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #0275d8;
}

.mascot {
  -webkit-filter: drop-shadow(7px 7px 7px rgba(255, 255, 255, 0.5));
  filter: drop-shadow(7px 7px 7px rgba(255, 255, 255, 0.5));
}

.mascot:hover {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.5s;

  /* When the animation is finished, start again */
  animation-iteration-count: 1;
}

.jobbuddy-example-section {
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 2, 23, 0.22);
}

.cover-letter-example-img {
  max-width: 400px; /* Ensure the image doesn't overflow its container */
  border-radius: 8px; /* Add a slight border radius for a modern look */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); /* Add a subtle shadow for depth */
}

.jobbuddy-example-section {
  background-color: #f8f9fa; /* Add a light background color to make it pop on a white background */
  padding: 40px;
  border-radius: 10px;
}

.counter-container {
  width: 300px; /* Adjust the width as needed */
}

.cover-letter-example-img {
  max-width: 75%; /* Adjust the width to fit the container */
  border-radius: 0px; /* Increase border radius for a softer look */
  box-shadow: 0 0 5px rgba(0, 12, 144, 0.446); /* Increase shadow depth for a more pronounced effect */
  transition: transform 0.3s ease-in-out; /* Add a subtle transition effect */
  box-shadow:
    /* The top layer shadow */ 0 1px 1px rgba(0, 0, 0, 0.15),
    /* The second layer */ 0 10px 0 -5px #eee,
    /* The second layer shadow */ 0 10px 1px -4px rgba(0, 0, 0, 0.15),
    /* The third layer */ 0 20px 0 -10px #eee,
    /* The third layer shadow */ 0 20px 1px -9px rgba(0, 0, 0, 0.15);
  /* Padding for demo purposes */
  border: 1px solid rgba(218, 215, 215, 0.817);
}

.cover-letter-example-img:hover {
  transform: scale(1.1); /* Scale up the image on hover for an interactive effect */
  box-shadow: 0 0 10px rgba(0, 19, 227, 0.446); /* Increase shadow depth for a more pronounced effect */
}

@media (max-width: 767px) {
  .row {
    flex-direction: column-reverse; /* Reverse the order for smaller screens */
  }

  .cover-letter-example-img {
    margin-top: 20px; /* Add margin to separate the image from text on mobile */
  }
}
</style>
