<template>
  <div class="container">
    <div class="cover-letter-browser">
      <h2 class="fw-bold mb-2 display-8">
        Cover Letter Examples
        <span class="text-primary"><i>That Got Applicants Hired</i>💡</span>
      </h2>
      <p class="fw-light px-5 m-3">
        Here are some cover letter examples that got applicants hired using JobBuddy's AI cover
        letter generator. Click on the cover letter examples below to see how they were written and
        what made them successful.
      </p>
      <div class="row w-100">
        <div
          v-for="example in coverLetterExamples"
          :key="example.Role"
          class="col-sm-3 col-6 my-3 cover-letter-item"
        >
          <router-link
            :to="{
              name: 'CoverLetterDetail',
              params: { role: example.Role.toLowerCase().replace(/ /g, '-') },
            }"
            class="text-dark text-decoration-none"
          >
            <div class="h-100 w-100">
              <img
                :src="getImageUrl(example.ExampleImgSrc)"
                :alt="`Cover letter example for ${example.Role}`"
                class="cover-letter-image shadow"
                loading="lazy"
              />

              <p class="py-2 text-secondary">{{ example.Role }} Cover Letter</p>
            </div>
          </router-link>
        </div>
        <div class="m-1 rounded bg-body-tertiary">
          <div class="m-3 text-body p-3">
            <h3 class="h5 mb-2 text-body">
              <b
                >Create Your Cover Letter in <u><i>Seconds</i></u> and <u><i>for Free</i></u> with
                Job<span class="text-primary">Buddy</span></b
              >
              💡
            </h3>
            <p>
              Each of these cover letters was carefully researched, written and formatted using the
              most advanced AI cover letter generator in the world. Sign up for free to create your
              own cover letters. It's easy, fast and free.
            </p>

            <div class="row mt-5 w-100">
              <router-link :to="{ name: 'Dashboard' }">
                <button class="btn btn-primary btn-lg w-100">Create Cover Letter</button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { coverLetterExamples } from "../views/blogs/CoverLetterExamples";
import { useHead } from "@unhead/vue";

export default {
  name: "CoverLetterBrowser",
  methods: {
    getImageUrl(path) {
      return require(`../../public/media/coverLetterExamples/${path}`);
    },
  },
  setup() {
    useHead({
      title: "Cover Letter Examples That Get You Noticed - JobBuddy",
      meta: [
        {
          name: "description",
          content:
            "Boost your job application with our tailored cover letter examples and templates. See how JobBuddy helps craft persuasive and effective cover letters to help you land your dream job.",
        },
        {
          name: "keywords",
          content:
            "cover letter examples, JobBuddy cover letters, effective cover letters, cover letter templates, job application help",
        },
      ],
    });
  },
  data() {
    return {
      coverLetterExamples,
    };
  },
};
</script>

<style>
.cover-letter-browser {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.cover-letter-item {
  text-align: center;
  cursor: pointer;
}

.cover-letter-image {
  width: 100%;
  height: auto;
}

.cover-letter-image:hover {
  transform: scale(1.05);
  transition: transform 0.3s;
  border: 3px solid rgba(0, 0, 255, 0.304); /* Change 'blue' to any desired highlight color */
  border-radius: 3px;
  box-shadow: 0 0 15px rgba(0, 0, 255, 0.5); /* Additional shadow for selected image */
}
</style>
