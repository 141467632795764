<template>
  <div class="payment d-flex flex-column align-items-center bg-light p-5 rounded shadow">
    <h1 class="title mb-4">Secure Checkout</h1>

    <div class="container">
      <div class="row">
        <div class="card-info bg-white p-4 rounded shadow-sm mb-4">
          <h2 class="subtitle mb-4">Please Enter Your Card Details</h2>
          <div id="card-element">
            <!-- Stripe Element will be inserted here. -->
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <button
          class="btn btn-dark w-100 py-2 rounded mb-4"
          :disabled="isProcessing"
          @click="submitPayment"
        >
          Confirm Payment
        </button>
      </div>
    </div>

    <div class="secure d-flex align-items-center justify-content-center text-muted mt-4">
      <i class="bi bi-shield-lock-fill me-2"></i>
      <p class="mb-0">Transactions are encrypted and secure. Powered by <b>Stripe</b></p>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { loadStripe } from "@stripe/stripe-js";
import { SubscriptionsApiService } from "@/services/SubscriptionsApiService";
import { toast } from "vue3-toastify";
import router from "@/router";
import store from "../store";
import { useRoute } from "vue-router";

const publicKey = process.env.VUE_APP_STRIPE_PUBLIC_KEY;
let stripe, elements, card;

const isProcessing = ref(false);
const selectedPlan = ref({
  priceId: useRoute().params.priceId,
  plan: useRoute().params.plan,
});

onMounted(async () => {
  stripe = await loadStripe(publicKey);
  elements = stripe.elements();

  card = elements.create("card");
  card.mount("#card-element");
});

async function submitPayment() {
  isProcessing.value = true;

  try {
    store.dispatch("toggleLoader", true);
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: "card",
      card: card,
    });

    if (error) {
      throw new Error(error.message);
    }

    const response = await SubscriptionsApiService.createSubscription({
      paymentMethodId: paymentMethod.id,
      priceId: selectedPlan.value.priceId,
    });

    if (response.error) {
      throw new Error(response.error.message);
    } else {
      if (response.data.subscription?.latest_invoice?.payment_intent?.status == "requires_action") {
        await handlePaymentRequiresAction(
          response.data.subscription.latest_invoice.payment_intent.client_secret
        );
        store.dispatch("toggleLoader", false);
        return;
      }
      if (
        !response.data.subscription?.plan?.active ||
        response.data.subscription?.status == "incomplete"
      ) {
        throw Error("Card declined!");
      }
      toast.success("Payment confirmed!");
      await store.dispatch("user/loadLoggedInUser");
      router.push({ name: "Dashboard" });
    }
  } catch (error) {
    console.error(error);
    toast.error("Payment confirmation failed!");
  } finally {
    isProcessing.value = false;
    store.dispatch("toggleLoader", false);
  }
}

async function handlePaymentRequiresAction(paymentIntentClientSecret) {
  const result = await stripe.confirmCardPayment(paymentIntentClientSecret);

  if (result.paymentIntent?.status === "requires_action") {
    const { error: actionError } = await stripe.handleCardAction(paymentIntentClientSecret);
    if (actionError) {
      throw new Error(actionError.message);
    }
  } else if (result.paymentIntent?.status === "succeeded") {
    await submitPayment();
    return;
  } else {
    throw new Error("Payment failed!");
  }
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");

.payment {
  max-width: 900px;
  margin: 0 auto;
  font-family: "Roboto", sans-serif;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.title {
  font-size: 2em;
  color: #34495e;
}

.subtitle {
  font-size: 1em;
  color: #34495e;
}

.btn:disabled {
  background-color: #bbb;
  cursor: not-allowed;
}

.secure i {
  font-size: 1em;
  color: #34495e;
}
</style>
