<template>
  <Modal title="There's been an error!" ref="errorModal">
    <template #body>There was an error processing this request. Please try again later!</template>
  </Modal>
  <Modal title="Are you sure you want to delete this application?" ref="appDeleteModal">
    <template #body>This action cannot be undone!</template>
    <template #footer>
      <button @click="deleteApplication()" class="btn btn-danger">Delete</button>
    </template>
  </Modal>
  <Modal title="Are you sure you want to delete this document?" ref="docDeleteModal">
    <template #body>This action cannot be undone!</template>
    <template #footer>
      <button @click="deleteDocument()" class="btn btn-danger">Delete</button>
    </template>
  </Modal>
  <Modal class="modal-lg" title="Welcome to JobBuddy! 👋" ref="userHelpModal">
    <template #body>
      <div class="container">
        <div class="row align-items-start text-start d-flex align-items-center">
          <div class="col-1">
            <div style="font-size: 200%">📄</div>
          </div>
          <div class="col-11">
            To get started with JobBuddy, you need to add a resume. Drag and drop (or click) on the
            documents area below to upload your resume. You can add as many resumes as you like.
          </div>
        </div>
        <br />
        <div class="row align-items-start text-start d-flex align-items-center">
          <div class="col-1">
            <div style="font-size: 200%">👤</div>
          </div>
          <div class="col-11">
            Make sure your details are correct in the profile area. JobBuddy will use these details
            to improve your experience.
          </div>
        </div>
        <br />

        <div class="row align-items-start text-start d-flex align-items-center">
          <div class="col-1">
            <div style="font-size: 200%">👇</div>
          </div>
          <div class="col-11">
            Click 'Create new application' and add any details about the job you're applying for.
            The more details, the better the outcome!
          </div>
        </div>
        <br />

        <div class="row align-items-start text-start d-flex align-items-center">
          <div class="col-1">
            <div style="font-size: 200%">✏️</div>
          </div>
          <div class="col-11">
            Select which output you'd like, for example for a cover letter, and click 'Generate
            Document'. The outputs of JobBuddy are organised as documents in the right hand menu.
            You can favourite these documents, or delete them as needed.
          </div>
        </div>
        <br />

        <div class="row align-items-start text-start d-flex align-items-center">
          <div class="col-1">
            <div style="font-size: 200%">👍</div>
          </div>
          <div class="col-11">
            JobBuddy learns from user feedback. If you receive a response which is bad - click the
            thumbs down button! If you get a great response, let us know by clicking the thumbs up
            button!
          </div>
        </div>
        <br />

        <div class="row align-items-start text-start d-flex align-items-center">
          <div class="col-1">
            <div style="font-size: 200%">🤲</div>
          </div>
          <div class="col-11">
            Got feedback? Need support? Spotted a bug (problem)? Let us know using the buttons in
            the footer, and we'll get back to you as quickly as we can. We
            <u>always</u> respond to users and frequently make updates to JobBuddy based on user
            feedback.
          </div>
        </div>
        <br />

        <br />

        <div class="d-flex align-items-center">
          <div class="col-12">
            <span class="fw-lighter"
              >Access these tips at any time, just click the question mark icon at the bottom of
              this page.</span
            >
          </div>
        </div>
      </div>
    </template>
    <template #footer> </template>
  </Modal>

  <h1 class="text-uppercase text-muted fw-light m-3">Application Dashboard 🚀</h1>

  <div />
  <div class="container h-100">
    <div class="row p-25">
      <div class="col card order-first m-3 p-0 pb-10 shadow-lg">
        <div class="card-body p-0">
          <h5 class="card-header">My Applications</h5>

          <div v-if="applicationsList?.length > 0" class="h-100 overflow-auto table-wrapper">
            <table class="table table-hover">
              <tbody>
                <tr v-for="app in applicationsList" :key="app.applicationId">
                  <td scope="row" class="p-1">
                    <router-link
                      :to="{
                        name: 'MyApplication',
                        params: { id: app.applicationId },
                      }"
                      style="text-decoration: none; /* Remove underline */ color: inherit"
                    >
                      <div class="d-flex justify-content-between">
                        <div class="col">{{ app.roleTitle }}</div>
                        <div class="col">@{{ app.companyName }}</div>
                      </div>
                    </router-link>
                  </td>
                  <td class="p-1">
                    <i
                      v-tooltip="'Delete Application'"
                      role="button"
                      class="bi bi-trash m-2"
                      style="font-size: 15px; color: red"
                      @click="confirmDeleteApplication(app.applicationId)"
                    />
                    <router-link
                      :to="{
                        name: 'MyApplication',
                        params: { id: app.applicationId },
                      }"
                      style="text-decoration: none; color: inherit"
                      v-tooltip="'Open Application'"
                    >
                      <i href="#" class="bi bi-folder m-2" style="font-size: 15px; color: green" />
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else>
            <div class="card-body d-flex align-items-center justify-content-around">
              <h5 class="text-secondary">No applications yet!</h5>
            </div>
          </div>
        </div>

        <div class="card-footer">
          <router-link to="CreateApplication" style="text-decoration: none; color: inherit">
            <button class="btn btn-primary btn-md align-items-center">
              Create new application
            </button>
          </router-link>
        </div>
      </div>
    </div>
    <div class="row m-1">
      <div class="col-sm card p-0 me-2 mb-3 shadow-sm">
        <div class="card-header">My Resumes</div>
        <FileUpload
          :records="documentsList"
          @file-uploaded="handleCVUpload($event)"
          @file-deleted="confirmDocDelete($event)"
        />
      </div>
      <div class="col-sm card p-0 mb-3 shadow-sm">
        <div class="card-header">Application Taskboard</div>
        <router-link :to="{ name: 'TaskBoard' }">
          <img
            loading="lazy"
            class="w-100 hover taskboard"
            src="../../public/media/Taskboard.webp"
          />
        </router-link>
      </div>
    </div>
  </div>
  <div>
    <i @click="userHelpModal.show()" role="button" class="bi bi-question-square-fill mb-3"></i>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import { useAuth0 } from "@auth0/auth0-vue";
import FileUpload from "@/components/FileUpload.vue";
import store from "@/store";
import { UserApplicationListItem } from "@models/dtos/UserApplicationList";
import { ApplicationApiService } from "@/services/ApplicationApiService";
import Document from "@models/Document";
import Modal from "@/components/Modal.vue";
import { toast } from "vue3-toastify";
import router from "../router";
const documentsList = ref<Document[]>();
const user = ref({});
user.value = useAuth0().user;
const applicationsList = ref(new Array<UserApplicationListItem>());

const errorModal = ref(null);
const appDeleteModal = ref(null);
const docDeleteModal = ref(null);
const userHelpModal = ref(null);

let applicationIdToDelete = "";
let documentIdToDelete = "";
onMounted(async () => {
  store.dispatch("toggleLoader", true);
  await loadUserApplications();
  await loadUserDocuments();
  store.dispatch("toggleLoader", false);
  if (user.value.value.email_verified === false) {
    router.push("/verifyEmail");
    return;
  }
  if (documentsList.value?.length == 0 && applicationsList.value.length == 0) {
    userHelpModal.value.show();
  }
});

async function loadUserDocuments() {
  //Don't load documents uneccessarily
  await store.dispatch("documents/loadDocuments");
  documentsList.value = store.getters["documents/getDocuments"];
}

function confirmDeleteApplication(applicationId: number) {
  applicationIdToDelete = applicationId;
  appDeleteModal.value.show();
}

async function deleteApplication() {
  appDeleteModal.value.hide();
  if (!applicationIdToDelete) return;
  const result = await ApplicationApiService.delete(applicationIdToDelete);
  if (result.status == 200) {
    applicationsList.value = applicationsList.value.filter(
      (app) => app.applicationId != applicationIdToDelete
    );
    toast.success("Application deleted successfully");
  } else {
    errorModal.value.show();
    toast.error("Application could not be deleted");
  }
  applicationIdToDelete = "";
}

async function loadUserApplications() {
  const response = await ApplicationApiService.getUserApplicationList();
  if (response.status == 200) {
    applicationsList.value = response.data.applications;
  }
}

async function handleCVUpload(event) {
  console.log(event);
  await postDocuments(event);
  toast.success("Document uploaded successfully");
}

async function postDocuments(file) {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("userId", user.value.value.sub);
  await store.dispatch("documents/uploadDocument", formData);
  await loadUserDocuments();
}

function goToTaskboard() {
  router.push("/taskboard");
}
function confirmDocDelete(documentId: string) {
  documentIdToDelete = documentId;
  docDeleteModal.value.show();
}

async function deleteDocument() {
  if (!documentIdToDelete) return;
  await store.dispatch("documents/deleteDocument", documentIdToDelete);
  await loadUserDocuments();
  documentIdToDelete = "";
  docDeleteModal.value.hide();
  toast.success("Document deleted successfully");
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #0275d8;
}

.table-wrapper {
  max-height: 300px;
  overflow: auto;
}
.taskboard {
  filter: drop-shadow(0 0 0.5rem #9c8ff45c);
}
</style>
