<template>
  <div class="container d-flex justify-content-center align-items-center h-100">
    <div class="text-center h-100">
      <h4 class="mt-5 mb-4 display-4">Please verify your email<span class="ms-3 mail">✉️</span></h4>
      <h6 class="mb-4">
        JobBuddy requires you to verify your email. Click the link emailed to you, or request
        another
      </h6>
      <div class="mt-5">
        <button class="btn btn-primary m-1" @click="resendVerificationEmail">
          Re-Send Email Verification Link
        </button>
        <button class="btn btn-secondary m-1" @click="reloadPage">
          Already Verified? Click Here
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import store from "../store";
import { UserApiService } from "@/services/UserApiService";
import router from "../router";
import { onMounted } from "vue";

onMounted(() => {
  if (sessionStorage.getItem("redirectToDashboard") === "true") {
    sessionStorage.removeItem("redirectToDashboard");
    router.push({ name: "Dashboard" });
  }
});
async function resendVerificationEmail() {
  try {
    store.dispatch("toggleLoader", true);

    await UserApiService.resendVerificationEmail();
    console.log("Verification email sent:");
  } catch (error) {
    console.error("Error sending verification email:", error);
  } finally {
    store.dispatch("toggleLoader", false);
  }
}

async function reloadPage() {
  sessionStorage.setItem("redirectToDashboard", "true");

  // Reload the page
  window.location.reload();
}
</script>
<style scoped></style>
