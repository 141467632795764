<template>
  <div>
    <!-- ERROR MODAL -->
    <Modal title="There's been an error!" ref="errorModal">
      <template #body>There was an error processing this request. Please try again later!</template>
    </Modal>
    <!-- FEEDBACK MODAL -->
    <Modal class="modal-lg" title="Help Make JobBuddy Better ✅" ref="submitIdeaFeedbackModal">
      <template #body>
        <br />Had an idea to improve JobBuddy? Something not working quite as you'd like? Tell us
        the good, bad and ugly. What's your pain, how can JobBuddy help with that? All feedback
        helps make JobBuddy better!<br /><br />
        <textarea
          v-model="feedback.feedback"
          class="form-control p-5"
          rows="5"
          placeholder="Give as much detail as you can. It helps me a lot!"
          required
        />
        <br />
        <button class="btn btn-primary w-50" @click="submitGeneralFeedback('Idea')">
          Submit Feedback
        </button>
      </template>
    </Modal>
    <!-- BUG MODAL -->
    <Modal class="modal-lg" title="Report A Problem 🐞" ref="submitBugFeedbackModal">
      <template #body>
        <br />Discovered a bug, noticed something strange or broken? Let us know! We're always
        building new features for JobBuddy, so sometimes things can go wrong. When that happens, we
        value feedback from users to help us get back on track.<br /><br />
        <textarea
          v-model="feedback.feedback"
          class="form-control p-5"
          rows="5"
          placeholder="Give as much detail as you can. How can we reproduce the problem?"
          required
        />
        <br />
        <button class="btn btn-primary w-50" @click="submitGeneralFeedback('Bug')">
          Submit Report
        </button>
      </template>
    </Modal>
    <!-- SUPPORT MODAL -->
    <Modal class="modal-lg" title="Contact JobBuddy Support 🛟" ref="submitSupportFeedbackModal">
      <template #body>
        <br />Having problems with JobBuddy? Not to worry, we're always on hand to help. Let us know
        how we can help, and we'll endeavor to get back to you shortly.<br /><br />
        <textarea
          v-model="feedback.feedback"
          class="form-control p-5"
          rows="5"
          placeholder="Give as much detail as you can. It helps a lot!"
          required
        />
        <br />
        <button class="btn btn-primary w-50" @click="submitGeneralFeedback('Support')">
          Submit Support Request
        </button>
      </template>
    </Modal>

    <!-- ADVANCED AI MODAL -->
    <Modal title="Unlock the Power of Cutting-Edge AI ⚡️" hide-close-button ref="advancedAIModal">
      <template #body>
        Elevate your documents and job applications with our latest AI technology. Experience
        unparalleled accuracy and sophistication powered by GPT-4o.

        <br />
        <br />
        <b><i>Upgrade your subscription now to experience our most advanced AI yet!</i></b>
      </template>
      <template #footer>
        <button
          @click="upgradeSubscriptionAction"
          class="w-100 btn btn-warning btn-lg mt-auto rounded-pill"
        >
          Upgrade Now
        </button>
      </template>
    </Modal>

    <!-- ADVANCED AI MODAL -->
    <Modal title="This is a premium feature 💎" hide-close-button ref="upgradeModal">
      <template #body>
        This feature is still in Beta and is currently only available to paid users.
        <br />
        <br />
        <b
          ><i
            >Upgrade to a paid tier to make use of JobBuddy Beta features before anybody else!</i
          ></b
        >
      </template>
      <template #footer>
        <button
          @click="upgradeSubscriptionAction"
          class="w-100 btn btn-warning btn-lg mt-auto rounded-pill"
        >
          Upgrade Now
        </button>
      </template>
    </Modal>

    <!-- QUOTA EXCEEDED MODAL -->
    <Modal title="Subscription Quota Reached" hide-close-button ref="quotaExceededModal">
      <template #body
        >Oops! It looks like you've reached the limit for creating new responses on your current
        subscription plan. To continue enjoying our services without interruption, please consider
        upgrading.</template
      >
      <template #footer>
        <button
          @click="upgradeSubscriptionAction"
          class="w-100 btn btn-warning btn-lg mt-auto rounded-pill"
        >
          Upgrade Subscription
        </button>
      </template>
    </Modal>
    <!-- CONFIRM DELETE MODAL -->
    <Modal title="Are you sure you want to delete this response?" ref="responseDeleteModal">
      <template #body>This action cannot be undone!</template>
      <template #footer>
        <button @click="deleteResponse()" class="btn btn-danger">Delete</button>
      </template>
    </Modal>
    <!-- MISSING CV WARNING MODAL-->
    <Modal title="Missing Resume!" ref="missingCvWarning">
      <template #body
        >JobBuddy works best when you've uploaded a resume, in order to personalize your cover
        letter using real information like your experiences, qualifications and qualities. You can
        upload a resume in the dashboard area. If you do not upload a resume, you are more likely to
        experience 'AI Hallucinations'<br />Don't worry, your application progress is saved and can
        be accessed at any time.
      </template>
      <template #footer>
        <button @click="handleCvWarn()" class="btn btn-danger">Continue Anyway</button>
      </template>
    </Modal>
    <!-- PICK A RESUME MODAL-->
    <Modal class="modal-lg" title="Configure Your Resume" ref="pickAResumeTemplate">
      <template #body>
        <div class="row">
          <div class="col">
            <label class="image-option">
              <input
                type="radio"
                v-model="selectedTemplate"
                :value="TemplateOptions.RESUME_GRADUATE"
              />
              <img loading="lazy" src="../../public/media/templateThumbnails/ResumeGraduate.webp" />
            </label>
            <span>Graduate</span>
          </div>
          <div class="col">
            <label class="image-option">
              <input
                type="radio"
                v-model="selectedTemplate"
                :value="TemplateOptions.RESUME_CLASSIC"
              />
              <img loading="lazy" src="../../public/media/templateThumbnails/ResumeClassic.webp" />
            </label>
            <span>Classic</span>
          </div>
          <div class="col">
            <label class="image-option">
              <input
                type="radio"
                v-model="selectedTemplate"
                :value="TemplateOptions.RESUME_MODERN"
              />
              <img loading="lazy" src="../../public/media/templateThumbnails/ResumeModern.webp" />
            </label>
            <span>Modern</span>
          </div>
        </div>
        <div></div>
      </template>
      <template #footer>
        <button @click="generateResumeAction()" class="btn btn-primary">Generate Resume</button>
      </template>
    </Modal>

    <!-- PICK A COVER LETTER MODAL-->
    <div>
      <!-- COVER LETTER CONFIGURATION MODAL -->
      <Modal class="modal-lg" title="Configure Your Cover Letter" ref="pickACoverLetterTemplate">
        <template #body>
          <div class="row">
            <div class="col">
              <label class="image-option">
                <input
                  type="radio"
                  v-model="coverLetterSettings.template"
                  :value="TemplateOptions.COVER_LETTER_GRADUATE"
                />
                <img
                  loading="lazy"
                  src="../../public/media/templateThumbnails/CoverGraduate.webp"
                />
              </label>
              <span>Graduate</span>
            </div>
            <div class="col">
              <label class="image-option">
                <input
                  type="radio"
                  v-model="coverLetterSettings.template"
                  :value="TemplateOptions.COVER_LETTER_CLASSIC"
                />
                <img loading="lazy" src="../../public/media/templateThumbnails/CoverClassic.webp" />
              </label>
              <span>Classic</span>
            </div>
            <div class="col">
              <label class="image-option">
                <input
                  type="radio"
                  v-model="coverLetterSettings.template"
                  :value="TemplateOptions.COVER_LETTER_MODERN"
                />
                <img loading="lazy" src="../../public/media/templateThumbnails/CoverModern.webp" />
              </label>
              <span>Modern</span>
            </div>
          </div>
          <div class="mt-4">
            <button
              class="btn btn-outline-primary d-flex align-items-center justify-content-between w-100"
              :class="chevronClass === 'bi bi-chevron-up' && 'rounded-bottom-0'"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#coverLetterSettings"
              @click="toggleChevron"
            >
              Custom Cover Letter Settings (optional)
              <i :class="chevronClass"></i>
            </button>
            <div class="collapse" id="coverLetterSettings">
              <div class="card card-body rounded-top-0">
                <div class="cover-letter-general-settings">
                  <div class="mb-3">
                    <label for="cover-letter-length-slider" class="form-label"
                      >Target Cover Letter Length | {{ coverLetterSettings.length }} Words |
                      {{ Math.max(Math.round(coverLetterSettings.length / 400), 1) }} Page</label
                    >
                    <input
                      v-model="coverLetterSettings.length"
                      class="form-range"
                      min="100"
                      max="650"
                      id="cover-letter-length-slider"
                      type="range"
                      step="50"
                    />
                  </div>
                  <div class="mb-3">
                    <label for="openingSentence" class="form-label">Opening Sentence</label>
                    <select id="openingSentence" v-model="selectedOpening" class="form-select">
                      <option
                        v-for="option in coverLetterOptions.opening"
                        :key="option"
                        :value="option"
                      >
                        {{ option }}
                      </option>
                      <option value="custom">Custom</option>
                    </select>
                    <input
                      v-if="selectedOpening === 'custom'"
                      type="text"
                      v-model="customOpening"
                      class="form-control mt-2"
                      placeholder="Enter your custom opening sentence"
                    />
                  </div>
                  <div class="mb-3">
                    <label for="closingSentence" class="form-label">Closing Sentence</label>
                    <select id="closingSentence" v-model="selectedClosing" class="form-select">
                      <option
                        v-for="option in coverLetterOptions.closing"
                        :key="option"
                        :value="option"
                      >
                        {{ option }}
                      </option>
                      <option value="custom">Custom</option>
                    </select>
                    <input
                      v-if="selectedClosing === 'custom'"
                      type="text"
                      v-model="customClosing"
                      class="form-control mt-2"
                      placeholder="Enter your custom closing sentence"
                    />
                  </div>
                </div>
                <!-- <div class="coverLetterOptionTickboxes m-3">
                  <span class="d-block mb-2">Include In Cover Letter</span>
                  <div class="row">
                    <div class="col-6 col-sm-3 form-check form-switch mb-2">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckDate"
                        v-model="coverLetterSettings.date"
                      />
                      <label class="form-check-label" for="flexSwitchCheckDate">Date</label>
                    </div>
                    <div class="col-6 col-sm-3 form-check form-switch mb-2">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckMobile"
                        v-model="coverLetterSettings.phone"
                      />
                      <label class="form-check-label" for="flexSwitchCheckMobile"
                        >Mobile Number</label
                      >
                    </div>
                    <div class="col-6 col-sm-3 form-check form-switch mb-2">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckEmail"
                        v-model="coverLetterSettings.email"
                      />
                      <label class="form-check-label" for="flexSwitchCheckEmail">Email</label>
                    </div>
                    <div class="col-6 col-sm-3 form-check form-switch mb-2">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckCity"
                        v-model="coverLetterSettings.city"
                      />
                      <label class="form-check-label" for="flexSwitchCheckCity">City</label>
                    </div>
                  </div>
                </div> -->
                <div>
                  <div class="mb-3" v-if="aiPersonas.length > 0">
                    <label for="openingSentence" class="form-label">Selected AI Profile*</label>
                    <select
                      id="openingSentence"
                      v-model="aiPersonas[selectedPersona].name"
                      class="form-select"
                      disabled="true"
                    >
                      <option v-for="option in aiPersonas" :key="option" :value="option.name">
                        {{ option.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="m-3">
                  <span class="text-secondary"
                    ><i
                      >*Select and configure the AI profile from the <i class="bi bi-cpu"></i> AI
                      Profiles (Beta) tab</i
                    ></span
                  >
                </div>
                <div class="w-100 border-top">
                  <div class="d-flex m-3">
                    <button
                      class="btn btn-outline-danger m-auto p-2 w-25"
                      @click="deleteCoverLetterSettings"
                    >
                      Reset to Default
                    </button>
                    <button
                      v-show="unsavedCoverLetterSettingsChanges"
                      class="btn btn-outline-primary m-auto p-2 w-25"
                      @click="saveCoverLetterSettings"
                    >
                      Save & Apply
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template #footer>
          <button @click="generateCoverLetterAction()" class="btn btn-primary">
            Generate Cover Letter
          </button>
        </template>
      </Modal>
    </div>

    <!-- FEEDBACK MODAL -->
    <Modal title="Please help with a few more details" ref="feedbackModal" class="modal-lg">
      <template #body>
        Please help us by ranking the following criteria, where
        <span class="fst-italic">1 is very poor </span> and
        <span class="fst-italic">100 is exceptional</span>.
        <div class="m-2">
          <label class="form-label"
            ><b>Accuracy of response </b> - {{ responseFeedback.accuracy }} <br /><span
              class="fst-italic fs-6"
              >Was the response true, were all included details accurate?</span
            ></label
          >
          <input v-model="responseFeedback.accuracy" type="range" class="form-range" />
        </div>
        <div>
          <label class="form-label"
            ><b>Formatting of response </b> - {{ responseFeedback.formatting }} <br /><span
              class="fst-italic fs-6"
              >Was the response formatted as you'd expect?</span
            ></label
          >

          <input v-model="responseFeedback.formatting" type="range" class="form-range" />
        </div>
        <div>
          <label class="form-label"
            ><b>Quality of communication</b> - {{ responseFeedback.communication }} <br /><span
              class="fst-italic fs-6"
              >Was the response clear and concise? Did it make sense?</span
            ></label
          >
          <input v-model="responseFeedback.communication" type="range" class="form-range" />
        </div>
        <div>
          <label class="form-label"
            ><b>Overall response rating</b> - {{ responseFeedback.overallRating }} <br /><span
              class="fst-italic fs-6"
              >Overall, how did you rate the response?</span
            ></label
          >
          <input v-model="responseFeedback.overallRating" type="range" class="form-range" />
        </div>
        <textarea
          class="form-control h-100"
          placeholder="(Optional) Please add any additional comments here."
          v-model="responseFeedback.comments"
        />
      </template>
      <template #footer>
        <span class="fw-lighter fs-6"
          >By clicking submit, you agree that JobBuddy my use this response and the data used to
          generate it to improve the JobBuddy service.</span
        >
        <button @click="submitFeedback()" class="btn btn-primary">Submit</button>
      </template>
    </Modal>
    <!-- DOWNLOAD TYPE MODAL -->
    <Modal title="Choose a file format" ref="downloadModal">
      <template #body>
        <div class="row">
          <div class="col">
            <div class="form-check d-flex align-items-center">
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
                value="PDF"
                v-model="downloadType"
              />
              <i class="bi bi-file-earmark-pdf p-3" style="font-size: 35px"></i>
              <label class="form-check-label" for="flexRadioDefault1"> PDF </label>
            </div>
          </div>
          <div class="col">
            <div class="form-check d-flex align-items-center">
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
                value="WORD"
                v-model="downloadType"
              />
              <i class="bi bi-file-earmark-word p-3" style="font-size: 35px"></i>
              <label class="form-check-label" for="flexRadioDefault2"> Word </label>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <button type="button" class="btn btn-primary" @click="downloadResponse">
          <i class="bi bi-download"></i> Download
        </button>
      </template>
    </Modal>

    <div class="container-fluid h-100" style="max-width: 98vw">
      <div class="row mh-30 h-100 p-0">
        <div
          class="col text-center d-flex mt-3 application-details h-100"
          style="min-height: 85vh; max-height: 85vh"
        >
          <div v-if="isEditResponseOpen && currentResponse?.schema != null" class="w-100">
            <EditResponseVue
              :schema="currentResponse?.schema"
              @update-schema="updateSchema"
              @delete-from-schema="deleteFromSchema"
              @close-editor="closeEditor"
              @save-response="saveResponse"
              class="overflow-y-auto h-100"
            >
            </EditResponseVue>
          </div>
          <div v-else class="application-settings w-100 d-flex">
            <div class="card h-100 w-100 d-flex flex-column">
              <div class="">
                <span>
                  <i
                    style="cursor: pointer"
                    class="bi bi-floppy"
                    @click="updateApplication"
                    v-if="applicationOrUserDetailsChanged"
                  ></i>
                </span>
              </div>

              <ul class="nav nav-tabs custom-tabs" id="myTab">
                <li class="nav-item">
                  <button
                    class="nav-link"
                    :class="{ active: activeTab === 'application' }"
                    id="application-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#application"
                    type="button"
                    @click="setActiveTab('application')"
                  >
                    <i class="bi bi-vector-pen"></i> Application Details
                  </button>
                </li>
                <li class="nav-item">
                  <button
                    class="nav-link"
                    :class="{ active: activeTab === 'personal' }"
                    id="personal-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#personal"
                    type="button"
                    @click="setActiveTab('personal')"
                  >
                    <i class="bi bi-person"></i> Personal Details
                  </button>
                </li>
                <li class="nav-item">
                  <button
                    class="nav-link"
                    :class="{ active: activeTab === 'ai' }"
                    id="ai-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#ai"
                    type="button"
                    @click="setActiveTab('ai')"
                    v-tooltip="'Configure the AI to generate responses that match your personality'"
                  >
                    <i class="bi bi-cpu"></i> AI Profiles (Beta)
                  </button>
                </li>
              </ul>

              <div class="tab-content overflow-y-auto" id="myTabContent">
                <div
                  class="tab-pane fade"
                  :class="activeTab == 'application' && 'show active'"
                  id="application"
                >
                  <!-- Application Details -->
                  <div class="input-group pt-2 px-2">
                    <span class="input-group-text w-25">Company Name</span>
                    <input
                      type="text"
                      class="form-control"
                      v-model.lazy="application.companyName"
                    />
                  </div>
                  <div class="input-group pt-2 px-2">
                    <span class="input-group-text w-25">Job Title</span>
                    <input type="text" class="form-control" v-model.lazy="application.roleTitle" />
                  </div>
                  <div class="input-group pt-2 px-2">
                    <span class="input-group-text w-100 rounded-bottom-0">Job Description</span>
                    <textarea
                      class="form-control flex-grow-1 rounded-top-0"
                      placeholder="Copy and paste the job description here."
                      v-model.lazy="application.jobDescription"
                      style="resize: none; height: 100%"
                      rows="13"
                    ></textarea>
                  </div>
                  <div class="input-group pt-2 px-2">
                    <span class="input-group-text w-100 rounded-bottom-0">Additional Comments</span>
                    <textarea
                      class="form-control flex-grow-1 rounded-top-0"
                      placeholder="Note anything additional you'd like considered as part of the generation."
                      v-model.lazy="application.roleComments"
                      style="resize: none; height: 100%"
                    ></textarea>
                  </div>
                </div>

                <div
                  class="tab-pane fade"
                  :class="activeTab == 'personal' && 'show active'"
                  id="personal"
                >
                  <!-- Personal Details -->
                  <div class="input-group pt-2 px-2">
                    <span class="input-group-text w-25">Name</span>
                    <input type="text" class="form-control" v-model.lazy="user.name" />
                  </div>
                  <div class="input-group pt-2 px-2">
                    <span class="input-group-text w-25">Occupation</span>
                    <input type="text" class="form-control" v-model.lazy="user.occupation" />
                  </div>
                  <div class="input-group pt-2 px-2">
                    <span class="input-group-text w-25">Resume</span>
                    <select v-model="selectedDocumentId" class="form-control">
                      <option v-for="doc in userDocuments" :value="doc.id" :key="doc.id">
                        {{ doc.documentName }}
                      </option>
                    </select>
                  </div>
                </div>

                <div
                  v-if="aiPersonas.length > 0"
                  :class="activeTab == 'ai' && 'show active'"
                  class="tab-pane fade"
                  id="ai"
                >
                  <div class="mx-3">
                    <div class="persona-list overflow-x-auto m-1">
                      <div
                        v-for="(persona, index) in aiPersonas"
                        :key="persona.name"
                        class="persona-item"
                        :class="{ 'selected-persona': selectedPersona === index }"
                        @click="selectPersona(index)"
                      >
                        <div
                          :class="selectedPersona === index && 'bg-primary'"
                          class="rounded-circle border-primary position-relative m-2 persona-container"
                        >
                          <i
                            :class="[persona.icon, selectedPersona === index && 'text-white']"
                            class="persona-icon"
                            style="font-size: 2rem"
                          ></i>
                          <div v-if="selectedPersona === index" class="check-icon-container">
                            <i
                              v-tooltip="'This AI Persona is applied'"
                              class="bi bi-check-circle-fill check-icon"
                            ></i>
                          </div>
                        </div>
                        <span class="text-nowrap">{{ persona.name }}</span>
                      </div>
                      <div class="persona-item" @click="createNewPersona">
                        <div class="rounded-circle position-relative m-2">
                          <i
                            class="bi bi-plus persona-icon persona-container border-dashed"
                            style="font-size: 2rem"
                          ></i>
                        </div>
                        <span class="text-nowrap">Create New</span>
                      </div>
                    </div>
                    <div
                      v-if="aiPersonas[selectedPersona] != null && !isDefaultAiProfileSelected()"
                    >
                      <div class="px-2">
                        <span
                          v-if="aiPersonas[selectedPersona]?.systemProfile"
                          class="pt-3 m-2 d-flex text-muted justify-content-center border rounded-1 border-secondary"
                        >
                          <p class="pe-1">
                            System profiles are not editable. Click "Create New" to create your own
                          </p>
                          <i class="bi bi-lock" v-tooltip="'System profiles are not editable'"></i>
                        </span>
                        <div class="m-2">
                          <span>Profile Name</span>
                          <input
                            type="text"
                            class="form-control"
                            v-model="aiPersonas[selectedPersona].name"
                            placeholder="AI Profile Name"
                            :disabled="aiPersonas[selectedPersona]?.systemProfile"
                            :class="{ 'text-muted': aiPersonas[selectedPersona]?.systemProfile }"
                          />
                        </div>
                        <div>
                          <div v-for="(slider, index) in sliders" :key="index" class="m-2">
                            <div class="d-flex justify-content-between">
                              <label :for="slider.id" class="form-label">{{ slider.label }} </label>
                              <span class="text-secondary">
                                <i>
                                  ({{
                                    slider.adjectives[
                                      Math.round(
                                        (aiPersonas[selectedPersona][slider.model] - slider.min) /
                                          slider.step
                                      )
                                    ]
                                  }})
                                </i>
                              </span>
                              <i v-tooltip="slider.tip" class="bi bi-info-circle-fill"></i>
                            </div>
                            <div>
                              <input
                                v-model="aiPersonas[selectedPersona][slider.model]"
                                :type="slider.type"
                                class="form-range"
                                :min="slider.min"
                                :max="slider.max"
                                :step="slider.step"
                                :id="slider.id"
                                :disabled="aiPersonas[selectedPersona]?.systemProfile"
                                @input="
                                  aiPersonas[selectedPersona][slider.model] = Number(
                                    aiPersonas[selectedPersona][slider.model]
                                  )
                                "
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- Personality Adjectives Selection -->
                      <div class="m-2 p-2">
                        <label class="form-label">Behavioral Qualities</label>
                        <i
                          v-tooltip="
                            'What qualities do you want the AI to demonstrate in responses? (Select up to 5)'
                          "
                          class="bi bi-info-circle-fill m-1"
                        ></i>

                        <div class="selected-adjectives border rounded-top-1 py-1">
                          <span
                            class="text-secondary"
                            v-if="aiPersonas[selectedPersona].personality.length < 1"
                            ><i
                              >Select behavioral qualities you'd like to highlight (up to 5)</i
                            ></span
                          >
                          <span
                            v-for="(adj, index) in aiPersonas[selectedPersona].personality"
                            :key="index"
                            class="badge bg-primary me-1"
                          >
                            {{ adj }}
                            <i
                              style="cursor: pointer"
                              class="bi bi-x-circle"
                              @click="removeAdjective(index)"
                              :disabled="aiPersonas[selectedPersona]?.systemProfile"
                            ></i>
                          </span>
                        </div>
                        <div class="adjectives-list border">
                          <span
                            v-for="(adj, index) in predefinedAdjectives.filter(
                              (adj) => !aiPersonas[selectedPersona].personality.includes(adj)
                            )"
                            :key="index"
                            class="badge bg-secondary me-1"
                            @click="toggleAdjective(adj)"
                            style="cursor: pointer"
                            :disabled="aiPersonas[selectedPersona]?.systemProfile"
                          >
                            {{ adj }}
                          </span>
                        </div>
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            class="form-control rounded-top-0"
                            v-model="newAdjective"
                            @keyup.enter="addAdjective"
                            placeholder="Add a behavioral quality"
                            :disabled="aiPersonas[selectedPersona].systemProfile"
                          />
                          <button
                            class="btn btn-outline-secondary rounded-top-0"
                            @click="addAdjective"
                            :disabled="aiPersonas[selectedPersona].systemProfile"
                          >
                            Add
                          </button>
                        </div>
                        <div class="">
                          <label class="form-label">Response Language</label>
                          <select
                            :disabled="aiPersonas[selectedPersona].systemProfile"
                            v-model="aiPersonas[selectedPersona].language"
                            class="form-select max-height-50 countries-dropdown"
                          >
                            <option value="" disabled>Response Language</option>
                            <option v-for="language in languages" :key="language">
                              {{ language }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div v-else class="text-center mt-4">
                      <i class="bi bi-shuffle display-1 text-primary"></i>
                      <div class="mt-3">
                        <p class="text-muted">
                          By using the default profile, JobBuddy will automatically determine all
                          settings. Keeping the default profile ensures classic JobBuddy responses.
                          For a more tailored and granular approach, try one of the AI personas.
                        </p>
                      </div>
                    </div>

                    <div>
                      <button
                        v-if="!aiPersonas[selectedPersona]?.systemProfile"
                        class="btn btn-outline-danger m-2"
                        @click="deletePersona"
                      >
                        Delete
                      </button>
                      <button
                        v-if="!aiPersonas[selectedPersona].systemProfile && unsavedAIProfileChanges"
                        class="btn btn-outline-primary m-2"
                        @click="savePersona"
                      >
                        Save Changes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer mt-auto">
                <div class="input-group p-2">
                  <select v-model="generationType" class="form-select">
                    <option selected>Choose an output</option>
                    <option :value="ResponseType.CoverLetter">Cover Letter</option>
                    <option :value="ResponseType.CreateResume">Resume</option>
                    <option :value="ResponseType.CVFeedback">Resume Feedback</option>
                    <option :value="ResponseType.CompetencyQuestions">Interview Prep</option>
                  </select>
                  <button
                    :disabled="loading"
                    @click="generate()"
                    class="btn btn-primary"
                    type="button"
                  >
                    Generate Document
                    <div
                      v-show="loading"
                      class="spinner-border spinner-border-sm"
                      role="status"
                    ></div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-7 text-center d-flex mt-3">
          <div class="card h-100 w-100">
            <div
              id="doc-viewer"
              class="row text-center card-body h-100 overflow-hidden pe-0 py-0 my-0"
            >
              <div class="col overflow-auto response ratio text-start">
                <!--  Approximate A4 Paper Ratio -->
                <div v-if="!loading">
                  <div
                    v-if="pdfBuffer"
                    class="pdf-viewer h-100"
                    :style="`width: ${pdfWidth}px; height: ${getPdfHeight()}px`"
                  >
                    <i
                      v-if="isDocumentsCollapsed"
                      class="bi bi-arrows-angle-contract size-toggle"
                      @click="isDocumentsCollapsed = false"
                      v-tooltip="'Contract Document View'"
                    ></i
                    ><i
                      v-else
                      @click="isDocumentsCollapsed = true"
                      class="bi bi-arrows-angle-expand size-toggle"
                      v-tooltip="'Expand Document View'"
                    ></i>
                    <VuePdfEmbed
                      class="custom-pdf-embed"
                      ref="pdfRef"
                      :source="pdfBuffer"
                      :width="pdfWidth"
                      :height="getPdfHeight()"
                      :text-layer="true"
                    >
                    </VuePdfEmbed>
                  </div>
                  <div v-else class="d-flex h-100 align-items-end text-primary-emphasis">
                    <div class="m-3">
                      <i class="bi bi-arrow-left-square-fill pe-2"></i>
                      <span> Click <i>"Generate Document"</i> button to get started </span>
                    </div>
                  </div>
                </div>
                <div v-else class="h-100 d-flex align-items-center justify-content-around">
                  <Particles
                    id="tsparticles"
                    :particlesInit="particlesInit"
                    :options="loadingParticles"
                    class="h-100"
                  />
                  <img
                    loading="lazy"
                    class="mascot-loader z-1"
                    src="../../public/media/jobbuddymascot.webp"
                  />
                  <span class="loading-text">{{ loadingText }}</span>
                </div>
              </div>

              <div
                v-show="!isDocumentsCollapsed"
                id="document-list"
                class="col-md-3 bg mh-100 overflow-y-auto m-0 p-0 pe-2 m-1"
              >
                <table class="table table-striped table-hover text-sm">
                  <thead>
                    <th class="align-text-center">
                      <div class="d-flex justify-content-between">
                        <h6><b>My Documents</b></h6>

                        <i></i>
                      </div>
                    </th>
                  </thead>
                  <tbody>
                    <tr class="document-list-item" v-if="loading && loadingText != 'Loading PDF'">
                      <div
                        class="document-list-item d-flex justify-content-between bg-primary text-white"
                      >
                        Working on it...
                      </div>
                    </tr>
                    <tr
                      v-for="response in responses"
                      :key="response.id"
                      role="button"
                      :disabled="loading"
                      @click="currentResponse = response"
                    >
                      <div
                        class="document-list-item"
                        :class="
                          response?.id == currentResponse?.id && !loading
                            ? 'd-flex justify-content-between bg-primary text-white'
                            : 'd-flex justify-content-between'
                        "
                      >
                        <div scope="row">
                          {{ `${getNameForResponse(response)}` }}
                        </div>
                        <td>
                          <img
                            loading="lazy"
                            v-if="response.isFavourite"
                            src="../../public/media/star.webp"
                            style="height: 20px"
                          />
                        </td>
                      </div>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div
              v-if="currentResponse?.content || currentResponse?.fileId"
              class="card-footer d-flex justify-content-between bg-body-secondary"
            >
              <div class="m-2" role="group">
                <div class="btn-group">
                  <button
                    @click="showFeedbackModal(true)"
                    type="button"
                    class="btn btn-outline-success"
                    v-tooltip="'Submit Response Feedback'"
                  >
                    <i class="bi bi-hand-thumbs-up rounded" />
                  </button>
                  <button
                    @click="showFeedbackModal(false)"
                    type="button"
                    class="btn btn-outline-danger"
                    v-tooltip="'Submit Response Feedback'"
                  >
                    <i class="bi bi-hand-thumbs-down rounded" />
                  </button>
                </div>
                <button
                  v-if="
                    user.subscriptionPlan == SubscriptionPlan.BASIC || user.subscriptionPlan == null
                  "
                  @click="showAdvancedAIModal()"
                  class="btn btn-warning ms-2"
                >
                  Access Premium AI
                  <img
                    loading="lazy"
                    style="height: 25px"
                    src="../../public/media/icons/BrainCircuit.svg"
                  />
                </button>
                <button v-else class="btn disabled ms-2">
                  Advanced AI Enabled
                  <img
                    loading="lazy"
                    style="height: 25px"
                    src="../../public/media/icons/BrainCircuit.svg"
                  />
                </button>
              </div>
              <div class="actions d-flex justify-content-end">
                <button
                  role="button"
                  v-tooltip="'Edit Document'"
                  class="btn bi bi-pencil m-2 bg-primary p-2 rounded"
                  :class="isEditResponseOpen && 'disabled'"
                  style="font-size: 15px; color: white"
                  @click="toggleEditResponse()"
                  :disabled="currentResponse?.schema == null"
                />
                <i
                  role="button"
                  v-tooltip="'Delete Document'"
                  class="bi bi-trash m-2 bg-danger p-2 rounded"
                  style="font-size: 15px; color: white"
                  @click="responseDeleteModal.show()"
                />
                <i
                  role="button"
                  v-tooltip="'Download Document'"
                  class="bi bi-download m-2 bg-success p-2 rounded"
                  style="font-size: 15px; color: white"
                  @click="downloadModal.show()"
                />
                <i
                  role="button"
                  v-tooltip="'Favorite Document'"
                  class="bi bi-star m-2 bg-warning p-2 rounded"
                  style="font-size: 15px; color: white"
                  fill="yellow"
                  @click="favoriteResponse"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row w-100 text-muted">
        <div class="col d-flex justify-content-center">
          <div
            class="m-3 mb-0 help-bar"
            v-tooltip="'Give Feedback'"
            @click="submitIdeaFeedbackModal.show()"
          >
            <i class="ps-2 bi bi-chat-right-text"></i>
          </div>
          <div
            class="m-3 mb-0 help-bar"
            v-tooltip="'Tell Us About A Bug'"
            @click="submitBugFeedbackModal.show()"
          >
            <i class="ps-2 bi bi-bug"></i>
          </div>
          <div
            class="m-3 mb-0 help-bar"
            v-tooltip="'Contact Support'"
            @click="submitSupportFeedbackModal.show()"
          >
            <i class="ps-2 bi-question-circle"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { nextTick, ref, watch } from "vue";
import { onMounted } from "vue";
import { useRoute } from "vue-router";
import CreateOrUpdateApplication from "@models/dtos/CreateOrUpdateApplication";
import User from "@models/User";
import { ResponseFeedback } from "@models/ResponseFeedback";
import { GenerateNewResponse, TemplateOptions } from "@models/dtos/ResponseDtos";
import store from "@/store";
import Modal from "@/components/Modal.vue";
import { ResponseType, Response } from "@models/Response";
import { ResponseApiService } from "@/services/ResponseApiService";
import { ApplicationApiService } from "@/services/ApplicationApiService";
import { loadFull } from "tsparticles";
import particlesLoading from "../particlesLoading";
import VueDocumentEditor from "vue-document-editor";
import VuePdfEmbed from "vue-pdf-embed";
import { toast } from "vue3-toastify";
import { jsPDF } from "jspdf";
import DocumentDto from "@models/dtos/DocumentDto";
import { FileApiService } from "@/services/FilesApiService";
import router from "../router";
import { SubscriptionPlan } from "@models/SystemEnums";
import EditResponseVue from "../components/EditResponse";
import { getCurrentInstance } from "vue";
import { FeedbackApiService } from "../services/FeedbackApiService";
import { countries } from "../components/CountriesList";
import { AIProfileApiService } from "@/services/AiProfileService";
import AIProfile from "@models/AIProfile";
import { Vue } from "vue-class-component";
import { CoverLetterSettingsApiService } from "@/services/CoverLetterSettingsService";
import CoverLetterSettings from "@models/CoverLetterSettings";

const feedback = ref({ feedback: "", userId: "", type: "", metadata: {} });

const loading = ref(false);
const route = useRoute();
const application = ref<CreateOrUpdateApplication>({});
const user = ref(new User(null, "", "", "", "", "", "", ""));
const hideApplicationDetails = ref(false);
const applicationOrUserDetailsChanged = ref(false);
const generationType = ref(0);
const selectedDocumentId = ref(null);
const userDocuments = ref(new Array<DocumentDto>());
const currentResponse = ref<Response | null>(null);
const responses = ref<Response[]>([]);
const advancedAIModal = ref(null);
const errorModal = ref(null);
const responseDeleteModal = ref(null);
const missingCvWarning = ref(null);
const pickAResumeTemplate = ref(null);
const pickACoverLetterTemplate = ref(null);
const feedbackModal = ref(null);
const quotaExceededModal = ref(null);
const responseFeedback = ref(
  new ResponseFeedback(true, currentResponse, null, null, null, null, "")
);
const downloadModal = ref(null);
const downloadType = ref<string>("PDF");
const responseTemperature = ref(0.7);
const responseFrequencyPenalty = ref(0);
const pdfBuffer = ref("");
const particlesInit = async (engine) => {
  await loadFull(engine);
};
const instance = getCurrentInstance();

const loadingParticles = ref(particlesLoading);
const content = ref();
const loadingText = ref("Generating Response");
const pdfUrls = ref(new Map());
const selectedTemplate = ref(TemplateOptions.RESUME_GRADUATE);
const isDocumentsCollapsed = ref(false); // false means the section is expanded by default
const pdfWidth = ref(800); // Set initial width
const isEditResponseOpen = ref(false);
const submitIdeaFeedbackModal = ref(null);
const submitBugFeedbackModal = ref(null);
const submitSupportFeedbackModal = ref(null);
const upgradeModal = ref(null);
const languages = [
  "Mandarin Chinese (普通话)",
  "Spanish (Español)",
  "English (English - UK)",
  "English (English - US)",
  "Hindi (हिन्दी)",
  "Arabic (العربية)",
  "Bengali (বাংলা)",
  "Portuguese (Português)",
  "Russian (Русский)",
  "Japanese (日本語)",
  "Western Punjabi (پنجابی)",
  "Marathi (मराठी)",
  "Telugu (తెలుగు)",
  "Wu Chinese (吴语)",
  "French (Français)",
  "Canadian French (Français canadien)",
  "Turkish (Türkçe)",
  "Korean (한국어)",
];

let doneLoading = false;

onMounted(async () => {
  store.dispatch("toggleLoader", true);
  loadUser();
  await loadApplication();
  await loadResponses();
  await loadDocuments();
  await loadAIProfiles();
  await loadApplicationSettings();
  doneLoading = true;
  content.value = [currentResponse.value?.content];
  updatePdfWidth();
  window.addEventListener("resize", updatePdfWidth);
  store.dispatch("toggleLoader", false);
});
const coverLetterSettings = ref<CoverLetterSettings>({});

const defaultCoverLetterSettings = {
  _id: null,
  userId: "",
  length: 375,
  opening: "Random | Let JobBuddy Decide",
  closing: "Random | Let JobBuddy Decide",
  date: true,
  address: true,
  email: true,
  phone: true,
  city: true,
  template: TemplateOptions.COVER_LETTER_GRADUATE,
};

function resetCoverLetterSettings() {
  coverLetterSettings.value = JSON.parse(JSON.stringify(defaultCoverLetterSettings));
  customClosing.value = "";
  customOpening.value = "";
  selectedOpening.value = coverLetterSettings.value.opening;
  selectedClosing.value = coverLetterSettings.value.closing;
  return;
}

function isDefaultAiProfileSelected() {
  return (
    aiPersonas.value[selectedPersona.value].name == "Default" &&
    aiPersonas.value[selectedPersona.value].systemProfile
  );
}

const coverLetterOptions = ref({
  length: ["Default", "Short | ", "Standard", "Long"],
  opening: ["Random | Let JobBuddy Decide", "Dear Hiring Manager,", "To Whom It May Concern,"],
  closing: [
    "Random | Let JobBuddy Decide",
    "Thank you for considering my application. I look forward to the opportunity to discuss my qualifications further.",
    "I am eager to bring my skills to [Company] and am available for an interview at your earliest convenience.",
    "I am enthusiastic about the possibility of contributing to [Company] and look forward to discussing how my background aligns with your needs.",
    "Thank you for your time and consideration. I hope to discuss my application with you soon.",
  ],
});
// Separate state for the selected option and custom text
const selectedOpening = ref(coverLetterSettings.value.opening);
const selectedClosing = ref(coverLetterSettings.value.closing);
const customOpening = ref("");
const customClosing = ref("");

watch(selectedOpening, (newVal) => {
  if (newVal !== "custom") {
    coverLetterSettings.value.opening = newVal;
  } else {
    coverLetterSettings.value.opening = customOpening.value;
  }
});

watch(customOpening, (newVal) => {
  if (selectedOpening.value === "custom") {
    coverLetterSettings.value.opening = newVal;
  }
});

watch(selectedClosing, (newVal) => {
  if (newVal !== "custom") {
    coverLetterSettings.value.closing = newVal;
  } else {
    coverLetterSettings.value.closing = customClosing.value;
  }
});

watch(customClosing, (newVal) => {
  if (selectedClosing.value === "custom") {
    coverLetterSettings.value.closing = newVal;
  }
});

const activeTab = ref("application");

function setActiveTab(tab: string) {
  activeTab.value = tab;
}

async function loadApplicationSettings() {
  if (localStorage.getItem("selectedPersona")) {
    selectedPersona.value = parseInt(localStorage.getItem("selectedPersona") ?? "0");
  } else {
    selectedPersona.value = 0;
  }
  const result = await CoverLetterSettingsApiService.getCoverLetterSettingsForLoggedInUser();
  if (result.data != null) {
    coverLetterSettings.value = result.data;
    if (
      coverLetterOptions.value.opening.some((x) => x == coverLetterSettings.value.opening) == false
    ) {
      customOpening.value = coverLetterSettings.value.opening;
      selectedOpening.value = "custom";
    } else {
      selectedOpening.value = coverLetterSettings.value.opening;
    }

    if (
      coverLetterOptions.value.closing.some((x) => x == coverLetterSettings.value.closing) == false
    ) {
      customClosing.value = coverLetterSettings.value.closing;
      selectedClosing.value = "custom";
    } else {
      selectedClosing.value = coverLetterSettings.value.closing;
    }
  } else {
    coverLetterSettings.value = JSON.parse(JSON.stringify(defaultCoverLetterSettings));
    selectedOpening.value = coverLetterSettings.value.opening;
    selectedClosing.value = coverLetterSettings.value.closing;
  }
}

watch(
  application,
  () => {
    if (!doneLoading) {
      return;
    }
    applicationOrUserDetailsChanged.value = true;
  },
  { deep: true }
);

watch(currentResponse, async () => {
  applicationOrUserDetailsChanged.value = false;
  responseTemperature.value = currentResponse.value?.temperature ?? 0.7;
  responseFrequencyPenalty.value = currentResponse.value?.frequencyPenalty ?? 0;
  loading.value = false;
  if (currentResponse.value?.fileId) {
    await loadPDF();
  }
  isEditResponseOpen.value = false;
  responseFeedback.value.accuracy = 50;
  responseFeedback.value.formatting = 50;
  responseFeedback.value.communication = 50;
  responseFeedback.value.overallRating = 50;
});

watch(isDocumentsCollapsed, () => {
  //Timeout to allow time for DOM to respond
  setTimeout(() => updatePdfWidth(), 10);
});

const unsavedAIProfileChanges = ref(false);
const aiPersonas = ref(new Array<AIProfile>());
const unsavedCoverLetterSettingsChanges = ref(false);
const newAiPersona = ref({
  _id: null,
  userId: "",
  name: getAiPersonaName(),
  icon: "bi bi-person",
  tone: 3,
  creativity: 3,
  detailLevel: 3,
  personality: [],
  language: "English (English - US)",
  systemGenerated: false,
});

function getAiPersonaName(): string {
  return `Profile ${aiPersonas.value.length + 1}`;
}
const selectedPersona = ref(0);
watch(selectedPersona, () => {
  if (!doneLoading) {
    return;
  }
  localStorage.setItem("selectedPersona", selectedPersona.value.toString());
  //Timeout to allow time for DOM to respond
  setTimeout(() => updatePdfWidth(), 10);
});

watch(
  aiPersonas,
  () => {
    if (!doneLoading) {
      return;
    }
    unsavedAIProfileChanges.value = true;
  },
  { deep: true }
);

watch(
  coverLetterSettings,
  () => {
    if (!doneLoading) {
      return;
    }
    unsavedCoverLetterSettingsChanges.value = true;
  },
  { deep: true }
);

const predefinedAdjectives = ref([
  "Professional",
  "Motivated",
  "Enthusiastic",
  "Ambitious",
  "Creative",
  "Innovative",
  "Unique",
  "Experienced",
  "Trusted",
  "Tenacious",
  "Determined",
  "Focused",
  "Communication",
  "Empathy",
  "Management",
  "Problem-solving",
  "Critical thinking",
  "Leadership",
  "Adaptability",
  "Assertiveness",
  "Conflict resolution",
  "Decision-making",
  "Patience",
  "Motivation",
  "Personal development",
  "Stress management",
  "Teamwork",
  "Accountability",
  "Behavior",
  "Confidence",
  "Emotional intelligence",
  "Goal setting",
  "Honesty",
]);
const newAdjective = ref("");

const sliders = ref([
  {
    id: "toneRange",
    label: "Tone",
    model: "tone",
    type: "range",
    min: 0,
    max: 5,
    step: 1,
    adjectives: ["Casual", "Neutral", "Formal", "Very Formal", "Highly Formal", "Professional"],
    tip: "How formal should the response be?",
  },
  {
    id: "creativityRange",
    label: "Creativity",
    model: "creativity",
    type: "range",
    min: 0.1,
    max: 1,
    step: 0.1,
    adjectives: [
      "Very Conservative",
      "Conservative",
      "Moderate",
      "Balanced",
      "Innovative",
      "Creative",
      "Highly Creative",
      "Avant-garde",
      "Visionary",
      "Revolutionary",
    ],
    tip: "How creative should the response be? (Higher values may result in more unique responses)",
  },
  {
    id: "detailLevelRange",
    label: "Detail Level",
    model: "detailLevel",
    type: "range",
    min: 0,
    max: 4,
    step: 1,
    adjectives: ["Generic", "Some Detail", "Balanced", "Detailed", "Highly Detailed"],
    tip: "How detailed should the response be? (Higher values will draw more details from the job description and resume)",
  },
]);

async function createNewPersona() {
  // Handle the creation of a new persona
  aiPersonas.value.push(newAiPersona.value);
  selectPersona(aiPersonas.value.length - 1);
  aiPersonas.value[aiPersonas.value.length - 1].name = getAiPersonaName();
  await savePersona();
  // await loadAIProfiles();
}

async function loadAIProfiles() {
  try {
    const response = await AIProfileApiService.getAiProfilesForLoggedInUser();
    aiPersonas.value = response.data.aiProfiles;
    unsavedAIProfileChanges.value = false;
  } catch (error) {
    toast.error("Error loading AI Profiles");
  }
}

async function savePersona() {
  try {
    await AIProfileApiService.createOrEditAIProfile(aiPersonas.value[selectedPersona.value]);
    toast.success("AI Profile saved successfully");
    await loadAIProfiles();
    unsavedAIProfileChanges.value = false;
  } catch (error) {
    toast.error("Error loading AI Profiles");
  }
}

async function saveCoverLetterSettings() {
  try {
    await CoverLetterSettingsApiService.createOrEditCoverLetterSettings(coverLetterSettings.value);
    toast.success("Cover letter settings saved successfully");
    await loadApplicationSettings();
    unsavedCoverLetterSettingsChanges.value = false;
  } catch (error) {
    toast.error("Error saving cover letter settings");
  }
}

async function deleteCoverLetterSettings() {
  try {
    if (coverLetterSettings.value._id != null || typeof coverLetterSettings.value._id == "string") {
      await CoverLetterSettingsApiService.deleteCoverLetterSettings(coverLetterSettings.value._id);
    }
    resetCoverLetterSettings();
    unsavedCoverLetterSettingsChanges.value = false;
  } catch (error) {
    toast.error("Error resetting cover letter settings");
  }
}

async function deletePersona() {
  try {
    if (
      typeof aiPersonas.value[selectedPersona.value]._id !== "string" ||
      aiPersonas.value[selectedPersona.value]._id == null
    ) {
      return;
    }
    await AIProfileApiService.deleteAIProfile(aiPersonas.value[selectedPersona.value]._id);
    selectedPersona.value -= 1;
    toast.success("AI Profile deleted successfully");
    await loadAIProfiles();
    unsavedAIProfileChanges.value = false;
  } catch (error) {
    toast.error("Error loading AI Profiles");
  }
}

function addAdjective() {
  const currentPersona = aiPersonas.value[selectedPersona.value];
  if (currentPersona.systemProfile) {
    return;
  }
  if (newAdjective.value && currentPersona.personality.length < 5) {
    currentPersona.personality.push(newAdjective.value);
    newAdjective.value = "";
  }
}

const chevronClass = ref("bi bi-chevron-down");
function toggleChevron() {
  chevronClass.value =
    chevronClass.value === "bi bi-chevron-down" ? "bi bi-chevron-up" : "bi bi-chevron-down";
}

function selectPersona(index) {
  selectedPersona.value = index;
}

function removeAdjective(index) {
  if (aiPersonas.value[selectedPersona.value].systemProfile) {
    return;
  }
  aiPersonas.value[selectedPersona.value].personality.splice(index, 1);
}

function toggleAdjective(adj) {
  const currentPersona = aiPersonas.value[selectedPersona.value];
  if (currentPersona.systemProfile) {
    return;
  }
  const index = currentPersona.personality.indexOf(adj);
  if (index > -1) {
    currentPersona.personality.splice(index, 1);
  } else if (currentPersona.personality.length < 5) {
    currentPersona.personality.push(adj);
  }
}
function updatePdfWidth() {
  const containerWidth = document.getElementById("doc-viewer").offsetWidth;
  // Adjust this logic based on your layout and requirements
  const documentListWidth = document.getElementById("document-list").offsetWidth;

  const documentInset = 50;

  pdfWidth.value = isDocumentsCollapsed.value
    ? containerWidth - documentInset
    : containerWidth - documentListWidth - documentInset;
}

function getPdfHeight(): number {
  return Math.floor(pdfWidth.value * 1.415);
}

async function loadUser() {
  user.value = await store.getters["user/getLoggedInUser"];
}

function toggleEditResponse() {
  if (
    user.value.subscriptionPlan == SubscriptionPlan.BASIC ||
    user.value.subscriptionPlan == null
  ) {
    upgradeModal.value.show();
    return;
  }

  isEditResponseOpen.value = !isEditResponseOpen.value;
}

async function closeEditor(reloadResponse: boolean) {
  if (reloadResponse) {
    await loadResponse(currentResponse.value.id);
  }
  isEditResponseOpen.value = false;
}

function upgradeSubscriptionAction() {
  quotaExceededModal.value.hide();
  upgradeModal.value.hide();
  advancedAIModal.value.hide();
  router.push({ path: "/subscribe", replace: true });
}

function showAdvancedAIModal() {
  advancedAIModal.value.show();
}

async function loadPDF(overwrite = false) {
  const existingUrl = pdfUrls.value.get(currentResponse.value?.fileId);
  if (existingUrl && !overwrite) {
    pdfBuffer.value = existingUrl;
    return;
  }

  loading.value = true;
  loadingText.value = "Loading PDF";

  if (!currentResponse?.value?.fileId) {
    loading.value = false;
    return;
  }

  try {
    const response = await FileApiService.getFileById(currentResponse.value.fileId, true);

    if (response.status !== 200) {
      throw new Error("Failed to fetch the file data.");
    }

    const buffer = response.data;
    const mimetype = response.headers["Content-Type"] || "application/octet-stream"; // Provide a default MIME type in case it's missing.

    // Create a Blob from the buffer data and generate a URL for it.
    const blob = new Blob([buffer], { type: mimetype });
    const url = window.URL.createObjectURL(blob);

    // Save the URL to the map
    savePdfUrlToMap(currentResponse.value.fileId, url);
    pdfBuffer.value = url;
  } catch (err) {
    toast.error("Failed to fetch the file data. Please try again later, or report the issue.", {
      hideProgressBar: true,
      autoClose: 10000,
    });
  } finally {
    loading.value = false;
  }
}

function savePdfUrlToMap(fileId: string, url: string) {
  // Revoke the old URL if it exists
  const existingUrl = pdfUrls.value.get(fileId);
  if (existingUrl) {
    window.URL.revokeObjectURL(existingUrl);
  }
  pdfUrls.value.set(fileId, url);
}

async function submitGeneralFeedback(type: string) {
  if (feedback.value.feedback.length < 1) {
    return;
  }
  feedback.value.userId = user.value._id || "";
  feedback.value.type = type;
  if (feedback.value.feedback == null) {
    feedback.value.feedback = "Empty";
  }

  switch (type) {
    case "Bug":
      (submitBugFeedbackModal.value as any).hide();
      break;
    case "Support":
      (submitSupportFeedbackModal.value as any).hide();
      break;
    case "Idea":
      (submitIdeaFeedbackModal.value as any).hide();
  }
  feedback.value.metadata = {
    responseId: currentResponse?.value.id,
    applicationId: application?.value.id,
    userAgent: window.navigator.userAgent,
    timestamp: new Date().toISOString(),
    screenResolution: `${window.screen.width}x${window.screen.height}`,
    browserWindowSize: `${window.innerWidth}x${window.innerHeight}`,
    currentPageUrl: window.location.href,
    referrerUrl: document.referrer,
    deviceType: /Mobi/i.test(window.navigator.userAgent) ? "Mobile" : "Desktop",
  };

  await FeedbackApiService.submitFeedback(feedback.value);
  feedback.value.feedback = "";
  toast.success("Submission successful!");
}

function generateResumeAction() {
  generate(true);
  pickAResumeTemplate.value.hide();
}

function generateCoverLetterAction() {
  generate(true);
  pickACoverLetterTemplate.value.hide();
}

async function loadApplication() {
  const applicationId = route.params.id as string;
  const response = await ApplicationApiService.getApplicationById(applicationId);
  if (response.status == 200) {
    application.value = await response.data;
    application.value.id = applicationId;
  }
}

async function loadDocuments() {
  const documents = () => store.getters["documents/getDocuments"];

  if (!documents.length) {
    await store.dispatch("documents/loadDocuments");
  }

  userDocuments.value = store.getters["documents/getDocuments"];
  selectedDocumentId.value = userDocuments.value[0]?.id;
}

async function loadResponses() {
  const applicationId = route.params.id as string;
  const response = await ResponseApiService.getApplicationResponses(applicationId);

  if (response.status == 200) {
    responses.value = await response.data;
    responses.value.sort((a, b) => {
      if (a.dateCreated === null && b.dateCreated === null) return 0;
      if (a.dateCreated === null) return 1;
      if (b.dateCreated === null) return -1;
      return new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime();
    });
    currentResponse.value = responses.value.length > 0 ? responses.value[0] : null;
  }
}

async function generate(force?: boolean) {
  if (!force) {
    if (!canGeneratePrompt()) {
      errorModal.value.show();
      return;
    }
    if (selectedDocumentId.value == null) {
      missingCvWarning.value.show();
      return;
    }

    if (generationType.value == ResponseType.CreateResume) {
      selectedTemplate.value = TemplateOptions.RESUME_GRADUATE;
      pickAResumeTemplate.value.show();
      return;
    }
    if (generationType.value == ResponseType.CoverLetter) {
      selectedTemplate.value = coverLetterSettings.value.template;
      pickACoverLetterTemplate.value.show();
      return;
    }
    if (generationType.value == ResponseType.CompetencyQuestions) {
      selectedTemplate.value = TemplateOptions.INTERVIEW_PREP;
    }
    if (generationType.value == ResponseType.CVFeedback) {
      selectedTemplate.value = TemplateOptions.RESUME_FEEDBACK;
    }
  }

  if (generationType.value == ResponseType.CoverLetter) {
    selectedTemplate.value = coverLetterSettings.value.template;
  }

  loading.value = true;
  loadingText.value = "Generating Document";

  const generateResponseDto = new GenerateNewResponse(
    route.params.id as string,
    selectedDocumentId.value ?? "",
    generationType.value,
    responseTemperature.value,
    responseFrequencyPenalty.value,
    selectedTemplate.value,
    coverLetterSettings.value._id == null ? undefined : coverLetterSettings.value,
    isDefaultAiProfileSelected() ? undefined : aiPersonas.value[selectedPersona.value]
  );
  try {
    const response = await ResponseApiService.generateNewResponse(generateResponseDto);
    await loadResponse(response.data.id);
    toast.success("Response generated successfully 🚀");
  } catch (err: any) {
    if (err.response.data.error == "Quota exceeded") {
      toast.error("Quota exceeded, please upgrade your account");
      loading.value = false;
      quotaExceededModal.value.show();
    } else {
      errorModal.value.show();
      toast.error("There was a problem loading your response");
      console.error(err);
    }
  } finally {
    loading.value = false;
  }
}

async function loadResponse(responseId: string) {
  const response = await ResponseApiService.getResponseById(responseId);
  if (response.status == 200) {
    if (!responses.value.some((r) => r.id == responseId)) {
      responses.value.unshift(response.data);
    }
    currentResponse.value = response.data;
    if (currentResponse.value?.fileId) {
      await loadPDF(true);
    }
  }
}

async function saveResponse(shouldCloseEditor: boolean) {
  if (currentResponse.value == null) {
    return;
  }
  const res = await ResponseApiService.editResponseContent(currentResponse.value);
  currentResponse.value = res.data;
  const existingItemIndex = responses.value.findIndex((r) => r?.id == res.data.id);
  if (existingItemIndex > -1) {
    responses.value[existingItemIndex] = res.data;
  }
  if (shouldCloseEditor) {
    await closeEditor(true);
  }
}

// async function loadGeneratedDocuments() {}

async function deleteResponse() {
  const response = await ResponseApiService.delete(currentResponse.value.id);
  if (response.status == 200) {
    responses.value = responses.value.filter((res) => res.id != currentResponse.value.id);
    if (currentResponse.value.fileId) {
      if (pdfUrls.value.has(currentResponse.value.fileId)) {
        const objectUrl = pdfUrls.value.get(currentResponse.value.fileId);
        window.URL.revokeObjectURL(objectUrl);
        if (pdfBuffer.value == objectUrl) {
          pdfBuffer.value = "";
        }
      }
      pdfUrls.value.delete(currentResponse.value.fileId);
    }
    currentResponse.value = responses.value[0];

    toast.success("Response deleted successfully");
  }
  responseDeleteModal.value.hide();
}

async function favoriteResponse() {
  const response = await ResponseApiService.toggleFavoriteResponse(currentResponse.value.id);
  if (response.status == 200) {
    currentResponse.value.isFavourite = !currentResponse.value.isFavourite;
  }
}

async function downloadResponse() {
  if (isEditResponseOpen.value) {
    await saveResponse(false);
  }
  try {
    if (downloadType.value == "PDF") {
      await downloadPDFResponse();
      toast.success("PDF created successfully");
    } else {
      await downloadDocResponse();
      toast.success("Document created successfully");
    }
  } catch (err) {
    toast.error("Unable to export document");
  } finally {
    downloadModal.value.hide();
  }
}

async function downloadPDFResponse() {
  if (currentResponse.value?.fileId) {
    const a = document.createElement("a");
    a.href = pdfBuffer.value;
    a.download = user.value.name + ".pdf";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    // Cleanup
    window.URL.revokeObjectURL(pdfBuffer.value);
  }
  if (!currentResponse?.value?.content.length) {
    return;
  }

  const pdf = new jsPDF("p", "pt", "a4");
  const pageDivs = document.getElementsByClassName("page");
  let yPos = 10;

  for (let i = 0; i < pageDivs.length; i++) {
    const page = pageDivs[i].cloneNode(true) as HTMLElement;

    page.removeAttribute("style");
    page.style.width = "451pt";
    page.style.height = "698pt";
    page.style.marginLeft = "72pt";
    page.style.marginRight = "72pt";
    page.style.marginTop = "72pt";
    page.style.marginBottom = "72pt";
    page.style.fontFamily = "Times New Roman, Times, serif";
    page.style.fontSize = "11.5pt";

    await pdf.html(page, {
      html2canvas: { scale: 0.75, scrollX: 0, scrollY: 0, windowWidth: 595, windowHeight: 842 },
      filename: "Document",
      x: 10,
      y: yPos,
    });

    // Only increment the y position if this is not the last page
    if (i < pageDivs.length - 1) {
      yPos += page.clientHeight + 842;
    }
  }

  const filename = `${getResponseNameFromEnum(currentResponse.value.type)}.pdf`;

  //For some reason there's always 1 additional blank page. So it must be deleted
  const numberOfPages = pdf.getNumberOfPages();
  pdf.deletePage(numberOfPages);

  pdf.save(filename);
}

async function updateSchema(updateObject: any) {
  // Recursive function to process each path and value
  function processUpdate(schemaRef, updatePath, value) {
    updatePath.forEach((element, index) => {
      const isLastElement = index === updatePath.length - 1;
      if (isLastElement) {
        // Set the value at the final destination
        schemaRef[element] = value;
      } else {
        // Prepare the schema reference for the next level
        if (!schemaRef[element] || typeof schemaRef[element] !== "object") {
          schemaRef[element] = isNaN(updatePath[index + 1]) ? {} : []; // Use an array if the next key is numeric
        }
        schemaRef = schemaRef[element];
      }
    });
  }

  // Function to handle each update
  function handleUpdate(update) {
    if (update.path && update.value !== undefined) {
      processUpdate(currentResponse.value.schema, update.path, update.value);
    } else if (update.path) {
      // If the current level contains another nested path array, process it recursively
      update.path.forEach((nestedUpdate) => {
        handleUpdate(nestedUpdate);
      });
    }
  }

  // Start processing the provided updateObject
  handleUpdate(updateObject);

  try {
    await getPreview(); // Ensure this function is properly implemented
  } catch (error) {
    console.error("Failed to process the updated schema:", error);
  }
}

async function deleteFromSchema(path) {
  // Navigate through the schema to find the array and remove the specified element
  function removeFromSchema(schemaRef, pathArray) {
    for (let i = 0; i < pathArray.length; i++) {
      const element = pathArray[i];
      const isLastElement = i === pathArray.length - 1;

      if (isLastElement) {
        // Last element in the path, so remove the item from the array
        if (Array.isArray(schemaRef) && schemaRef.length > element) {
          schemaRef.splice(element, 1);
        } else {
          console.error("Path does not lead to a valid array index.");
        }
      } else {
        // Not the last element, so update the schemaRef to point to the next level in the schema
        schemaRef = schemaRef[element];
      }
    }
  }

  // Start processing the deletion from the root of the schema
  removeFromSchema(currentResponse.value.schema, path);

  try {
    // After deletion, call a function to finalize the process and update the UI or backend as needed
    await getPreview();
    instance?.proxy?.$forceUpdate();

    // Adjust according to how you handle schema updates in your app
  } catch (error) {
    console.error("Failed to delete from the schema:", error);
  }
}

// async function updateResponse() {
//   store.dispatch("toggleLoader", true);
//   const res = await ResponseApiService.editResponseContent(currentResponse.value);
//   currentResponse.value = res.data;
//   await loadPDF();
//   currentResponse.value = res.data;
//   // hasSaved.value = true;
//   store.dispatch("toggleLoader", false);
// }

async function getPreview() {
  try {
    if (currentResponse.value == null) {
      return;
    }
    const res = await FileApiService.getPDFPreview(currentResponse.value);

    if (res.status !== 200) {
      loading.value = false;
      toast.error("Failed to fetch the file data.");
      return;
    }

    loadPDFPreview(res.data);
    loading.value = false;
  } catch (err) {
    toast.error("Failed to fetch the file data. Please try again later, or report the issue.", {
      hideProgressBar: true,
      autoClose: 10000,
    });
    loading.value = false;
  }
}

// async function loadPDF() {
//   if (!response.value?.fileId) {
//     return;
//   }
//   loading.value = true;
//   loadingText.value = "Loading PDF";

//   try {
//     const res = await FileApiService.getFileById(response.value.fileId, true);

//     if (res.status !== 200) {
//       loading.value = false;
//       toast.error("Failed to fetch the file data.");
//       return;
//     }

//     loadPDFPreview(res.data);
//     loading.value = false;
//   } catch (err) {
//     toast.error("Failed to fetch the file data. Please try again later, or report the issue.", {
//       hideProgressBar: true,
//       autoClose: 10000,
//     });
//     loading.value = false;
//   }
// }

function loadPDFPreview(buffer: Buffer): void {
  const mimetype = "application/pdf";
  const blob = new Blob([buffer], { type: mimetype });
  const url = window.URL.createObjectURL(blob);
  pdfBuffer.value = url;
}

// Old method of downloading a docx file
async function downloadDocResponse() {
  if (!currentResponse.value?.content.length && !currentResponse.value?.fileId) {
    return;
  }

  let url = "";
  let filename = "";
  if (currentResponse?.value?.fileId) {
    const result = await FileApiService.getFileById(currentResponse.value.fileId, false);
    if (result.status != 200) {
      toast.error("There has been a problem downloading this document");
      return;
    }
    const blob = new Blob([result.data], { type: "application/msword" });
    url = window.URL.createObjectURL(blob);
    filename = `${getResponseNameFromEnum(currentResponse.value.type)}.docx`;
  } else {
    const preHtml =
      "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
    const postHtml = "</body></html>";
    const htmlContentObj = document.createElement("div");
    htmlContentObj.innerHTML = currentResponse.value.content;
    const html = preHtml + htmlContentObj.innerHTML + postHtml;
    url = "data:application/vnd.ms-word;charset=utf-8," + encodeURIComponent(html);
    filename = `${getResponseNameFromEnum(currentResponse.value.type)}.doc`;
  }
  // Create download link element
  const downloadLink = document.createElement("a");

  document.body.appendChild(downloadLink);

  // Create a link to the file
  downloadLink.href = url;

  // Setting the file name
  downloadLink.download = filename;

  //triggering the function
  downloadLink.click();

  document.body.removeChild(downloadLink);
}

function getResponseNameFromEnum(type: ResponseType) {
  switch (type) {
    case ResponseType.CVFeedback:
      return "Resume Feedback";
    case ResponseType.CoverLetter:
      return "Cover Letter";
    case ResponseType.CompetencyQuestions:
      return "Interview Prep";
    case ResponseType.CreateResume:
      return "Resume";
  }
}

function getNameForResponse(response: Response) {
  const index = responses.value.filter((r) => r.type == response.type).indexOf(response);

  // Add a condition to only return the index if it's over 0
  return index > 0
    ? `${getResponseNameFromEnum(response.type)} (${index})`
    : getResponseNameFromEnum(response.type);
}

function canGeneratePrompt() {
  if (generationType.value == null) {
    return false;
  }
  if (generationType.value == ResponseType.CoverLetter) {
    return application.value.jobDescription;
  } else if (generationType.value == ResponseType.CVFeedback) {
    return application.value.jobDescription?.length > 0;
  } else if (generationType.value == ResponseType.CompetencyQuestions) {
    return application.value.jobDescription?.length > 0;
  } else if (generationType.value == ResponseType.CreateResume) {
    return true;
  }
}

async function submitFeedback() {
  if (!currentResponse.value) {
    return;
  }
  responseFeedback.value.response = currentResponse.value;
  await ResponseApiService.postResponseFeedback(responseFeedback.value);
  feedbackModal.value.hide();
  toast.success("Feedback submitted successfully");
}

function handleCvWarn() {
  missingCvWarning.value.hide();
  generate(true);
}

async function updateApplication() {
  const result = await ApplicationApiService.createOrUpdateApplication(application.value);
  if (result.status != 200) {
    toast.error("There has been a problem saving this application");
  } else {
    toast.success("Application updated successfully");
  }
  applicationOrUserDetailsChanged.value = false;
}

function showFeedbackModal(positiveFeedback: boolean) {
  responseFeedback.value.positiveFeedback = positiveFeedback;
  feedbackModal.value.show();
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  overflow-y: hidden;
}
.row-height {
  height: 100vh;
}
.image-option {
  position: relative;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  padding: 0; /* Reset padding */
  display: inline-block; /* Makes the label only as wide as its contents */
}

.image-option img {
  max-width: 100%;
  display: block;
  margin: 0; /* Reset margin */
}

.image-option span {
  display: block; /* Making the span a block element so it fits the width of the image */
  text-align: center; /* Center the text if desired */
  margin: 0; /* Reset margin */
}

.image-option input[type="radio"]:checked + img {
  border-color: blue;
}
.mascot-loader {
  height: 100px;
  animation: rotation 2s infinite linear;
  position: absolute;
}

.loading-text {
  z-index: 1;
  position: absolute;
  padding-top: 130px;
}
.loading-text:after {
  display: inline-block;
  animation: dotty steps(1, end) 1s infinite;
  content: "";
}
@keyframes dotty {
  0% {
    content: "";
  }
  25% {
    content: ".";
  }
  50% {
    content: "..";
  }
  75% {
    content: "...";
  }
  100% {
    content: "";
  }
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.pdf-viewer {
  padding: 5px 10px;
}
</style>
<style>
.vue-pdf-embed > body {
  margin: 0;
  padding: 0;
}

.vue-pdf-embed > div {
  margin-bottom: 8px;
  margin-left: 8px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

canvas {
  box-shadow: 0 0 15px rgba(60, 64, 67, 0.15);
}
.app-header {
  padding: 16px;
}

.app-content {
}

.vue-document-editor {
}

.image-option {
  position: relative; /* this is important for the input positioning */
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.image-option:hover {
  transform: scale(1.05); /* slightly enlarges the element on hover */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* adds a shadow/glow effect */
}

.image-option img {
  max-width: 100%; /* ensure the image doesn't overflow */
  display: block; /* remove any extra spacing */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 0 1px rgba(0, 0, 0, 0.2);
  border: 1px solid #eee; /* This gives a slight edge to the paper */
  position: relative;
  z-index: 1; /* Stack the image above pseudo-elements */
}

.image-option::before,
.image-option::after {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  z-index: 0; /* Stack them below the main image */
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.image-option::after {
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
}

/* Hide the radio input */
.image-option input[type="radio"] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none; /* ensures clicks go through the input to the label beneath */
}

/* Highlight the selected template */
.image-option input[type="radio"]:checked + img {
  border: 3px solid blue; /* Change 'blue' to any desired highlight color */
  box-shadow: 0 0 15px rgba(0, 0, 255, 0.5); /* Additional shadow for selected image */
}

.document-list-item {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  margin-bottom: 3px;
}

.size-toggle {
  position: sticky;
  top: 50px; /* Adjust this value as needed to position the buttons within the div */
  left: 100%;
  z-index: 100;
  color: grey;
  cursor: pointer;
}

.size-toggle:hover {
  color: blue;
}

.help-bar {
  cursor: pointer;
}
.persona-container {
  width: 75px;
  height: 75px;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 2px solid #0d6efd;
  border-radius: 50%;
}

.check-icon-container {
  position: absolute;
  right: 1px;
  bottom: 1px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white; /* Add background color if needed */
  border-radius: 50%; /* To make it circular */
}

.check-icon {
  font-size: 1.6rem;
  color: #198754; /* Set the check icon color */
}

.persona-icon {
  font-size: 50px;
  color: #007bff;
  transition: color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease;
}

.persona-icon:hover {
  color: #0056b3;
  transform: scale(1.1);
}

.nav-item {
  padding-right: 1px;
}

.border-dashed {
  border-style: dashed !important;
}

.persona-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  gap: 5px; /* Reduced gap */
  overflow-x: auto; /* Enable horizontal scroll */
}

.persona-item {
  text-align: center;
}

.adjectives-list {
  max-height: 100px;
  overflow: auto;
}
</style>
