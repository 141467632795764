export enum SubscriptionControlledActions {
  CREATE_RESPONSE = 'createResponse',
  CREATE_APPLICATION = 'createApplication',
}

export enum SubscriptionPlan {
  BASIC = 'basic',
  JOB_SEEKER = 'jobSeeker',
  BUSINESS = 'business',
}

export enum ApplicationStatus {
  TO_APPLY,
  APPLIED,
  INTERVIEWING,
  REJECTED,
  OFFERED,
}
