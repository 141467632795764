import { type TemplateOptions } from './dtos/ResponseDtos'

export enum ResponseType {
  CoverLetter,
  CVFeedback,
  CompetencyQuestions,
  CreateResume
}

export class Response {
  applicationId: string
  userId: string
  type: ResponseType
  content: string
  isFavourite: boolean
  documentId: string
  temperature: number
  frequencyPenalty: number
  dateCreated: Date
  template: TemplateOptions | null
  fileId: string | null
  schema: object | null
  isDeleted?: boolean

  constructor (applicationId: string, userId: string, type: ResponseType, content: string, isFavourite: boolean, documentId: string, temperature: number, frequencyPenalty: number, dateCreated: Date, template: TemplateOptions | null, fileId: string | null, schema: object | null, isDeleted?: boolean) {
    this.applicationId = applicationId
    this.userId = userId
    this.type = type
    this.content = content
    this.isFavourite = isFavourite
    this.documentId = documentId
    this.temperature = temperature
    this.frequencyPenalty = frequencyPenalty
    this.dateCreated = dateCreated
    this.template = template
    this.fileId = fileId
    this.schema = schema
    this.isDeleted = isDeleted
  }
}
