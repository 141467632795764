<template>
  <div class="container mt-5">
    <div class="text-center my-5">
      <h2 class="display-5 fw-bold text-secondary">Choose Your Subscription</h2>
      <p class="lead text-muted">Select a plan that best fits your needs</p>
    </div>

    <div class="d-flex flex-column flex-md-row justify-content-around">
      <!-- Basic -->
      <div class="card mb-4 mb-md-0 shadow-lg mx-2 flex-fill d-flex flex-column rounded">
        <div class="card-header bg-gradient-blue text-white text-uppercase">
          <h4 class="my-0 fw-bold">Basic</h4>
        </div>
        <div class="card-body d-flex flex-column">
          <div class="py-3 border-bottom mb-4">
            <h1 class="card-title pricing-card-title text-center text-primary">
              $0<small class="text-muted"> / mo</small>
            </h1>
          </div>
          <ul class="list-unstyled mt-3 mb-4 flex-grow-1 text-secondary">
            <li>Create up to 5 job applications per month</li>
            <li>Up to 5 responses* per month</li>
            <li>Access to Basic Models</li>
            <li><b>No credit card required</b></li>
          </ul>
          <router-link :to="{ name: 'Dashboard' }" class="text-decoration-none text-dark">
            <button
              class="w-100 btn btn-outline-primary btn-lg mt-auto rounded-pill d-flex align-items-center justify-content-center"
            >
              <h5 class="m-0">Sign up for free</h5>
            </button>
          </router-link>
        </div>
      </div>

      <!-- JobSeeker -->
      <div class="card mb-4 mb-md-0 shadow-lg mx-2 flex-fill d-flex flex-column rounded">
        <div class="card-header bg-gradient-silver text-white text-uppercase">
          <h4 class="my-0 fw-bold">JobSeeker</h4>
        </div>
        <div class="card-body d-flex flex-column">
          <div class="py-3 border-bottom mb-4">
            <h1 class="card-title pricing-card-title text-center text-muted">
              $4.99<small class="text-muted"> / mo</small>
            </h1>
          </div>
          <ul class="list-unstyled mt-3 mb-4 flex-grow-1 text-secondary">
            <li>Create up to 75 applications per month</li>
            <li>Up to 75 responses* per month</li>
            <li>Priority Customer Support</li>
            <li>Get New Features Early</li>
            <li>
              <i><b>Our Most Advanced AI</b></i>
            </li>
          </ul>
          <router-link
            :to="{
              name: 'Payment',
              params: {
                plan: 'jobseeker',
                priceId: jobSeekerPriceId,
              },
            }"
            class="text-decoration-none text-dark"
          >
            <button
              class="w-100 btn btn-outline-secondary btn-lg mt-auto rounded-pill d-flex align-items-center justify-content-center"
            >
              <h5 class="m-0">Get Started</h5>
            </button>
          </router-link>
        </div>
      </div>

      <!-- Business -->
      <div class="card mb-4 mb-md-0 shadow-lg mx-2 flex-fill d-flex flex-column rounded">
        <div class="card-header bg-gradient-gold text-white text-uppercase">
          <h4 class="my-0 fw-bold">Business</h4>
        </div>
        <div class="card-body d-flex flex-column">
          <div class="py-3 border-bottom mb-4">
            <h1 class="card-title pricing-card-title text-center text-warning">
              $24.99<small class="text-muted"> / mo</small>
            </h1>
          </div>
          <ul class="list-unstyled mt-3 mb-4 flex-grow-1 text-secondary">
            <li>Create up to 300 applications per month</li>
            <li>Access up to 300 responses* per month</li>
            <li>Priority Customer Support 24/7</li>
            <li><i>Advanced Business Tools</i></li>
            <li>
              <i><b>Our Most Advanced AI</b></i>
            </li>
          </ul>
          <router-link
            :to="{
              name: 'Payment',
              params: {
                plan: 'business',
                priceId: businessPriceId,
              },
            }"
            class="text-decoration-none text-dark"
          >
            <button class="w-100 btn btn-outline-warning btn-lg mt-auto rounded-pill">
              <h5 class="m-0">Get Started</h5>
            </button>
          </router-link>
        </div>
      </div>
    </div>
    <br />
    <br />
    <span class="fw-lighter"
      >*Each generated cover letter, resume, resume feedback or interview prep document constitutes
      1 response</span
    >
  </div>
</template>

<script>
export default {
  name: "Subscribe",
  data() {
    return {
      jobSeekerPriceId: process.env.VUE_APP_STRIPE_PRICE_ID_JOBSEEKER ?? "",
      businessPriceId: process.env.VUE_APP_STRIPE_PRICE_ID_BUSINESS ?? "",
    };
  },
};
</script>

<style scoped>
.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  font-family: "Ubuntu", sans-serif;
}
.bg-gradient-blue {
  background: linear-gradient(to right, #375e97, #ace5ff);
}
.bg-gradient-silver {
  background: linear-gradient(to right, #bfc0c0, #f8f8f8);
}
.bg-gradient-gold {
  background: linear-gradient(to right, #d4af37, #ffd700);
}
.pricing-card-title {
  font-size: 3rem;
  margin: 0.75rem 0;
  font-weight: bold;
  color: #333;
  font-family: "Ubuntu", sans-serif;
}
.card {
  border-radius: 20px;
  font-family: "Montserrat", sans-serif;
}
.btn {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
}
</style>
