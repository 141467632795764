<template>
  <div class="container w-100 h-100">
    <div class="task-board-view h-100">
      <h1 class="text-uppercase text-muted fw-light m-3">
        Application Tracker <span class="rocket">✅</span>
      </h1>

      <h6 class="mb-4">Drag and drop your applications to keep track of your progress</h6>
      <div class="task-board">
        <div class="task-columns w-100 h-100" group="task-columns">
          <div
            class="board-column w-100 h-100"
            v-for="(column, columnIndex) in taskColumns"
            :key="columnIndex"
          >
            <div class="column-header">{{ column.title }}</div>
            <VueDraggableNext
              class="board-card-list h-100"
              v-model="column.cards"
              group="task-cards"
              @end="updateApplicationStatus"
              :data-column-index="columnIndex"
            >
              <div class="board-card" v-for="(card, cardIndex) in column.cards" :key="cardIndex">
                <div class="card-header">
                  <b>{{ card.companyName }}</b>
                </div>
                <div class="card-body"></div>
                <div>
                  <router-link
                    :to="{
                      name: 'MyApplication',
                      params: { id: card.applicationId },
                    }"
                    style="text-decoration: none; /* Remove underline */ color: inherit"
                  >
                    <p class="p-3">{{ card.title }}</p>
                  </router-link>
                </div>
              </div>
            </VueDraggableNext>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ApplicationApiService } from "@/services/ApplicationApiService";
import { ApplicationStatus } from "@models/SystemEnums";
import { UserApplicationListItem } from "@models/dtos/UserApplicationList";
import { onMounted, ref } from "vue";
import { VueDraggableNext } from "vue-draggable-next";
import store from "../store";

interface Card {
  title: string;
  companyName: string;
  applicationId: string;
}

interface Column {
  title: string;
  cards: Card[];
  applicationStatus: ApplicationStatus;
}

const taskColumns = ref<Column[]>([
  {
    title: "To Apply",
    cards: [],
    applicationStatus: ApplicationStatus.TO_APPLY,
  },
  {
    title: "Applied",
    cards: [],
    applicationStatus: ApplicationStatus.APPLIED,
  },
  {
    title: "Interviewing",
    cards: [],
    applicationStatus: ApplicationStatus.INTERVIEWING,
  },
  {
    title: "Rejected",
    cards: [],
    applicationStatus: ApplicationStatus.REJECTED,
  },
  {
    title: "Offer",
    cards: [],
    applicationStatus: ApplicationStatus.OFFERED,
  },
]);

const applicationsList = ref([] as UserApplicationListItem[]);

onMounted(async () => {
  store.dispatch("toggleLoader", true);
  await loadUserApplications();
  store.dispatch("toggleLoader", false);
});

async function loadUserApplications() {
  // Fetch applications
  const response = await ApplicationApiService.getUserApplicationList();
  if (response.status !== 200) {
    console.error("Problem loading user applications");
    return;
  }

  applicationsList.value = response.data.applications;

  // Populate and sort the cards for each column
  taskColumns.value.forEach((column) => {
    const filteredApps = applicationsList.value.filter(
      (app) => app?.applicationStatus === column.applicationStatus
    );

    if (column.applicationStatus === ApplicationStatus.TO_APPLY) {
      const unassignedApps = applicationsList.value.filter((app) => app?.applicationStatus == null);
      unassignedApps.forEach((app) => {
        filteredApps.push(app);
      });
    }

    // Sort the applications by their index property
    filteredApps.sort((a, b) => a.index - b.index);

    // Map the sorted applications to cards
    column.cards = filteredApps.map((app) => ({
      title: app.roleTitle,
      companyName: app.companyName,
      applicationId: app.applicationId,
    }));
  });
}

async function updateApplicationStatus(event: any) {
  const newColumnIndex = parseInt(event.to.getAttribute("data-column-index"));
  const oldColumnIndex = parseInt(event.from.getAttribute("data-column-index"));
  const newColumn = taskColumns.value[newColumnIndex];
  const columnStatus = newColumn.applicationStatus;
  const movedCard = newColumn.cards[event.newIndex];

  if (event.newIndex === event.oldIndex && newColumnIndex === oldColumnIndex) return;
  try {
    const response = await ApplicationApiService.updateApplicationStatus(
      movedCard.applicationId,
      columnStatus,
      event.newIndex
    );

    if (response.status !== 200) {
      throw new Error("Problem updating application status");
    }

    if (event.oldIndex !== event.newIndex) {
      // If needed, update cards below the one moved
      for (let index = event.newIndex; index < newColumn.cards.length; index++) {
        const card = newColumn.cards[index];
        if (card === movedCard) continue;

        const response = await ApplicationApiService.updateApplicationStatus(
          card.applicationId,
          columnStatus,
          index
        );

        if (response.status !== 200) {
          throw new Error("Problem updating application status for other cards");
        }
      }
    }
  } catch (error) {
    console.error(error);
    // Handle rollback or UI update
  }
}
</script>

<style scoped>
.task-board-view {
  padding: 30px;
  min-height: 100%;
}

.rocket {
  animation: shake 1.5s infinite;
}

@keyframes shake {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.task-board {
  display: flex;
  overflow-x: auto;
}

.task-columns {
  display: flex;
}

.board-column {
  flex: 1;
  margin-right: 10px;
  padding: 15px;
  border: 1px solid transparent;
  border-radius: 10px;
  background: linear-gradient(90deg, #ffffff, #f9f9f9);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  min-height: 500px;
}

.column-header {
  font-weight: bold;
  font-size: 1.2em;
  margin-bottom: 12px;
  color: black;
}

.board-card-list {
  min-height: 500px;
}

.board-card {
  margin-top: 15px;
  border: 1px solid rgba(128, 128, 128, 0.187);
  border-radius: 10px;
  background: linear-gradient(90deg, #ffffff, #f9f9f9);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  color: black;
  overflow: hidden;
}

.board-card:hover {
  filter: drop-shadow(0 0 0.75rem #9c8ff48b);
}

.card-header {
  padding: 5px;
  border-bottom: 1px solid #ddd;
  background-color: #ffffff;
  font-size: 1em;
  cursor: grab;
  color: rgba(45, 44, 44, 0.843);
  border-radius: 10px;
}
</style>
