import CreateOrUpdateApplication from "@models/dtos/CreateOrUpdateApplication";
import Application from "@models/Application";
import { AxiosResponse } from "axios";
import { instance } from "./apiProvider/ApiProvider";
import { ApplicationStatus } from "@models/SystemEnums";
export class ApplicationApiService {
  public static async createOrUpdateApplication(
    application: CreateOrUpdateApplication
  ): Promise<AxiosResponse<{ id: number } | undefined>> {
    const axios = await instance();
    const response = await axios.post("/createOrUpdateApplication", application);

    if (response.status == 200) {
      return response;
    } else {
      console.error(response);
      return response;
    }
  }
  public static async delete(applicationId: string): Promise<AxiosResponse> {
    const axios = await instance();
    const response = await axios.delete("/deleteApplication", { data: { id: applicationId } });
    if (response.status == 200) {
      return response;
    } else {
      console.error(response);
      return response;
    }
  }
  public static async getUserApplicationList() {
    const axios = await instance();
    const response = await axios.get("/getUserApplicationList");
    if (response.status == 200) {
      return response;
    } else {
      console.error(response);
      return response;
    }
  }

  public static async getApplicationById(
    applicationId: string
  ): Promise<AxiosResponse<Application>> {
    const axios = await instance();
    const response = await axios.get("/getApplicationById", {
      params: { id: applicationId },
    });
    if (response.status == 200) {
      return response;
    } else {
      console.error(response);
      return response;
    }
  }

  public static async updateApplicationStatus(
    applicationId: string,
    status: ApplicationStatus,
    index: number
  ) {
    const axios = await instance();
    const response = await axios.put("/updateApplicationStatus", {
      id: applicationId,
      status: status,
      index: index,
    });
    if (response.status == 200) {
      return response;
    } else {
      console.error(response);
      return response;
    }
  }
}
