import CoverLetterSettings from "@models/CoverLetterSettings";
import { AxiosResponse } from "axios";
import { instance } from "./apiProvider/ApiProvider";

export class CoverLetterSettingsApiService {
  public static async createOrEditCoverLetterSettings(
    coverLetterSettings: CoverLetterSettings
  ): Promise<AxiosResponse<CoverLetterSettings>> {
    const axios = await instance();
    const response = await axios.post("/createOrEditCoverLetterSettings", coverLetterSettings);
    if (response.status == 200) {
      return response;
    } else {
      console.error(response);
      return response;
    }
  }

  public static async getCoverLetterSettingsForLoggedInUser(): Promise<
    AxiosResponse<CoverLetterSettings>
  > {
    const axios = await instance();
    const response = await axios.get("/getCoverLetterSettingsForLoggedInUser");
    if (response.status == 200) {
      return response;
    } else {
      console.error(response);
      return response;
    }
  }

  public static async deleteCoverLetterSettings(
    coverLetterSettingsId: string
  ): Promise<AxiosResponse> {
    const axios = await instance();
    const response = await axios.delete("/deleteCoverLetterSettings", {
      params: {
        id: coverLetterSettingsId,
      },
    });
    if (response.status == 200) {
      return response;
    } else {
      console.error(response);
      return response;
    }
  }
}
