<template>
  <div class="container">
    <div class="cover-letter-browser">
      <h2 class="fw-bold mb-2 display-8">
        Resume Examples
        <span class="text-primary"><i>That Got Applicants Hired</i>💡</span>
      </h2>
      <p class="fw-light px-5 m-3">
        Here are some featured resume examples that got applicants hired using JobBuddy's AI resume
        builder tool. Click on the resume examples below to see what made them successful.
      </p>
      <div class="row w-100">
        <div
          v-for="example in resumeExamples"
          :key="example.Role"
          class="col-sm-3 col-6 my-3 cover-letter-item"
        >
          <router-link
            :to="{
              name: 'ResumeDetail',
              params: { role: example.Role.toLowerCase().replace(/ /g, '-') },
            }"
            class="text-dark text-decoration-none"
          >
            <div class="h-100 w-100">
              <img
                :src="getImageUrl(example.ExampleImgSrc)"
                :alt="`Resume example for ${example.Role}`"
                class="cover-letter-image shadow"
                loading="lazy"
              />

              <p class="py-2 text-secondary">{{ example.Role }} Resume</p>
            </div>
          </router-link>
        </div>
        <div class="m-1 rounded bg-body-tertiary">
          <div class="m-3 text-body p-3">
            <h3 class="h5 mb-2 text-body">
              <b
                >Create Your Resume in <u><i>Seconds</i></u> and <u><i>for Free</i></u> with
                Job<span class="text-primary">Buddy</span></b
              >
              💡
            </h3>
            <p>
              Each of these resumes was carefully researched, written and formatted using the most
              powerful resume generator in the world. Sign up for free to create your resume. It's
              easy, fast and free.
            </p>

            <div class="row mt-5 w-100">
              <router-link :to="{ name: 'Dashboard' }">
                <button class="btn btn-primary btn-lg w-100">Create Resume</button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { resumeExamples } from "./blogs/ResumeExamples";
import { useHead } from "@unhead/vue";

export default {
  name: "CoverLetterBrowser",
  methods: {
    getImageUrl(path) {
      return require(`../../public/media/resumeExamples/${path}`);
    },
  },
  setup() {
    useHead({
      title: "Successful Resume Examples - JobBuddy",
      meta: [
        {
          name: "description",
          content:
            "Discover resume examples across various industries that have helped JobBuddy users get hired. From Administrative Assistants to Project Managers, see what makes these AI-generated resumes effective. Enhance your job application today by leveraging our powerful resume builder tool.",
        },
        {
          name: "keywords",
          content:
            "successful resume examples, JobBuddy resume builder, resume templates, industry-specific resumes, AI resume optimization",
        },
      ],
    });
  },
  data() {
    return {
      resumeExamples,
    };
  },
};
</script>

<style>
.cover-letter-browser {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.cover-letter-item {
  text-align: center;
  cursor: pointer;
}

.cover-letter-image {
  width: 100%;
  height: auto;
}

.cover-letter-image:hover {
  transform: scale(1.05);
  transition: transform 0.3s;
  border: 3px solid rgba(0, 0, 255, 0.304); /* Change 'blue' to any desired highlight color */
  border-radius: 3px;
  box-shadow: 0 0 15px rgba(0, 0, 255, 0.5); /* Additional shadow for selected image */
}
</style>
