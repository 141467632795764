import Feedback from "@models/Feedback";
import { AxiosResponse } from "axios";
import { instance } from "./apiProvider/ApiProvider";

export class FeedbackApiService {
  public static async submitFeedback(feedback: any): Promise<AxiosResponse<void>> {
    const axios = await instance();
    const response = await axios.post("/submitFeedback", feedback);

    if (response.status == 200) {
      return response;
    } else {
      console.error(response);
      return response;
    }
  }
}
