<template>
  <div id="app">
    <!-- FEEDBACK MODAL -->
    <Modal class="modal-lg" title="Help Make JobBuddy Better ✅" ref="submitIdeaFeedbackModal">
      <template #body>
        <br />Had an idea to improve JobBuddy? Something not working quite as you'd like? Tell us
        the good, bad and ugly. What's your pain, how can JobBuddy help with that? All feedback
        helps make JobBuddy better!<br /><br />
        <textarea
          v-model="feedback.feedback"
          class="form-control p-5"
          rows="5"
          placeholder="Give as much detail as you can. It helps me a lot!"
          required
        />
        <br />
        <button class="btn btn-primary w-50" @click="submitFeedback('Idea')">
          Submit Feedback
        </button>
      </template>
    </Modal>
    <!-- BUG MODAL -->
    <Modal class="modal-lg" title="Report A Problem 🐞" ref="submitBugFeedbackModal">
      <template #body>
        <br />Discovered a bug, noticed something strange or broken? Let us know! We're always
        building new features for JobBuddy, so sometimes things can go wrong. When that happens, we
        value feedback from users to help us get back on track.<br /><br />
        <textarea
          v-model="feedback.feedback"
          class="form-control p-5"
          rows="5"
          placeholder="Give as much detail as you can. How can we reproduce the problem?"
          required
        />
        <br />
        <button class="btn btn-primary w-50" @click="submitFeedback('Bug')">Submit Report</button>
      </template>
    </Modal>
    <!-- SUPPORT MODAL -->
    <Modal class="modal-lg" title="Contact JobBuddy Support 🛟" ref="submitSupportFeedbackModal">
      <template #body>
        <br />Having problems with JobBuddy? Not to worry, we're always on hand to help. Let us know
        how we can help, and we'll endeavor to get back to you shortly.<br /><br />
        <textarea
          v-model="feedback.feedback"
          class="form-control p-5"
          rows="5"
          placeholder="Give as much detail as you can. It helps a lot!"
          required
        />
        <br />
        <button class="btn btn-primary w-50" @click="submitFeedback('Support')">
          Submit Support Request
        </button>
      </template>
    </Modal>
    <nav class="navbar navbar-expand-lg navbar bg-body-secondary">
      <div class="container-fluid">
        <router-link to="/" style="text-decoration: none; color: inherit">
          <h2 class="navbar-brand fw-bold">Job<span class="text-primary">Buddy</span></h2>
        </router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarText"
        >
          <span class="navbar-toggler-icon" />
        </button>
        <div id="navbarText" class="collapse navbar-collapse">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <router-link to="/" style="text-decoration: none; color: inherit">
                <a class="nav-link active" href="#">Home</a>
              </router-link>
            </li>
            <div v-if="!isAuthenticated" @click="login()">
              <li class="nav-item">
                <a class="nav-link" href="#">Try it free</a>
              </li>
            </div>
            <router-link
              v-if="!isAuthenticated"
              :to="{
                name: 'About',
              }"
              style="text-decoration: none; color: inherit"
            >
              <li class="nav-item">
                <a class="nav-link" href="#">About</a>
              </li>
            </router-link>
            <router-link
              v-if="!isAuthenticated"
              :to="{
                name: 'Subscribe',
              }"
              style="text-decoration: none; color: inherit"
            >
              <li class="nav-item">
                <a class="nav-link" href="#">Pricing</a>
              </li>
            </router-link>

            <router-link
              v-if="isAuthenticated"
              :to="{
                name: 'Dashboard',
              }"
              style="text-decoration: none; color: inherit"
            >
              <li class="nav-item">
                <a class="nav-link" href="#">Dashboard</a>
              </li>
            </router-link>

            <router-link
              v-if="isAuthenticated"
              :to="{
                name: 'Profile',
              }"
              style="text-decoration: none; color: inherit"
            >
              <li class="nav-item">
                <a class="nav-link" href="#">Profile</a>
              </li>
            </router-link>
            <!-- Mobiles can use the navbar for login-->
            <li class="d-md-none">
              <div>
                <div v-if="!isAuthenticated">
                  <a class="nav-link" href="#" @click="login()">Login</a>
                </div>
                <div v-else style="text-decoration: none; color: inherit">
                  <a class="nav-link" href="#" @click="logout">Logout</a>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div
        id="themeDropdown"
        class="btn btn-outline-primary d-none d-lg-block"
        type="button"
        data-bs-toggle="dropdown"
        v-pre
      >
        <i class="bi bi bi-circle-half"></i>
      </div>
      <div class="dropdown-menu dropdown-menu-end">
        <a
          id="lightThemeOption"
          class="dropdown-item theme-dd-option"
          href="#"
          @click="toggleDarkMode(DarkModeOptions.Light)"
        >
          <i class="bi bi-brightness-high-fill"></i>&nbsp;Light
        </a>
        <a
          id="darkThemeOption"
          class="dropdown-item theme-dd-option"
          href="#"
          @click="toggleDarkMode(DarkModeOptions.Dark)"
        >
          <i class="bi bi-moon-stars-fill"></i>&nbsp;Dark
        </a>
        <a
          id="osThemeOption"
          class="dropdown-item theme-dd-option"
          href="#"
          @click="toggleDarkMode(DarkModeOptions.Auto)"
        >
          <i class="bi bi-circle-half"></i>&nbsp;Auto
        </a>
      </div>
      <button
        v-if="!isAuthenticated"
        class="collapse navbar-collapse btn btn-primary m-2"
        @click="login()"
      >
        <span class="">Login</span>
        <i width="30" class="bi bi-person-circle ps-2" />
      </button>
      <button
        v-else
        type="button"
        class="collapse navbar-collapse dropdown btn btn-primary dropdown-toggle m-2"
        data-bs-toggle="dropdown"
      >
        <img v-if="isAuthenticated" width="25" class="rounded-circle me-2" :src="user.picture" />
        <span class="">{{ user.name }}</span>
      </button>
      <ul class="dropdown-menu dropdown-menu-end me-2">
        <li>
          <router-link
            :to="{
              name: 'Dashboard',
            }"
            style="text-decoration: none; color: inherit"
          >
            <a class="dropdown-item" role="button">Dashboard</a>
          </router-link>
        </li>
        <li>
          <router-link
            :to="{
              name: 'Profile',
            }"
            style="text-decoration: none; color: inherit"
          >
            <a class="dropdown-item" role="button">Profile</a>
          </router-link>
        </li>
        <li>
          <hr class="dropdown-divider" />
        </li>

        <li>
          <a href="#" class="dropdown-item" @click="logout()">Logout</a>
        </li>
      </ul>
    </nav>
    <body class="bg-transparent min-vh-100">
      <router-view />
      <transition name="fade">
        <div v-if="store.state.showLoader" class="loader-container">
          <div class="loader"></div>
        </div>
      </transition>
    </body>
    <footer class="footer bg-body-secondary text-decoration-none mt-5">
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <h6>Discover More</h6>
            <ul class="list-unstyled">
              <li><router-link :to="{ name: 'ResumeExamples' }">Resume Examples</router-link></li>
              <li>
                <router-link :to="{ name: 'CoverLetterExamples' }"
                  >Cover Letter Examples</router-link
                >
              </li>
              <li><router-link :to="{ name: 'ChatGPTBlog' }">JobBuddy vs ChatGPT</router-link></li>
              <li>
                <router-link :to="{ name: 'CoverLetterBlog' }"
                  >Writing a killer cover letter</router-link
                >
              </li>
            </ul>
          </div>
          <div class="col-md-4">
            <div class="row w-100 text-muted">
              <div v-if="isAuthenticated" class="col d-flex justify-content-center">
                <div
                  class="m-3 mt-0 help-bar d-flex"
                  v-tooltip="'Give Feedback'"
                  @click="submitIdeaFeedbackModal.show()"
                >
                  <span>Feedback</span>
                  <i class="ps-2 bi bi-chat-right-text"></i>
                </div>
                <div
                  class="m-2 mt-0 help-bar d-flex"
                  v-tooltip="'Tell Us About A Bug'"
                  @click="submitBugFeedbackModal.show()"
                >
                  <span>Report Problem</span>
                  <i class="ps-2 bi bi-bug"></i>
                </div>
                <div
                  class="m-3 mt-0 help-bar d-flex"
                  v-tooltip="'Contact Support'"
                  @click="submitSupportFeedbackModal.show()"
                >
                  <span>Support</span>
                  <i class="ps-2 bi-question-circle"></i>
                </div>
              </div>
            </div>
            <p class="mb-0 mt-3 text-secondary">© 2024 JobBuddy</p>
          </div>

          <div class="col-md-4">
            <ul class="list-unstyled">
              <li>
                <a href="mailto:hello@jobbuddytech.com">Get In Touch</a>
              </li>
              <li>
                <router-link :to="{ name: 'TermsOfService' }">Terms of Service</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'PrivacyPolicy' }">Privacy Policy</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'Subscribe' }">Pricing</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script lang="ts">
import { useAuth0 } from "@auth0/auth0-vue";
import { ref, onMounted } from "vue";
import Modal from "./components/Modal.vue";
import { FeedbackApiService } from "./services/FeedbackApiService";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { useStore } from "vuex";

export enum DarkModeOptions {
  Light = 0,
  Dark = 1,
  Auto = 2,
}

export default {
  name: "App",
  components: { Modal },
  setup() {
    enum DarkModeOptions {
      Light = 0,
      Dark = 1,
      Auto = 2,
    }
    const store = useStore();
    const { isAuthenticated, logout, loginWithRedirect, user } = useAuth0();
    const userPreference = ref(
      parseInt(localStorage.getItem("darkModeEnabledEnum") ?? DarkModeOptions.Auto.toString())
    );
    const systemPreferenceForDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
    const darkModeEnabled = ref(
      userPreference.value === DarkModeOptions.Dark ||
        (userPreference.value === DarkModeOptions.Auto && systemPreferenceForDark)
    );
    const submitBugFeedbackModal = ref(null);
    const submitIdeaFeedbackModal = ref(null);
    const submitSupportFeedbackModal = ref(null);

    const feedback = ref({ feedback: "", userId: "", metadata: {}, type: "" });

    const toggleDarkMode = (option: DarkModeOptions) => {
      const isDarkModeEnabled =
        option === DarkModeOptions.Dark ||
        (option === DarkModeOptions.Auto && systemPreferenceForDark);
      const htmlElement = document.querySelector("html");
      if (htmlElement === null) {
        console.error("Can't set dark mode");
        return;
      }
      store.commit("setDarkModeStatus", option);
      htmlElement.dataset.bsTheme = isDarkModeEnabled ? "dark" : "light";
    };

    const submitFeedback = async (type: string) => {
      const user = await store.getters["user/getLoggedInUser"];

      if (feedback.value.feedback.length < 1) {
        return;
      }
      feedback.value.metadata = {
        responseId: null,
        applicationId: null,
        userAgent: window.navigator.userAgent,
        timestamp: new Date().toISOString(),
        screenResolution: `${window.screen.width}x${window.screen.height}`,
        browserWindowSize: `${window.innerWidth}x${window.innerHeight}`,
        currentPageUrl: window.location.href,
        referrerUrl: document.referrer,
        deviceType: /Mobi/i.test(window.navigator.userAgent) ? "Mobile" : "Desktop",
      };
      feedback.value.userId = user._id || "";
      feedback.value.type = type;
      if (feedback.value.feedback == null) {
        feedback.value.feedback = "Empty";
      }
      await FeedbackApiService.submitFeedback(feedback.value);

      switch (type) {
        case "Bug":
          (submitBugFeedbackModal.value as any).hide();
          break;
        case "Support":
          (submitSupportFeedbackModal.value as any).hide();
          break;
        case "Idea":
          (submitIdeaFeedbackModal.value as any).hide();
      }
      feedback.value.feedback = "";
      toast.success("Submission successful!");
    };

    onMounted(() => {
      const htmlElement = document.querySelector("html");
      if (htmlElement != null) {
        htmlElement.dataset.bsTheme = darkModeEnabled.value ? "dark" : "light";
        store.commit("setDarkModeStatus", userPreference.value);
      }
    });

    return {
      logout: () => {
        logout({ returnTo: window.location.origin });
      },
      login: async () => {
        await loginWithRedirect({
          appState: { target: "/dashboard" },
        });
      },
      toggleDarkMode,
      submitFeedback,
      userPreference,
      isAuthenticated,
      user,
      darkModeEnabled,
      submitSupportFeedbackModal,
      submitBugFeedbackModal,
      submitIdeaFeedbackModal,
      feedback,
      store,
      DarkModeOptions,
    };
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position: relative;
}

.footer {
  padding: 20px 0;
}
.footer a {
  text-decoration: none;
}
.footer a:hover {
  text-decoration: underline;
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.245);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #0275d8;
  width: 100px;
  height: 100px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
.help-bar {
  cursor: pointer;
}
</style>
