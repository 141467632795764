<template>
  <div>
    <Modal title="Save your changes?" hide-close-button ref="saveResponseModal">
      <template #body>
        <p>Do you want to save the changes you've made?</p>
      </template>
      <template #footer>
        <button @click="discardChanges" class="btn btn-secondary btn-lg me-2">Discard</button>
        <button @click="saveChanges" class="btn btn-primary btn-lg">Save Changes</button>
      </template>
    </Modal>
    <div class="document-editor-container card h-100 overflow-y-auto" style="max-height: 85vh">
      <div
        class="card-header p-0 m-0 px-2 d-flex justify-content-between align-items-center position-sticky"
      >
        <button
          class="btn btn-outline-primary m-0 py-0 px-2"
          @click="confirmClose()"
          v-tooltip="'Return To Application'"
        >
          <i class="bi bi-arrow-return-left"></i> Back
        </button>

        <div class="d-flex align-items-center justify-content-center mx-auto">
          <span class="">Document Editor</span>
          <i class="bi bi-pencil ms-2"></i>
        </div>

        <i
          @click="saveResponse"
          class="bi bi-floppy m-1 p-1 p"
          style="cursor: pointer"
          v-tooltip="'Save Document Changes'"
        />
      </div>
      <div class="card-body m-0 p-0 rounded-0 border-0 overflow-y-auto">
        <SchemaField
          :value="schema"
          :label="'Root'"
          @update-schema="updateSchema"
          @delete-from-schema="deleteFromSchema"
          class="px-2"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import SchemaField from "./SchemaField.vue";
import Modal from "@/components/Modal.vue";
import { ref, watch } from "vue";

const saveResponseModal = ref(null);
const isSchemaChanged = ref(false);
const props = defineProps({
  schema: Object,
});

const emit = defineEmits(["updateSchema", "deleteFromSchema"]);

function saveResponse() {
  emit("saveResponse");
  isSchemaChanged.value = false;
}

function updateSchema(updateObject) {
  isSchemaChanged.value = true;
  // Handle the schema update locally if needed, then emit the update
  emit("updateSchema", updateObject);
}

function deleteFromSchema(path) {
  // Emit the delete event to the parent with the path of the schema to be deleted
  emit("deleteFromSchema", path);
}

function confirmClose() {
  if (isSchemaChanged.value) {
    saveResponseModal.value.show();
  } else {
    emit("closeEditor", false);
    saveResponseModal.value.hide();
  }
}

function saveChanges() {
  emit("saveResponse", true);
  saveResponseModal.value.hide();
  isSchemaChanged.value = false;
}
function discardChanges() {
  emit("closeEditor", true);
  saveResponseModal.value?.hide();
}
</script>

<style scoped>
.editor-header {
  /* Your CSS styling */
}

.document-editor-container::-webkit-scrollbar {
}
</style>
