<template>
  <div class="modal fade" id="exampleModal" tabindex="-1" ref="modalEle">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
        </div>
        <div class="modal-body">
          <slot name="body" />
        </div>
        <div class="modal-footer">
          <slot name="footer"></slot>

          <button
            v-if="!hideCloseButton"
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from "vue";
import { Modal } from "bootstrap";
defineProps({
  title: {
    type: String,
    default: "",
  },
  hideCloseButton: {
    type: Boolean,
    default: false,
  },
});
const modalEle = ref(null);
let thisModalObj = null;

onMounted(() => {
  thisModalObj = new Modal(modalEle.value);
});
function _show() {
  thisModalObj.show();
}

function _hide() {
  thisModalObj.hide();
}

// onBeforeUnmount(() => {
//   thisModalObj.dispose();
// });

defineExpose({ show: _show, hide: _hide });
</script>

<style scoped>
.modal-header {
  display: flex;
  justify-content: center; /* Center the items horizontally */
}

.modal-title {
  flex-grow: 1; /* Allows the title to take up the maximum width */
  text-align: center; /* Centers the title text */
}

.modal-header .btn-close {
  position: absolute;
  right: 1rem; /* Adjusts the position of the close button */
}
</style>
