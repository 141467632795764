<template>
  <div class="container py-5">
    <div class="row mb-4">
      <div class="col-md-12 text-center">
        <div class="lc-block mb-4">
          <h2 editable="inline" class="rfs-25 fw-bolder">Testimonials</h2>
          <p editable="inline">
            We've helped thousands of happy users in securing their dream job. See what some of our
            users have to say.
          </p>
        </div>
        <!-- /lc-block -->
      </div>
    </div>
    <div class="row justify-content-center">
      <div
        v-for="testamonial in testamonials"
        :key="testamonial.name"
        class="col-md-8 col-lg-4 mb-4"
      >
        <div class="card border-0 shadow">
          <div class="card-body py-4">
            <div class="d-flex">
              <img
                style="width: 48px; height: 48px"
                :src="`../../media/${testamonial.imageSrc}`"
                alt="JobBuddy user testamonial"
                class="rounded-2 shadow"
                sizes="(max-width: 1080px) 100vw, 1080px"
                width="1080"
                height="1080"
                loading="lazy"
              />
              <div class="ps-2 d-flex">
                <h4 editable="inline" class="rfs-7 ms-2">{{ testamonial.name }}</h4>
                <h4 class="text-muted ms-2">- {{ testamonial.jobTitle }}</h4>
              </div>
            </div>
            <div class="lc-block mt-4 text-muted">
              <div editable="rich">
                <p>{{ testamonial.description }}</p>
              </div>
            </div>
            <div class="rating mt-3 text-success">
              <div class="lc-block">
                <svg
                  v-for="index in testamonial.stars"
                  :key="index"
                  width="1em"
                  height="1em"
                  viewBox="0 0 16 16"
                  fill="#007bff"
                  xmlns="http://www.w3.org/2000/svg"
                  lc-helper="svg-icon"
                >
                  <path
                    d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";

export default {
  name: "TestamonialsVue",
  props: {
    testamonials: {
      type: Object,
      default: () => ({}), // Provide a default value
    },
  },
  setup() {
    // Your reactive properties and methods go here
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
/* Your CSS goes here */
</style>
