<template>
  <div
    class="schema-field"
    :class="{
      'is-root': isRoot,
      'non-root-field': !isRoot,
      'my-2': !isRoot,
      container: !isString(localValue) && !isRoot,
      collapsed: collapsed && !isRoot,
      'compact-root': isRoot,
      'schema-field-nested': collapsed && !isString(localValue),
    }"
  >
    <template v-if="isObject(value) || isArray(value)">
      <div v-if="!isRoot" @click="toggleCollapse" class="title-bar">
        <span class="m-2"
          >{{ humaniseString(label) }}
          <span class="ms-2">{{ getEmojiForString(label) }}</span>
          <span v-if="isObject(value)" class="fw-lighter fst-italic">{{
            Object.values(value)[0]
          }}</span>
        </span>
        <i class="bi-chevron-up m-2"></i>
      </div>
      <div v-if="!collapsed || isRoot" class="content">
        <template v-if="isObject(value)">
          <div v-for="(item, key) in value" :key="key">
            <SchemaField
              :value="item"
              :label="key"
              :path="extendPath(key)"
              @update-schema="updateValue"
              @delete-from-schema="deleteFromSchemaHandler"
            />
          </div>
        </template>
        <template v-if="isArray(value)">
          <div v-for="(item, index) in value" :key="index">
            <SchemaField
              :value="item"
              :label="`${humaniseString(label, true)} #${index + 1}`"
              :path="extendPath(index)"
              class="m-2"
              @update-schema="updateValue"
              @delete-from-schema="deleteFromSchemaHandler"
              deletable
            />
          </div>
          <button class="btn btn-outline-secondary btn-sm m-2" @click="addToArray">
            Add {{ humaniseString(label) }}
            <i class="bi bi-plus"></i>
          </button>
        </template>
      </div>
    </template>
    <template v-else>
      <div class="field d-flex">
        <div v-if="isLongField()" class="flex-grow-1 d-flex flex-column">
          <span class="input-group-text w-100 rounded-bottom-0 rounded-1 border-0">{{
            humaniseString(label)
          }}</span>
          <textarea
            :rows="getHeight(localValue)"
            class="form-control flex-grow-1 rounded-top-0"
            v-model="localValue"
            style="resize: none"
            @blur="onInputChange"
            oninput="this.style.height = ''; this.style.height = this.scrollHeight +'px'"
            @keydown="handleEnter"
          ></textarea>
          <i
            v-if="deletable"
            class="bi bi-trash"
            @click="deleteItem()"
            @mouseenter="highlightBorderOnHover"
            @mouseleave="removeHighlightOnLeave"
            style="cursor: pointer"
          >
          </i>
        </div>
        <div v-else class="input-group border-0">
          <span class="input-group-text w-25 border-0">{{ humaniseString(label) }}</span>
          <textarea
            type="text"
            :rows="getHeight(localValue)"
            class="form-control border-0 rounded"
            v-model="localValue"
            @keydown="handleEnter"
            @blur="onInputChange"
            oninput="this.style.height = ''; this.style.height = this.scrollHeight +'px'"
          />
          <div v-if="deletable" class="input-group-append">
            <button
              class="btn btn-outline-none m-0 p-0 rounded-1 h-100"
              @click="deleteItem()"
              @mouseenter="highlightBorderOnHover"
              @mouseleave="removeHighlightOnLeave"
            >
              <i class="bi bi-trash me-1 text-muted" style="cursor: pointer; font-size: 1rem"></i>
            </button>
          </div>
          <i v-show="hasChanged" class="bi bi-arrow-clockwise tick-icon"></i>
        </div>
      </div>
    </template>
    <button
      v-if="deletable && !collapsed"
      class="btn btn-outline-danger btn-sm mb-3"
      @click="deleteItem()"
      @mouseenter="highlightBorderOnHover"
      @mouseleave="removeHighlightOnLeave"
    >
      Delete
      <i class="bi bi-trash"></i>
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "SchemaField",
  props: {
    value: {
      type: [String, Object, Array, Boolean] as PropType<string | object | any[] | boolean>,
      default: "",
    },
    label: { type: String, default: "Root" },
    path: { type: Array as PropType<any[]>, default: () => [] },
    deletable: { type: Boolean, default: false },
  },
  data() {
    return {
      localValue: this.value,
      collapsed: this.label !== "Root",
      initialValue: this.value,
      hasChanged: false,
    };
  },
  computed: {
    fieldId(): string {
      return this.path.join("-");
    },
    labelClass(): string {
      return `label-${20 - this.path.length}`;
    },
    isRoot(): boolean {
      return this.label === "Root";
    },
  },
  methods: {
    isString(val: any): boolean {
      return typeof val === "string";
    },
    isBoolean(val: any): boolean {
      return typeof val === "boolean";
    },
    isObject(val: any): boolean {
      return val && typeof val === "object" && !Array.isArray(val);
    },
    isArray(val: any): boolean {
      return Array.isArray(val);
    },
    extendPath(key: any): any[] {
      return [...this.path, key];
    },
    isLongField(): boolean {
      return this.getHeight(this.localValue) > 2;
    },
    toggleCollapse(): void {
      this.collapsed = !this.collapsed;
      if (!this.collapsed) {
        this.$emit("close-siblings", this.path);
      }
    },
    humaniseString(input: string | null, depluralise?: boolean) {
      if (input == null) {
        return input;
      }
      const result = input.replace(/([A-Z])/g, " $1").trim();
      let formattedString = result.charAt(0).toUpperCase() + result.slice(1);
      if (depluralise) {
        if (formattedString.endsWith("s")) {
          formattedString = formattedString.slice(0, -1);
        }
      }
      return formattedString;
    },
    getEmojiForString(str: string): string | null {
      const includesStr = (string: string) => str.toUpperCase().includes(string);
      if (str.includes("#")) {
        return "";
      }
      if (includesStr("WORK")) {
        return "💼";
      }
      if (includesStr("EDUCATION")) {
        return "📚";
      }
      if (includesStr("SKILLS")) {
        return "✅";
      }
      if (includesStr("ACHIEVMENT")) {
        return "🎉";
      }
      if (includesStr("CERTIFICATIONS")) {
        return "📊";
      }
      if (includesStr("ACHIEVEMENT")) {
        return "🏆";
      }
      if (includesStr("INTERESTS")) {
        return "🚴🏻";
      }
      return "";
    },
    deleteItem() {
      const confirmed = confirm("Are you sure you want to delete this item?");
      if (!confirmed) {
        return;
      }
      this.$emit("delete-from-schema", this.path);
    },
    updateValue(updatedPath: unknown[], updatedValue: any) {
      const pathArray = Array.isArray(updatedPath) ? updatedPath : [updatedPath];
      this.$emit("update-schema", { path: pathArray, value: updatedValue });
    },
    deleteFromSchemaHandler(path: any) {
      this.$emit("delete-from-schema", path);
    },
    handleEnter(event: any) {
      if (event.key !== "Enter" || event.shiftKey) {
        return;
      }

      // Causes the textarea to lose focus
      event.target.blur();

      // Call your input change handling function
      this.onInputChange();
    },
    onInputChange() {
      this.hasChanged = this.localValue !== this.initialValue;
      if (this.hasChanged) {
        this.updateValue([...this.path], this.localValue);
        this.initialValue = this.localValue;
        this.hasChanged = false;
      }
    },
    addToArray() {
      const values = this.localValue as any;
      const emptyCloneOfFirstElement = this.emptyClone(values[0], this.label);
      values.push(emptyCloneOfFirstElement);
      this.updateValue([...this.path], values);
    },
    emptyClone(obj: any, label: string): any {
      if (Array.isArray(obj)) {
        return obj.map((val) => this.emptyClone(val, label));
      } else if (typeof obj === "object" && obj !== null) {
        const clone = {} as any;
        for (const key in obj) {
          clone[key] = this.emptyClone(obj[key], key);
        }
        return clone;
      } else if (typeof obj === "string") {
        return `${label.toUpperCase()}`;
      } else {
        return " ";
      }
    },
    closeSiblings(path: unknown[]) {
      if (!this.path.every((el, idx) => el === path[idx])) {
        this.collapsed = true;
      }
    },
    getHeight(value: any) {
      if (this.isString(value)) {
        const newLines = (value.match(/\n/g) || []).length + 1;
        return Math.max(Math.floor(value.split(" ").length / 20) + newLines, 1);
      }
      return 1;
    },
    highlightBorderOnHover() {
      this.$el.classList.add("delete-hover");
    },
    removeHighlightOnLeave() {
      this.$el.classList.remove("delete-hover");
    },
  },
});
</script>
<style scoped>
.schema-field-nested {
  transition: box-shadow 0.3s ease; /* Smooth transition for the shadow */
  padding-right: 3px;
}

.container {
  border: 1px solid #cccccc50;
  border-radius: 5px;
}
.schema-field-nested:hover {
  border: 0px 0px 2px rgba(12, 108, 253, 0.487); /* Shadow appears on hover */
}

/* }
.non-root-field {
  border: 1px solid #e8e8e8;
  border-radius: 5px;
} */

.is-root > .title-bar {
  display: none;
}

.schema-field.is-root {
  border-radius: 0px;
  border-width: 0px;
}

.title-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.field.d-flex {
  display: flex;
  align-items: center;
}

/* .field .field-label {
  flex: 0 0 15%;
  text-align: left;
  padding-left: 10px;
} */

.compact-root .content {
  padding-top: 0;
}

.compact-root .field > .field-label {
  margin-top: 0;
  font-size: 0.875rem;
}

.collapsed > .content {
  display: none;
}

.full-width-textarea {
  flex-grow: 1;
  padding-top: 5px;
  padding-left: 5px;
  border: 1px solid #15010150;
  border-radius: 5px;
  resize: none;
  background: none;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.full-width-textarea:focus {
  border-color: rgba(12, 108, 253, 0.33);
  box-shadow: 0 0 0 2px rgba(12, 108, 253, 0.481);
}

.bi-chevron-up {
  transition: transform 0.3s ease-in-out; /* Smooth transition for rotation */
}

.collapsed .bi-chevron-up {
  transform: rotate(180deg); /* Normal state */
}

.schema-field {
  border-style: solid;
  border-width: 1px;
  border-color: #dee2e6;
  border-radius: 5px;
}
.schema-field.delete-hover {
  border-color: rgba(255, 0, 0, 0.503); /* Change the border color to red */
  box-shadow: 0 0 8px rgba(255, 0, 0, 0.419); /* Add a glow effect */
}

.text-area-container {
  position: relative;
  width: 100%;
}

.tick-icon {
  position: absolute;
  right: 10px; /* Adjust as necessary for alignment */
  top: 50%;
  transform: translateY(-50%);
  color: rgb(0, 19, 128); /* Green color for the tick */
  font-size: 20px; /* Adjust size as necessary */
  display: none; /* Hide by default */
  cursor: pointer;
}

.text-area-container textarea:focus + .tick-icon {
  display: block; /* Show when text area is focused and hasChanged is true */
}

textarea {
  resize: none;
}
.full-width-textarea:focus {
  border-color: rgba(12, 108, 253, 0.33);
  outline: none;
  box-shadow: 0 0 0 2px rgba(12, 108, 253, 0.481);
}
</style>
