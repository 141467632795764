import { createRouter, createWebHistory } from "vue-router";
import HomePage from "../views/Homepage.vue";
import SetUpProfile from "../views/SetUpProfile.vue";
import AboutJb from "../views/About.vue";
import DashboardView from "../views/DashboardView.vue";
import CreateApplication from "../views/CreateApplication.vue";
import MyApplication from "../views/MyApplication.vue";
import TermsOfService from "../views/TermsOfService.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import CoverLetterBlog from "../views/blogs/CoverLetterBlog.vue";
import ChatGPTBlog from "../views/blogs/ChatGPTBlog.vue";
import UserProfilePopout from "../components/UserProfilePopout.vue";
import { authGuard } from "@auth0/auth0-vue";
import store from "@/store";
import { loginProtectedRoutes } from "@/auth/loginProtectedRoutes";
import SubscribeVue from "@/views/Subscribe.vue";
import PaymentVue from "@/views/Payment.vue";
import VerifyEmail from "@/views/VerifyEmail.vue";
import TaskBoard from "@/views/TaskBoard.vue";
import EditResponseVue from "@/views/EditResponse.vue";
import MyApplicationVue from "../views/MyApplication.vue";
import { coverLetterExamples } from "@/views/blogs/CoverLetterExamples";
import CoverLetterBrowser from "@/views/CoverLetterBrowser.vue";
import CoverLetterDetail from "@/views/CoverLetterDetail.vue";
import ResumeBrowser from "@/views/ResumeBrowser.vue";
import ResumeDetail from "@/views/ResumeDetail.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomePage,
  },
  {
    path: "/subscribe",
    name: "Subscribe",
    component: SubscribeVue,
    meta: { toTop: true, smoothScroll: true },
  },
  {
    path: "/payment/:plan/:priceId",
    name: "Payment",
    component: PaymentVue,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: DashboardView,
    beforeEnter: authGuard,
  },
  {
    path: "/about",
    name: "About",
    component: AboutJb,
  },
  {
    path: "/profile",
    name: "Profile",
    component: UserProfilePopout,
  },
  {
    path: "/setupprofile",
    name: "SetUpProfile",
    component: SetUpProfile,
  },
  {
    path: "/createapplication",
    name: "CreateApplication",
    component: CreateApplication,
  },
  {
    path: "/myapplication/:id",
    name: "MyApplication",
    component: MyApplicationVue,
  },
  {
    path: "/termsofservice",
    name: "TermsOfService",
    component: TermsOfService,
  },
  {
    path: "/privacypolicy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/blog/CoverLetterBlog",
    name: "CoverLetterBlog",
    component: CoverLetterBlog,
  },
  {
    path: "/blog/ChatGPTBlog",
    name: "ChatGPTBlog",
    component: ChatGPTBlog,
  },
  {
    path: "/taskboard",
    name: "TaskBoard",
    component: TaskBoard,
  },
  {
    path: "/verifyEmail",
    name: "VerifyEmail",
    component: VerifyEmail,
  },
  {
    path: "/cover-letter-examples",
    name: "CoverLetterExamples",
    meta: { toTop: true, smoothScroll: true },
    component: CoverLetterBrowser,
  },
  {
    path: "/cover-letter-examples/:role",
    name: "CoverLetterDetail",
    meta: { toTop: true, smoothScroll: true },
    component: CoverLetterDetail,
  },
  {
    path: "/resume-examples",
    name: "ResumeExamples",
    meta: { toTop: true, smoothScroll: true },

    component: ResumeBrowser,
  },
  {
    path: "/resume-examples/:role",
    name: "ResumeDetail",
    meta: { toTop: true, smoothScroll: true },
    component: ResumeDetail,
  },
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    const scroll = {} as any;
    if (to.meta.toTop) scroll.top = 0;
    if (to.meta.smoothScroll) scroll.behavior = "smooth";
    return scroll;
  },
});

router.beforeEach(async (to, from, next) => {
  if (loginProtectedRoutes.some((routeName) => routeName == to.name)) {
    await checkUserIsLoggedIn();
  }

  // Google Analytics Page View
  (window as any).dataLayer = (window as any).dataLayer || [];
  (window as any).dataLayer.push({
    event: "vue-pageview",
    routeName: to.name,
    routePath: to.path,
    routeFullPath: to.fullPath,
    routeParams: JSON.stringify(to.params),
    routeQuery: JSON.stringify(to.query),
  });
  next();
});

async function checkUserIsLoggedIn() {
  const user = await store.getters["user/getLoggedInUser"];
  if (!user) {
    await store.dispatch("user/loadLoggedInUser");
  }
}

export default router;
