import { Store } from "vuex";
import documentModule from "./modules/documentsModule";
import userModule from "./modules/userModule";
import { DarkModeOptions } from "@/App.vue";
export interface State {
  darkModeEnabled: any;
  showLoader: boolean;
}

export default new Store<State>({
  state: {
    darkModeEnabled: true,
    showLoader: false,
  },
  getters: {
    getDarkModeStatus(state) {
      return state.darkModeEnabled;
    },
  },
  mutations: {
    setDarkModeStatus(state, darkModeOption: DarkModeOptions) {
      localStorage.setItem("darkModeEnabledEnum", darkModeOption.toString());
      state.darkModeEnabled = darkModeOption;
    },
    setShowLoader(state, payload) {
      state.showLoader = payload;
    },
  },
  actions: {
    toggleLoader({ commit }, status) {
      commit("setShowLoader", status);
    },
  },
  modules: {
    documents: documentModule,
    user: userModule,
  },
});
