import { Module } from "vuex";
import store, { State as RootState } from "../index"; // import root state type
import User from "@models/User";
import { UserApiService } from "@/services/UserApiService";
import router from "@/router";
import { loginWithRedirect, isAuthenticated, auth0 } from "../../auth/auth0";
export interface UserState {
  user: User | null;
}

const userModule: Module<UserState, RootState> = {
  namespaced: true,
  state: {
    user: null,
  },

  getters: {
    async getLoggedInUser(state): Promise<User | null> {
      if (state.user != null) {
        return state.user;
      } else {
        await store.dispatch("user/loadLoggedInUser");
        return state.user;
      }
    },
  },

  actions: {
    async loadLoggedInUser(state) {
      if (!isAuthenticated().value) {
        await loginWithRedirect({ appState: { target: window.location.pathname || "/dashboard" } });
        const appState = await auth0.handleRedirectCallback();
        router.replace(appState?.appState?.target ?? "/");
      }
      try {
        const response = await UserApiService.getLoggedInUser();
        const user = response.data;
        store.commit("user/setUser", user);
      } catch (error: any) {
        console.error(error);
        if (error.message.includes("404")) {
          router.push("/setupprofile");
        }
      }
    },
    async createUser(state, user: User) {
      const response = await UserApiService.createNewUser(user);
      if (response.status == 200) {
        store.commit("user/setUser", user);
      }
    },
    async updateUser(state, user: User) {
      const response = await UserApiService.updateUser(user);
      if (response.status == 200) {
        store.commit("user/setUser", user);
      }
    },
  },

  mutations: {
    setUser(state, user: User) {
      state.user = user;
    },
  },
};

export default userModule;
