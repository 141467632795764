<template>
  <div>
    <Modal title="Are you sure you want to cancel your subscription?" ref="cancelSubscriptionModal">
      <template #body
        >Sorry to hear you're cancelling your subscription! If there's anything JobBuddy could do
        keep you as a customer, why not <a href="mailto:hello@jobbuddytech.com">let us know</a>. We
        always strive to improve, and we'd love to hear your feedback.
        <br />
        <br />
        Please note, cancelling your subscription will take immediate effect and you will lose all
        premium benefits.
      </template>
      <template #footer>
        <button @click="cancelSubscription(true)" class="btn btn-danger">Cancel</button>
      </template>
    </Modal>
    <Modal title="Delete your account? 😢" ref="deleteAccountModal">
      <template #body>
        If you're unhappy with your experience using JobBuddy, please
        <a href="mailto:hello@jobbuddytech.com">let us know</a>. We use user feedback to improve our
        service.
        <br />
        <br />
        Please note, deleting your account is irreversible!
        <br />
        <button @click="deleteAccount()" class="btn btn-danger m-3">
          Delete Account and Associated Data
        </button>
      </template>
    </Modal>
    <div class="container w-75 mt-5 shadow-lg p-5 rounded">
      <div class="row">
        <div class="col-lg-6 pe-5 border-end">
          <h3 class="text-uppercase text-muted fw-light mb-4">Personal Details</h3>
          <div class="mb-4">
            <label class="form-label">Name</label>
            <input type="text" class="form-control" v-model="user.name" />
          </div>
          <div class="mb-4">
            <label class="form-label">Email</label>
            <input type="text" class="form-control" v-model="user.email" />
          </div>
          <div class="mb-4">
            <label class="form-label">Occupation</label>
            <input type="text" class="form-control" v-model="user.occupation" />
          </div>
          <div class="mb-4">
            <label class="form-label">Gender</label>
            <select v-model="user.gender" class="form-select">
              <option>Male</option>
              <option>Female</option>
            </select>
          </div>
          <div class="mb-4">
            <label class="form-label">Employment Status</label>
            <select v-model="user.employmentStatus" class="form-select">
              <option>Unemployed - Casually browsing</option>
              <option>Unemployed - Long term unemployed</option>
              <option>Employed - Looking at new companies</option>
              <option>Employed - Looking to change career/role</option>
              <option>Graduate</option>
            </select>
          </div>
          <div class="">
            <label class="form-label">Country</label>
            <select v-model="user.country" class="form-select max-height-50 countries-dropdown">
              <option value="" disabled>Select your country</option>
              <option v-for="country in countries" :key="country">{{ country }}</option>
            </select>
          </div>
          <br />
          <div class="m-auto">
            <href class="text-danger" style="cursor: pointer" @click="deleteAccountModal.show()"
              >Delete My Account</href
            >
          </div>
        </div>
        <div class="col-lg-6 ps-5">
          <h3 class="text-uppercase text-muted fw-light mb-4">Subscription</h3>
          <div class="mb-4">
            <label class="form-label">Subscription Plan</label>
            <div class="input-group">
              <span class="form-control">{{
                user.subscriptionPlan?.toUpperCase() ?? "BASIC"
              }}</span>
              <button
                v-if="
                  user.subscriptionPlan == SubscriptionPlan.JOB_SEEKER ||
                  user.subscriptionPlan == SubscriptionPlan.BUSINESS
                "
                class="btn btn-outline-danger"
                @click="cancelSubscription()"
              >
                Cancel
              </button>
            </div>
          </div>
          <div class="mb-4">
            <router-link
              :to="{ name: 'Subscribe' }"
              v-if="user.subscriptionPlan != SubscriptionPlan.BUSINESS"
              class="btn btn-warning w-100"
            >
              Upgrade Plan
            </router-link>
          </div>
        </div>
      </div>
      <div class="text-center mt-5">
        <button class="btn btn-outline-primary" role="button" @click="updateUser()">
          Save Changes
        </button>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { onMounted, Ref, ref } from "vue";
import User from "@models/User";
import store from "../store";
import { toast } from "vue3-toastify";
import { SubscriptionPlan } from "@models/SystemEnums";
import { SubscriptionsApiService } from "@/services/SubscriptionsApiService";
import Modal from "@/components/Modal.vue";
import { countries } from "../components/CountriesList";
import { UserApiService } from "@/services/UserApiService";
import router from "../router";
const user: Ref<User> = ref({});
const cancelSubscriptionModal = ref(null);
const deleteAccountModal = ref(null);
onMounted(async () => {
  user.value = await store.getters["user/getLoggedInUser"];
});

async function deleteAccount() {
  try {
    const result = await UserApiService.deleteUser();
    if (result.status == 200) {
      router.push({ name: "Home" });

      setTimeout(() => {
        window.location.reload();
      }, 1000);
      setTimeout(() => {
        toast.success("Account deleted successfully");
      }, 2000);
    } else {
      toast.error("Could not delete account. Please contact support!");
    }
  } catch (err) {
    toast.error("Could not delete account. Please contact support!");
  } finally {
    deleteAccountModal.value.hide();
  }
}
async function cancelSubscription(force = false) {
  if (!force) {
    cancelSubscriptionModal.value.show();
    return;
  }
  const result = await SubscriptionsApiService.cancelSubscription();
  if (result.status == 200) {
    toast.success("Subscription cancelled successfully");
    await store.dispatch("user/loadLoggedInUser");
    user.value = await store.getters["user/getLoggedInUser"];
  } else {
    toast.error("Failed to cancel subscription");
  }
  cancelSubscriptionModal.value.hide();
}

async function updateUser() {
  await store.dispatch("user/updateUser", user.value);
  toast.success("Profile updated successfully");
}
</script>

<style scoped>
.form-label {
  color: #6c757d;
  font-size: 1rem;
  font-weight: 600;
}

.form-control,
.form-select {
  border-radius: 0;
  border-color: #ced4da;
  box-shadow: none;
  max-height: 200px;
}

.form-control:focus,
.form-select:focus {
  border-color: #495057;
  box-shadow: none;
}

.input-group .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.countries-dropdown {
  max-height: 200px; /* Set your desired maximum height */
  overflow-y: auto; /* Enable vertical scrollbar if needed */
}
</style>
