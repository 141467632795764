import { type SubscriptionPlan } from './SystemEnums'

export default class User {
  _id: string | null // MongoDB ID
  userId: string
  email: string
  gender: string
  employmentStatus: string
  name: string
  occupation: string
  customerId: string | null
  subscriptionPlan: SubscriptionPlan
  country: string

  constructor (_id: string | null, userId: string, email: string, gender: string, employmentStatus: string, name: string, occupation: string, customerId: string | null, subscriptionPlan: SubscriptionPlan, country: string) {
    this._id = _id
    this.userId = userId
    this.email = email
    this.gender = gender
    this.employmentStatus = employmentStatus
    this.name = name
    this.occupation = occupation
    this.customerId = customerId
    this.subscriptionPlan = subscriptionPlan
    this.country = country
  }
}
